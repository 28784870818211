import { NavLink } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import Page from '@components/Layouts/Page';
import RequestProvider, { withRequestState } from '@containers/RequestContext';
import withParams from '@containers/withParams';
import { classNames, makeLibraryName } from '@utils';
import styles from './index.module.scss';

const PageTitle = withRequestState(({ request }) => request?.data ? makeLibraryName(request.data) : null)

const LibraryPage = withParams(({ params: { libraryId }, back = true, is_steward = false }) => (
  <RequestProvider url={`/libraries/${libraryId}.json`}>
    <Page
      title={<PageTitle />}
      back={back}
      backReload={false}
      left={<>
        <div className={styles.linkWrapper}><NavLink to="edit" className={classNames(styles.link, "selectable")} activeClassName={styles.active} title="Edit">Edit</NavLink></div>
        <div className={styles.linkWrapper}><NavLink to="comments" className={classNames(styles.link, "selectable")} activeClassName={styles.active} title="Guest Book">Guest Book</NavLink></div>
        {/*<div className={styles.linkWrapper}><NavLink to="check-ins" className={classNames(styles.link,"selectable")} activeClassName={styles.active} title="Check-Ins">Check-Ins</NavLink></div>*/}
        <div className={styles.linkWrapper}><NavLink to="issues" className={classNames(styles.link, "selectable")} activeClassName={styles.active} title="Issues">Issues</NavLink></div>
        <div className={styles.linkWrapper}><NavLink to="photos" className={classNames(styles.link, "selectable")} activeClassName={styles.active} title="Photos">Photos</NavLink></div>
        <div className={styles.linkWrapper}><NavLink to="transfer" className={classNames(styles.link, "selectable")} activeClassName={styles.active} title="Transfer">Transfer</NavLink></div>
        {is_steward && <div className={styles.linkWrapper}><NavLink to="/account" className={classNames(styles.link, "selectable")} activeClassName={styles.active} title="Edit Steward">Edit Steward</NavLink></div>}
      </>}
    >
      <Outlet />
    </Page>
  </RequestProvider>
))

export default LibraryPage;
