import SponsorFormPage from '@components/Sponsor/Form';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {createSponsor} from '@utils/api/sponsor';

const SponsorCreatePage = withParams(({params:{id}}) => (
  <SponsorFormPage
    form={{
      url: "/app_sponsors.json",
      onSubmit: createSponsor
    }}
    page={{
      title: "Create Sponsor",
      back: true,
      submit: "Create New Sponsor"
    }}
  />
))

export default withPageTitle(SponsorCreatePage, "Create Sponsor");
