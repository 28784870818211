import { PublicForm } from '@components/Layouts/Public';
import InputComponent, { Input } from '@components/Form/Fields/Input';
import AutocapitalizeInput from '@components/Form/Fields/AutocapitalizeInput';
import { registrationSchema } from '@utils/validation/auth';
import { registrationRequest } from '@utils/api/auth';
import styles from './index.module.css';

const RegistrationPage = () => (
  <PublicForm
    buttonText="Sign Up"
    link={{ to: "/login", text: "Already have an account?" }}
    onSubmit={registrationRequest}
    defaultValues={{ tos_accepted: true }}
    to="/download_app"
    toast="Your account has been created, please check your email for further instructions"
    validationSchema={registrationSchema}
    after={(
      <div className={styles.terms}>
        <div>By creating an account, I agree to Little Free Library's
          <a href="https://littlefreelibrary.org/privacy-policy" target="_blank"> Trademark Agreement </a> and 
          <br></br><a href="https://littlefreelibrary.org/privacy-policy" target="_blank"> Mobile App Privacy Policy</a>
        </div>
      </div>
    )}
  >
    <AutocapitalizeInput name="first_name" placeholder="First Name" secondary round />
    <AutocapitalizeInput name="last_name" placeholder="Last Name" secondary round />
    <InputComponent type="email" name="email" placeholder="Email" secondary round />
    <InputComponent type="password" name="password" placeholder="Password" secondary round />
    <InputComponent type="password" name="password_confirmation" secondary round placeholder="Confirm Password" />
    <Input name="tos_accepted" className="hidden" />
  </PublicForm>
)

export default RegistrationPage;
