import ChallengeFormPage from '@components/Challenge/Form';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import { createChallenge } from '@utils/api/challenge';

const ChallengeCreatePage = withParams(({ params: { id } }) => (
  <ChallengeFormPage
    form={{
      url: "/challenges.json",
      onSubmit: createChallenge
    }}
    page={{
      title: "Create Challenge",
      back: true,
      submit: "Create New Challenge"
    }}
  />
))

export default withPageTitle(ChallengeCreatePage, "Create Challenge");
