import { useCallback, useEffect,useState,useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import Cropper from 'react-easy-crop';
import heic2any from "heic2any";
import Label from '@components/Form/Label';
import Button from '@components/Button';
import Error from '@components/Form/Fields/Error';
import {useToastAddAction} from '@containers/ToastContext';
import {useLoaderDispatch,startLoading,stopLoading} from "@containers/Loader";
import {ReactComponent as CloseIcon} from '@assets/images/close-24px.svg';
import {ReactComponent as ZoomInIcon} from '@assets/images/zoom_in_white_24dp.svg';
import {ReactComponent as ZoomOutIcon} from '@assets/images/zoom_out_white_24dp.svg';
import {ReactComponent as RotateIcon} from '@assets/images/refresh_white_24dp.svg';
import styles from './index.module.scss';
import {classNames,uniq,getBase64} from '@utils';

const getExtReqStr = (str) => uniq(str.split(', ').map(img => {
  switch (img) {
    case "image/png":
      return "PNG"
    case "image/jpg":
    case "image/jpeg":
      return "JPG"
    case "image/gif":
      return "GIF"
    default:
      return "PNG"
  }
})).join(", ")

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180
}

export async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  const maxSize = Math.max(image.width, image.height)
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

  canvas.width = safeArea
  canvas.height = safeArea

  ctx.translate(safeArea / 2, safeArea / 2)
  ctx.rotate(getRadianAngle(rotation))
  ctx.translate(-safeArea / 2, -safeArea / 2)

  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  )
  const data = ctx.getImageData(0, 0, safeArea, safeArea)

  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  )

  return new Promise((resolve) => {
    canvas.toBlob(async (file) => {
      resolve(await getBase64(file))
    }, 'image/png')
  })
}

export const Gallery = ({children,close,className,...props}) => (
  <div {...props} className={classNames(styles.cropContainer,className)}>
    <GalleryHeaderButton onClick={close} component={CloseIcon} className={styles.closeIcon + " selectable"} />
    {children}
  </div>
)


const GalleryHeaderButton = ({src,as: WrapperComponent = "button", component: Component,projectId,className,white,...props}) => (
  <WrapperComponent {...props} type="button" className={classNames(styles.galleryHeaderButtonContainer, white && styles.white,className)}>
    <Component className={styles.galleryHeaderButtonIcon} />
  </WrapperComponent>
)
const AvatarCrop = ({src,onCrop,close,aspect = 3/4}) => {
  const [rotation, setRotation] = useState(0),
        [zoom, setZoom] = useState(1),
        [crop, setCrop] = useState({ x: 0, y: 0 }),
        [croppedAreaPixels, setCroppedAreaPixels] = useState(null),
        onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
          setCroppedAreaPixels(croppedAreaPixels);
        }, []),
        handleImageUpload = useCallback(async () => {
          try {
            const croppedImage = await getCroppedImg(
              src,
              croppedAreaPixels,
              rotation
            )
            onCrop(croppedImage);

          } catch (e) {
            console.error(e)
          }
        }, [src, croppedAreaPixels, rotation,onCrop])
  return(
    <Gallery close={close}>
      <div className={styles.content}>
        <Cropper
          image={src}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={aspect}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>

      <div className={styles.controls}>
          <GalleryHeaderButton component={RotateIcon} onClick={() => {setRotation(prevRotation => prevRotation + 90)}} />
          <GalleryHeaderButton component={ZoomInIcon} onClick={() => {if(zoom < 3) setZoom(prevZoom => prevZoom + 1)}} />
          <GalleryHeaderButton component={ZoomOutIcon} onClick={() => {if(zoom > 1) setZoom(prevZoom => prevZoom - 1)}} />
          <Button type="button" onClick={handleImageUpload}>Upload Photo</Button>
      </div>
    </Gallery>
  )
}
function readFile(file) {
  return new Promise(async (resolve) => {
    const reader = new FileReader()
    var data = file;
    if(file.type.includes("heic") || file.type.includes("heif")){
      const blobURL = URL.createObjectURL(file);
      const res = await fetch(blobURL)
      const blob = await res.blob()
      data = await heic2any({ blob })
    }
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(data)
  })
}

export const CropperInput = ({name,aspect,multiple,image,handleCrop,className,close,children,inputProps,isCropperOpen}) => (<>
  <label htmlFor={name} className={className}>
    <input
      name={name}
      multiple={multiple}
      id={name}
      type="file"
      className="hidden"
      {...inputProps}
    />
    {children}
  </label>
  {isCropperOpen && <AvatarCrop src={image} aspect={aspect} onCrop={handleCrop} close={close} />}
</>)

export const useAvatarCropper = (onError = () => {}) => {
  const [image,setImage] = useState(),
        [file,setFile] = useState(),
        loaderDispatch = useLoaderDispatch(),
        [isCropperOpen,setIsCropperOpen] = useState(),
        prevFile = useRef(file),
        convertFileToImg = useCallback(
          async (file) => {
            try {
              loaderDispatch(startLoading());
              setImage(await readFile(file))
              setIsCropperOpen(true)
              prevFile.current = file;
            } catch (e) {
              setFile()
              onError(e)
            } finally {
              loaderDispatch(stopLoading());
            }
          },
          []
        )
  useEffect(() => {
    if(file && file !== prevFile.current){
      convertFileToImg(file)
    }
  },[file,convertFileToImg])

  return {
    file,
    setFile,
    image,
    setImage,
    isCropperOpen,
    close: () => {
      setIsCropperOpen()
    }
  }
}

export const useDropzoneHandlers = (setFile,accept = "image/*") => {
  const onDrop = useCallback((droppedFiles) => {
          if(droppedFiles && droppedFiles.length){
            setFile(droppedFiles[0])
          }
        },[]),
        dropZone = useDropzone({
          onDrop,
          accept,
          multiple: false
        });
  return dropZone
}
const FileInput = ({ name, label = name,aspect, isFile,value, width, height, style,onCrop,dropzoneClassName, accept, multiple, placeholder, maxSize, minSize, large, className,form }) => {
  const styleObj = style ? style : large ? {width: 480, height: (height / width) * 480} : { width, height },
        addToast = useToastAddAction(),
        handleError = () => {
          addToast("Unable to add your photo");
        },
        {setFile,image,setImage,isCropperOpen,close} = useAvatarCropper(handleError),
        { getRootProps, getInputProps, isDragActive } = useDropzoneHandlers(setFile,accept),
        handleClose = () => {
          setFile();
          setImage();
          close();
        },
        handleCrop = (img) => {
          onCrop(img);
          handleClose();
        }
  return (
    <div className={classNames(styles.container, className)}>
      <div style={styleObj} className={classNames(styles.dropzone,dropzoneClassName, "selectable", isDragActive && styles.active)} {...getRootProps()}>
        {value ? (
            <img
              className={styles.image}
              src={value}
              alt="File"
            />
          ) : (
            "Drop file"
          )}
      </div>
      <CropperInput
        name={name}
        multiple={multiple}
        image={image}
        aspect={aspect}
        handleCrop={handleCrop}
        close={handleClose}
        inputProps={getInputProps()}
        isCropperOpen={isCropperOpen}
        className={styles.label}>
        <Button as="div" secondary className={styles.button} small>Choose File</Button>
        <Label>{image ? "No file chosen" : "No file chosen"}</Label>
      </CropperInput>
      {form && <Error name={name} className={styles.error} />}
    </div>
  )
}

export default FileInput
