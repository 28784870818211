import { Link } from 'react-router-dom';
import { PublicHeader } from '@components/Layouts/Public';
import Button from '@components/Button';
import styles from './index.module.css';

const WelcomePage = () => (<>
  <PublicHeader
    title="Welcome!"
    margin
    text="In order to proceed, please log in or create a free account."
  />
  <Button as={Link} to="registration" light className={styles.button}>Sign Up</Button>
  <Button as={Link} secondary to="login" light className={styles.button}>Log In</Button>
  <div className={styles.text}>Read our <a href="https://littlefreelibrary.org/privacy-policy/" target="_blank" rel="noopener noreferrer" className={styles.link}>Privacy Policy</a>.</div>
</>)

export default WelcomePage;
