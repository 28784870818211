import { Link } from 'react-router-dom';
import Page from '@components/Layouts/Page';
import Button from '@components/Button';
import ChallengeList from '@components/Challenge/List';
import { Pagination } from '@components/Pagination';
import withPageTitle from '@containers/withPageTitle';
import usePaginationRequest from '@containers/usePaginationRequest';

export const ChallengeListPageComponent = ({ request, ...props }) => (
  <Page
    right={(
      <Button as={Link} to="new" type="button" medium primary>New Challenge</Button>
    )}
    {...props}
  >
    <Pagination previousPage={request.previousPage} setPage={request.setPage} nextPage={request.nextPage} pageCount={request.state.data.page_count} page={request.state.data.page} status={request.state.status} error={request.state.error}>
      <ChallengeList challenges={request.state.data.challenges} text="No challenges found" />
    </Pagination>
  </Page>
)

const ChallengeListPageContainer = ({ url, ...props }) => {
  const request = usePaginationRequest(url, 25);
  return <ChallengeListPageComponent {...props} request={request} />
}

const ChallengeListPage = () => (
  <ChallengeListPageContainer
    title="Challenges"
    url="/challenges.json"
  />
)

export default withPageTitle(ChallengeListPage, "Challenges");
