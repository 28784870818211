import {makeRequest,deleteRequest} from '@utils/api';

export const addLibraryFlag = (library_c_id, app_flag_id) => makeRequest("POST","/library_flags.json",{
  library_flag: {
    library_c_id,
    app_flag_id
  }
})

export const removeLibraryFlag = (libraryFlagId) => deleteRequest(`/library_flags/${libraryFlagId}.json`)
