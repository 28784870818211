import { makeRequest, getRequest } from '@utils/api';
import { stringifyQuery } from '@utils';

export const createUser = (user, url) => makeRequest("POST", url, {
  user
})

export const updateUser = (user, url) => makeRequest("PATCH", url, {
  user
})

export const sendInvitation = (id) => makeRequest("POST", `/users/${id}/invite.json`) // NOTE this doesn't seem to exist on the backend

export const resendConfirmation = (id) => makeRequest("POST", `/user/${id}/resend_email.json`)

export const cancelEmailConfirmation = () => makeRequest("POST", "/user/me/clear_email.json")

export const exportUsers = (query) => getRequest(`/user/export.json${query ? `?${stringifyQuery(query)}` : ''}`)
