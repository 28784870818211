import {createContext,useReducer,useContext,useCallback} from 'react';
import ToastList from '@components/Toaster/List';

const ADD_TOAST = "ADD_TOAST";
const REMOVE_TOAST = "REMOVE_TOAST";
const ToastDispatchContext = createContext();
const initialState = [];
var count = 0;

export const addToastAction = (text,onClick) => ({
  type: ADD_TOAST,
  payload: {
    id: count++,
    text,
    onClick
  }
})

function authReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TOAST:
      return [...state, action.payload]
    case REMOVE_TOAST:
      return state.filter(toast => toast.id !== action.payload)
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

function ToastProvider({children}) {
  const [toasts, toastsDispatch] = useReducer(authReducer, initialState),
        addToast = (text,onClick) => toastsDispatch(addToastAction(text,onClick)),
        removeToast = useCallback(id => toastsDispatch({type: REMOVE_TOAST, payload: id}),[])
  return (
    <ToastDispatchContext.Provider value={addToast}>
      {children}
      <ToastList toasts={toasts} remove={removeToast} />
    </ToastDispatchContext.Provider>
  )
}

function useToastAddAction() {
  const context = useContext(ToastDispatchContext);
  if (context === undefined) {
    throw new Error('useToastAddAction must be used within a ToastProvider');
  }
  return context;
}

export {ToastProvider, useToastAddAction};
