import RequestButton from '@containers/RequestButton';
import { resendConfirmation, cancelEmailConfirmation } from '@utils/api/user';

const handleConfirmationButtonClick = (email) => () => new Promise(
  async (resolve, reject) => {
    try {
      resolve(await resendConfirmation(email))
    } catch (e) {
      reject(e)
    }
  }
)

const handleCancelEmailConfirmationButtonClick = () => () => new Promise(
  async (resolve, reject) => {
    try {
      resolve(await cancelEmailConfirmation())
    } catch (e) {
      reject(e)
    }
  }
)

const ConfirmationResendButton = ({ title = "Re-Send Confirmation Email", email, toast = "Comfirmation email sent", children, ...props }) => (
  <RequestButton {...props} toast={toast} makeRequest={handleConfirmationButtonClick(email)}>{title}</RequestButton>
)

export const CancelEmailConfirmationButton = ({ title = "Cancel email change", email, toast = "Email change cancelled", children, ...props }) => (
  <RequestButton {...props} toast={toast} makeRequest={handleCancelEmailConfirmationButtonClick()}>{title}</RequestButton>
)

export default ConfirmationResendButton;
