import {useState,useCallback} from 'react';
import Page from '@components/Layouts/Page';
import {LibraryTransferRow} from '@components/LibraryTransfer/List';
import Col from '@components/Sort/Col';
import ErrorDisplay from '@components/ErrorDisplay';
import CreateTransferButton from '@components/LibraryTransfer/CreateModal';
import {RequestLoader} from '@components/Loader';
import {Buttons} from '@components/Pagination';
import {SelectComponent} from '@components/Form/Fields/Select';
import withPageTitle from '@containers/withPageTitle';
import EmptyResourcePlaceholder from '@components/EmptyResourcePlaceholder';
import {Table,Header,Row} from '@components/Table';
import usePaginationRequest from '@containers/usePaginationRequest';
import useEffectAfterMount from '@containers/useEffectAfterMount';

const statusFilterOptions = [{value: "all", name: "All Statuses"},{value: "active", name: "Active"},{value: "complete", name: "Complete"},{value: "expired", name: "Expired"}]

const SortableLibraryTransferList = ({sort,setSort,request,onUpdate,onDelete}) => {
  if(request.state.status === "error"){
    return <ErrorDisplay error={request.state.error} />
  } else if (request.state.status === "success" && request.state.data.library_transfers?.length === 0){
    return <EmptyResourcePlaceholder text="No library transfers found" hideButton />
  }
  return (<>
    <Table>
      <Header>
        <div><Col sort={sort} text="Library" sortKey="library_name" setSort={setSort} /></div>
        <div><Col sort={sort} text="Initiated By" sortKey="initiated_by" setSort={setSort} /></div>
        <div><Col sort={sort} text="New Steward" sortKey="new_steward" setSort={setSort} /></div>
        <div><Col sort={sort} text="New Steward Email" sortKey="library_transfers.steward_email" setSort={setSort} /></div>
        <div><Col sort={sort} text="Status" sortKey="status" setSort={setSort} /></div>
        <div><Col sort={sort} text="Date Created" sortKey="library_transfers.created_at" setSort={setSort} /></div>
      </Header>
      {request.state.status === "success" && request.state.data.library_transfers?.map(r => (
        <Row key={r.id}>
          <LibraryTransferRow transfer={r} onUpdate={onUpdate} onDelete={onDelete} />
        </Row>
      ))}
    </Table>
    {request.state.status === "loading" ? (
      <RequestLoader />
    ) : (
      <Buttons previousPage={request.previousPage} setPage={request.setPage} nextPage={request.nextPage} pageCount={request.state.data.page_count} page={request.state.data.page} />
    )}
  </>)
}

export const LibraryTransferListPageComponent = ({request,...props}) => {
  const [statusFilter,setStatusFilter] = useState(statusFilterOptions[0].value),
        [sort,setSort] = useState("library_transfers.created_at-desc"),
        makeRequest = useCallback(() => {
          const query = {};
          if(statusFilter === "active"){
            query.active = true
          } else if(statusFilter === "complete"){
            query.complete = true
          } else if(statusFilter === "expired"){
            query.expired = true
          }
          if(sort){
            query.order = sort
          }
          request.setQuery(query)
        },[statusFilter,sort]),
        updateLibraryTransfer = (transfer) => {
          request.updateData({
            ...request.state.data,
            library_transfers: request.state.data.library_transfers.map(t => {
              if(t.id === transfer.id)
                return Object.assign({}, t, transfer)
              return t
            })
          })
        },
        deleteLibraryTransfer = (id) => {
          request.updateData({
            ...request.state.data,
            library_transfers: request.state.data.library_transfers.filter(t => t.id !== id)
          })
        };
  useEffectAfterMount(() => {
    makeRequest();
  },[makeRequest]);
  return(
    <Page
      {...props}
      left={(
        <SelectComponent value={statusFilter} options={statusFilterOptions} onChange={(e) => {setStatusFilter(e.target.value)}} />
      )}
      right={(
        <CreateTransferButton onSuccess={makeRequest} />
      )}
    >
      <SortableLibraryTransferList request={request} sort={sort} setSort={setSort} onDelete={deleteLibraryTransfer} onUpdate={updateLibraryTransfer} />
    </Page>
  )
}

const LibraryTransferListPageContainer = ({url,...props}) => {
  const request = usePaginationRequest(url,25);
  return <LibraryTransferListPageComponent {...props} request={request} />
}

const LibraryTransferListPage = () => (
  <LibraryTransferListPageContainer
    title="Library Transfers"
    url="/library_transfers.json"
  />
)

export default withPageTitle(LibraryTransferListPage, "Library Transfers");
