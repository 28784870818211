import {useState,useRef,useEffect,Children,cloneElement,isValidElement} from "react";
import {classNames} from '@utils';
import styles from './index.module.css';

function debounce(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}

const Slider = ({children,className,visibleCount = 1}) => {
  const [swipeDelta,setSwipeDelta] = useState();
  const [width,setWidth] = useState();
  const [idx,setIdx] = useState(0);
  const containerRef = useRef();
  const startX = useRef();
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setWidth(containerRef.current.getBoundingClientRect().width)
    }, 66)
    window.addEventListener('resize', debouncedHandleResize)
    return _ => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  },[setWidth])
  useEffect(() => {
    if(containerRef.current){
      setWidth(containerRef.current.getBoundingClientRect().width)
    }
  },[])
  const setSliderXPosition = (e) => {
    setSwipeDelta(e.touches[0].clientX - startX.current)
  }
  const handleLeftClick = () => {
    if(idx <= 0){
      setIdx(Children.count(children) - 1)
    } else {
      setIdx(idx - 1)
    }
  }
  const handleRightClick = () => {
    if(idx >= Children.count(children) - 1){
      setIdx(0)
    } else {
      setIdx(idx + 1)
    }
  }
  const beginSwipe = (e) => {
    startX.current = e.touches[0].clientX
  }
  const endSwipe = (e) => {
    if(Math.abs(swipeDelta) > ((width / visibleCount) / 2)){
      if(swipeDelta > 0){
        handleLeftClick()
      } else {
        handleRightClick()
      }
    }
    setSwipeDelta(null)
  }
  const renderItems = () => {
    let style = {width: `${width / visibleCount}px`}
    return Children.map(children, (child, i) => isValidElement(child) ? cloneElement(child, { style }) : child)
  }
  if (!children || Children.count(children) === 0) {
      return null;
  }
  let disableLeft = idx <= 0,
      disableRight = idx >= Children.count(children) - visibleCount,
      left = (width / visibleCount) * idx;
  if(swipeDelta){
    left += -1*swipeDelta
  }
  left *= -1
  return(
    <div className={classNames(styles.container, className)}>
      <div className={classNames(styles.button, disableLeft && styles.disabled)} onClick={disableLeft ? undefined : handleLeftClick}>
        <svg viewBox="0 0 24 24"><path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
      </div>
      <div className={styles.outer} ref={containerRef}>
      <div className={styles.inner} style={{left}} onTouchStart={beginSwipe} onTouchMove={setSliderXPosition} onTouchEnd={endSwipe} onTouchCancel={endSwipe}>
        {renderItems()}
      </div>
      </div>
      <div className={classNames(styles.button, disableRight && styles.disabled)} onClick={disableRight ? undefined : handleRightClick}>
        <svg viewBox="0 0 24 24"><path d="M5.88 4.12L13.76 12l-7.88 7.88L8 22l10-10L8 2z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
      </div>
    </div>
  )
}

export default Slider;
