import {TransferForm,TransferFields} from '@components/LibraryTransfer/CreateModal';
import Content from '@components/Layouts/Content';
import {FormPageTitle} from '@components/Library/Form';
import withPageTitle from '@containers/withPageTitle';
import withParams from '@containers/withParams';

const LibraryTransfer = withParams(({params:{libraryId}}) => (
  <TransferForm libraryId={libraryId} to="../edit">
    <Content
      title={(<FormPageTitle title="Transfer Information" buttonText="Transfer" />)}>
      <TransferFields />
    </Content>
  </TransferForm>
))

export default withPageTitle(LibraryTransfer,"Transfer Your Library")
