import { useState } from 'react';
import { InfoWindow } from '@react-google-maps/api';
import Button from '@components/Button';
import Slider from '@components/Slider';
import { Gallery } from '@components/Form/Fields/AvatarCrop';
import { useProgressiveImg } from '@components/Photos/Item';
import { InfoSection, InfoModal } from '../Filters/FilterPanel';
import { makeLibraryName, classNames } from '@utils';
import styles from './index.module.scss';
import './index.css';
import { useAuthState } from '@containers/Auth';
import { MixpanelConsumer } from 'react-mixpanel';

function Photo({src,className,...props}){
  const img = useProgressiveImg(src)
  return img === src ? (
    <img
      {...props}
      className={classNames(styles.image,className)}
      src={img}
    />
  ) : null
}

const handleReadMoreClick = (mixpanel, library, user, setModal) => {
  user && mixpanel.identify(user.id);
  mixpanel.track('Library Story Read More', {
    'Library': library.id,
    'Library Name': library.Name,
    'Library Story': library.Library_Story__c,
    'User': user ? user.id : 'Anonymous'
  });
  setModal("story");
}

const showGallery = (mixpanel, library, user, setModal) => {
  user && mixpanel.identify(user.id);
  mixpanel.track('Library Photo View', {
    'Library': library.id,
    'Library Name': library.Name,
    'User': user ? user.id : 'Anonymous'
  });
  setModal("gallery");
}

const Story = ({onReadMoreClick,story}) => (
  <div className={styles.story}>
    {story.length > 200 ? (<>
      {`${story.slice(0,200)}... `}<button type="button" onClick={onReadMoreClick} className={styles.readmore}>read more</button>
    </>) : (
      story
    )}
  </div>
)

const LibraryInfoWindow = ({setOpenLibraryInfo,setOpenLibraryPage,library,isMobile}) => {
  const [modal,setModal] = useState();
  const user = useAuthState();
  const closeModal = () => setModal(false)
  const libraryName = makeLibraryName(library);
  return(<>
    <InfoWindow position={{
      lat: library.Library_Geolocation__Latitude__s,
      lng: library.Library_Geolocation__Longitude__s
    }} options={{pixelOffset: new window.google.maps.Size(0, -45), maxWidth: 340, minWidth: 300, disableAutoPan: true}} onCloseClick={() => setOpenLibraryInfo(null)}>
      <div className={styles.container}>

        <div className={styles.content}>
          <div className={styles.textWrapper}>
            <div className={styles.name}>{libraryName}</div>
            <div className={styles.street}>{library.Street__c ? `${library.Street__c}, ` : ""}{library.City__c}, {library.State_Province_Region__c}</div>
            {library.Email_on_Map__c && (
              <a href={`mailto:${library.Primary_Steward_s_Email__c}`} className={styles.email}>{library.Primary_Steward_s_Email__c}</a>
            )}
            <div className={styles.charter}>Charter #{library.Official_Charter_Number__c}</div>
          </div>
          <div className={styles.indicators}>
            {library.active_flags.map(fl=> (
              <Button key={fl.id} type="button" xs className={styles.flag}>
                <div className={styles.label}>{fl.app_flag.Name}</div>
              </Button>
            ))}
          </div>
          <MixpanelConsumer>
            {mixpanel => (
              <>
                {library.image_urls.length > 0 && (
                  <div className={styles.imageWrapper} onClick={() => showGallery(mixpanel, library, user, setModal)}>
                    <div className={styles.imageNumber}>1/{library.image_urls.length}</div>
                    <Photo src={library.image_urls[0]} className={styles.infoImage} />
                  </div>
                )}
                {library.Library_Story__c && (
                  <Story onReadMoreClick={() => handleReadMoreClick(mixpanel, library, user, setModal)} story={library.Library_Story__c} />
                )}
              </>
            )}
          </MixpanelConsumer>
        </div>
      </div>
    </InfoWindow>
    {modal === 'story' && (
      <InfoModal close={closeModal} closeOnOverlayClick>
        <InfoSection title={libraryName}>
          {library.Library_Story__c}
        </InfoSection>
      </InfoModal>
    )}
    {modal === 'gallery' && (
      <Gallery close={closeModal} className={styles.gallery}>
        <Slider className={styles.slider}>
          {library.image_urls.map(img => (
            <div className={styles.largeImageWrapper}>
              <Photo key={img} src={img} className={styles.largeImage} />
            </div>
          ))}
        </Slider>
      </Gallery>
    )}
  </>)
}

export default LibraryInfoWindow
