import LibraryFormPage from '@components/Library/Form';
import withPageTitle from '@containers/withPageTitle';
import {createLibrary} from '@utils/api/library';

const LibraryCreatePage = () => (
  <LibraryFormPage
    form={{
      url: "/libraries.json",
      onSubmit: createLibrary
    }}
    page={{
      title: "Create Library",
      back: "Back To Librarys",
      submit: "Create New Library"
    }}
  />
)

export default withPageTitle(LibraryCreatePage, "Create Library");
