import styles from './index.module.css';
import {classNames} from '@utils';

const LinkText = ({children,className}) => (
  <div className={classNames(styles.container,className)}>
    {children}
  </div>
)

export default LinkText;
