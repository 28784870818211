import { memo } from 'react'
import { Marker, MarkerClusterer } from '@react-google-maps/api';
import m1 from '@assets/images/m1.png';
import m2 from '@assets/images/m2.png';
import m3 from '@assets/images/m3.png';
import m4 from '@assets/images/m4.png';
import m5 from '@assets/images/m5.png';

const clustererStyles = [
  {
    url: m1,
    height: 53,
    width: 53
  },
  {
    url: m2,
    height: 56,
    width: 56
  },
  {
    url: m3,
    height: 66,
    width: 66
  },
  {
    url: m4,
    height: 78,
    width: 78
  },
  {
    url: m5,
    height: 90,
    width: 90
  }
]
const LibraryMarker = ({library,onClick,...props}) => {
  return(
    <Marker
      {...props}
      onClick={() => {onClick(library)}}
      position={{
        lat: library.Library_Geolocation__Latitude__s,
        lng: library.Library_Geolocation__Longitude__s
      }}
    />
  )
}

const LibraryMarkers = memo(({libraries = [],onMarkerClick,onClusterClick}) => (
  <MarkerClusterer onClick={onClusterClick} zoomOnClick={false} maxZoom={15} styles={clustererStyles}>
    {(clusterer) => libraries.map(lib => (
      <LibraryMarker
        key={lib.id}
        onClick={onMarkerClick}
        library={lib}
        clusterer={clusterer}
      />
    ))}
  </MarkerClusterer>
))

export default LibraryMarkers
