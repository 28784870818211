const useAccessorFunction = (
    accessor = (r) => r
) => (
    typeof accessor === 'function' ? (
        (r) => accessor(r)
    ) : (
        (r) => r?.[accessor]
    )
)
export default useAccessorFunction;
