import {PublicForm} from '@components/Layouts/Public';
import Input from '@components/Form/Fields/Input';
import withQueryStringValidation from '@containers/withQueryStringValidation';
import {confirmPasswordSchema,invitePageValidation} from '@utils/validation/auth';
import {acceptInvitation} from '@utils/api/auth';

const InvitationConfirmation = ({query}) => (
  <PublicForm buttonText="Set Password" onSubmit={acceptInvitation} defaultValues={{invitation_token: query?.invitation_token}} to="/login" toast="Your password has been set, you may now log in" validationSchema={confirmPasswordSchema}>
    <Input name="invitation_token" className="hidden" />
    <Input type="password" name="password" secondary round placeholder="Password" />
    <Input type="password" name="password_confirmation" secondary round placeholder="Confirm Password" />
  </PublicForm>
)

export default withQueryStringValidation(InvitationConfirmation, invitePageValidation, "/login");
