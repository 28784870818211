import { useCallback, useRef, useEffect } from 'react';

export function useEventListener(
  eventName,
  handler,
  element = typeof window !== "undefined" ? window : {}
) {

  const savedCallback = useRef(handler)

  useEffect(() => {
    savedCallback.current = handler
  }, [handler])

  useEffect(() => {
    const eventListener = (e) => {
      if(savedCallback.current){
        savedCallback.current(e)
      }
    }
    if(eventName !== null && element && element.addEventListener){
      element.addEventListener(eventName, eventListener);
      return () => element.removeEventListener(eventName, eventListener)
    }
  }, [eventName, element]);
}

export default function useOnClickOutside(
  ref,
  handler
){

  const listener = useCallback((e) => {
    if (!ref.current || ref.current.contains(e.target) || !handler) {
      return;
    }
    handler(e)
  },[handler,ref])

  useEventListener("mousedown",listener)
  useEventListener("touchstart",listener)
}
