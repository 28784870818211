export class RequestError extends Error {
  constructor(status, message = "An unexpected error has occurred") {
    super(status)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RequestError)
    }
    this.name = 'RequestError';
    this.status = status;
    this.message = message;
  }
}

export class AuthorizationError extends Error {
  constructor(status) {
    super(status)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AuthorizationError)
    }
    this.name = 'AuthorizationError';
  }
}

export class TokenError extends Error {
  constructor(error) {
    super(error)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, TokenError)
    }
    this.name = 'TokenError';
    this.formError = error
  }
}

export class FormError extends Error {
  constructor(status,fieldErrors = {},formError) {
    super(status)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FormError)
    }
    this.name = 'FormError';
    this.fieldErrors  = fieldErrors;
    this.formError = formError
  }
}

const makeFormErrorObj = (r = {}) => Object.keys(r).map(name => ({
  type: "manual",
  name,
  message: `${name} ${r[name][0]}`
}))

export default function handleError(e){
  if(e.response.status === 401){
    throw new AuthorizationError(e.response.status)
  } else if(e.response.status === 400 && e.response.data?.error === "invalid_grant"){
    throw new TokenError(e.response.data.error_description)
  } else if(e.response.status === 422){
    throw new FormError(e.response.status, makeFormErrorObj(e.response.data),e.response.data.error_description || e.response.data?.errors?.[0])
  } else {
    throw new RequestError(e.response.status)
  }
}
