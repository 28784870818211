import {useCallback,useEffect,useReducer} from 'react';
import {RequestLoader} from '@components/Loader';
import ErrorDisplay from '@components/ErrorDisplay';
import {useAuthDispatch, logoutAction} from '@containers/Auth';
import {getRequest} from '@utils/api';
import {TokenError} from '@utils/api/error';
import {FormError} from '../utils/api/error';

const REQUEST = "REQUEST";
const REQUEST__SUCCESS = "REQUEST__SUCCESS";
const REQUEST__FAILURE = "REQUEST__FAILURE";
const UPDATE_DATA = "UPDATE_DATA";

const initialState = {status: "loading", data: null, error: null};
function requestReducer(state = initialState, {type,payload}) {
  switch (type) {
    case REQUEST:
      return initialState;
    case REQUEST__SUCCESS:
      return {
        status: "success",
        data: payload,
        error: null
      }
    case REQUEST__FAILURE:
      return {
        status: "error",
        data: null,
        error: payload || "An unexpected error has occurred"
      }
    case UPDATE_DATA:
      return {
        ...state,
        data: payload
      }
    default:
      throw new Error(`Unhandled action type: ${type}`)
  }
}

export const useRequest = (url) => {
  const [state, dispatch] = useReducer(requestReducer, initialState),
        authDispatch = useAuthDispatch(),
        makeRequest = useCallback(async () => {
          dispatch({type: REQUEST})
          try {
            dispatch({type: REQUEST__SUCCESS, payload: await getRequest(url)})
          } catch (e) {
            if(e instanceof TokenError) {
              authDispatch(logoutAction())
            } else if (e instanceof FormError) {
              dispatch({type: REQUEST__FAILURE, payload: e.formError})
            }else {
              dispatch({type: REQUEST__FAILURE, payload: "An unexpected error has occurred"})
            }
          }
        },[url,dispatch]),
        updateData = (payload) => dispatch({type: UPDATE_DATA, payload})

  useEffect(() => {
    makeRequest();
  },[makeRequest])

  return {state,updateData,makeRequest};
}

export default function Request({url,children}){
  const {state:{status,data,error},updateData,makeRequest} = useRequest(url);
  if(status === "loading"){
    return <RequestLoader />
  }
  if(status === "error"){
    return <ErrorDisplay error={error} />
  }
  return children(data,makeRequest,updateData);
};
