import {useState} from 'react';
import Content from '@components/Layouts/Content';
import IssueSection from '@components/Issue/Section';
import EmptyResourcePlaceholder from '@components/EmptyResourcePlaceholder';
import {SelectComponent} from '@components/Form/Fields/Select';
import RequestStatus from '@components/RequestStatus';
import {withRequestState,useRequestDispatch} from '@containers/RequestContext';
import styles from './index.module.css';

const IssuePageComponent = ({issues,filter,...props}) => {
  if(filter === "all"){
    const unresolved = issues.filter(({Resolved__c}) => !Resolved__c),
          resolved = issues.filter(({Resolved__c}) => Resolved__c);

    return (unresolved.length === 0 && resolved.length === 0) ? (
      <EmptyResourcePlaceholder text="No one has left an issue yet" hideButton />
    ) : (<>
      {unresolved.length > 0 && <IssueSection {...props} title="Active" issues={unresolved} />}
      {resolved.length > 0 && <IssueSection {...props} title="Resolved" issues={resolved} />}
    </>)
  }
  return <IssueSection {...props} title={filter} issues={issues.filter(({Resolved__c}) =>  filter === "Active" ? !Resolved__c : Resolved__c)} placeholder={`There are no ${filter.toLowerCase()} issues`} />
}

const filterOptions = [{value: "all", name: "All"},{value: "Active", name: "Active"},{value: "Resolved", name: "Resolved"}]

const IssuePage = withRequestState(({request}) => {
  const [filter,setFilter] = useState(filterOptions[0].value),
        dispatch = useRequestDispatch(),
        issues = request.data?.library_issues ? request.data.library_issues : [];
  const handleResolvedUpdateSuccess = (id,Resolved__c) => {
          dispatch.updateData({
            ...request.data,
            library_issues: request.data.library_issues.map(issue => (
              issue.id === id ? (
                Object.assign({},issue,{Resolved__c})
              ) : (
                issue
              )
            ))
          })
        };
  const handleDeletedIssueSuccess = (id) => {
          dispatch.updateData({
            ...request.data,
            library_issues: request.data.library_issues.filter(issue => issue.id !== id)
          })
        };
  return(
    <Content
      title={(
        <div className={styles.flex}>
          <div>Issues</div>
          <SelectComponent value={filter} options={filterOptions} onChange={(e) => {setFilter(e.target.value)}} />
        </div>
      )}
      noPadding
    >
      <RequestStatus status={request.status} error={request.error}>
        <IssuePageComponent filter={filter} issues={issues} onIssueUpdateSuccess={handleResolvedUpdateSuccess} onIssueDeleteSuccess={handleDeletedIssueSuccess}/>
      </RequestStatus>
    </Content>
  )
})

export default IssuePage;
