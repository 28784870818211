import LibraryFormPage from '@components/Library/Form';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import RequestStatus from '@components/RequestStatus';
import {withRequestState,withRequestStateUpdate} from '@containers/RequestContext';
import {updateLibrary} from '@utils/api/library';
import {makeLibraryName} from '@utils';

const makeDefaultValues = ({
  id,
  Official_Charter_Number__c,
  Street__c,
  HowToFind__c,
  Country__c,
  City__c,
  State_Province_Region__c,
  Postal_Zip_Code__c,
  Latitude_Form__c,
  Longitude_Form__c,
  DownUntil__c,
  Library_Story__c,
  Facebook__c,
  Instagram__c,
  Twitter__c,
  Pinterest__c,
  Name_on_Map__c,
  Map_Me__c,
  Email_on_Map__c,
  active_flags,
  Library_Geolocation__Latitude__s,
  Library_Geolocation__Longitude__s,
  Announcement__c,
  ...library
}) => ({
  id,
  Official_Charter_Number__c,
  List_As_Name__c: makeLibraryName(library),
  Street__c,
  HowToFind__c,
  Country__c,
  City__c,
  State_Province_Region__c,
  Postal_Zip_Code__c,
  Latitude_Form__c: Library_Geolocation__Latitude__s,
  Longitude_Form__c: Library_Geolocation__Longitude__s,
  DownUntil__c,
  Library_Story__c,
  Facebook__c,
  Instagram__c,
  Twitter__c,
  Pinterest__c,
  Name_on_Map__c,
  Map_Me__c,
  Email_on_Map__c,
  Announcement__c,
  active_flags
})
const LibraryUpdatePageComponent = ({library = {},libraryId,updateData}) => (
  <LibraryFormPage
    form={{
      url: `/libraries/${libraryId}.json`,
      defaultValues: makeDefaultValues(library),
      onSubmit: updateLibrary,
      toast: "Library successfully updated",
      onSuccess: ({response}) => {updateData(response)}
    }}
    library={library}
    libraryId={libraryId}
  />
)

const LibraryUpdatePage = withParams(withRequestState(withRequestStateUpdate(({params:{libraryId},request:{data,status,error},updateData}) => (
  <RequestStatus status={status} error={error}>
    <LibraryUpdatePageComponent library={data} libraryId={libraryId} updateData={updateData} />
  </RequestStatus>
))));

export default withPageTitle(LibraryUpdatePage, "Update Library");
