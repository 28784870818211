import {useState} from 'react';
import Page from '@components/Layouts/Page';
import UserList from '@components/User/List';
import SearchFilter from '@components/SearchFilter';
import ExportUsersButton from '@components/User/ExportButton';
import {Pagination} from '@components/Pagination';
import {SelectComponent} from '@components/Form/Fields/Select';
import withPageTitle from '@containers/withPageTitle';
import usePaginationRequest from '@containers/usePaginationRequest';
import useEffectAfterMount from '@containers/useEffectAfterMount';
import Button from '@components/Button';
import { format } from 'date-fns';
import DateFilter from '@components/DateFilter'
import styles from './index.module.css'


const statusFilterOptions = [{value: "", name: "All Statuses"},{value: "active", name: "Active"},{value: "inactive", name: "Inactive"}]
export const UserListPageComponent = ({request,...props}) => {
  const [statusFilter,setStatusFilter] = useState(statusFilterOptions[0].value),
        [searchFilter,setSearchFilter] = useState(),
        defaultDateValues = {
          dateFrom: "",
          dateTill: "",
        },
        [filterDates, setFilterDates] = useState(defaultDateValues);

  useEffectAfterMount(() => {
    const query = {};
    const formatDate = (date) => format(date, 'yyyy-MM-dd');
    if(statusFilter) {
      query.active = statusFilter === "active";
    }
    if(searchFilter){
      query.search = searchFilter;
    }
    if (filterDates) {
      if (filterDates['dateFrom']) {
        query.created_at_from = formatDate(filterDates['dateFrom']);
      }
      if (filterDates['dateTill']) {
        query.created_at_to = formatDate(filterDates['dateTill']);
      }
    }
    request.setQuery(query)
  },[statusFilter,searchFilter,filterDates]);
  return(
    <Page
      left={(<>
        <SearchFilter onChange={setSearchFilter} placeholder="Search..." />
        <SelectComponent value={statusFilter} options={statusFilterOptions} className={styles.medInput} onChange={(e) => {setStatusFilter(e.target.value)}} />
        <div className={styles.dateContainer}>
          <span>
            Signed Up:
          </span>
          <DateFilter dateFilter={filterDates['dateFrom']} onChange={(date) => setFilterDates({...filterDates, dateFrom: date})} />
          <span>-</span>
          <DateFilter dateFilter={filterDates['dateTill']} onChange={(date) => setFilterDates({...filterDates, dateTill: date})} />
        </div>
        <ExportUsersButton statusFilter={statusFilter} searchFilter={searchFilter} filterDates={filterDates} />
      </>)}
      {...props}
    >
      <Pagination previousPage={request.previousPage} setPage={request.setPage} nextPage={request.nextPage} pageCount={request.state.data.page_count} page={request.state.data.page} status={request.state.status} error={request.state.error}>
        <UserList users={request.state.data.users} text="There are no users matching this criteria" />
      </Pagination>
    </Page>
  )
}

const UserListPageContainer = ({url,...props}) => {
  const request = usePaginationRequest(url,25);
  return <UserListPageComponent {...props} request={request} />
}

const UserListPage = () => (
  <UserListPageContainer
    title="Users"
    url="/users.json"
  />
)

export default withPageTitle(UserListPage, "Users");
