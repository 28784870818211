import styles from './index.module.css';
import googlePlay from '@assets/images/google-play-badge-300x89.png';
import appStore from '@assets/images/download-on-the-app-store.png';

const MapYourLibraryRedirectPage = ({logout}) => (
  <div className={styles.container}>
    <div className={styles.header}>
      Success!
    </div>
    <div className={styles.text}>
      Make sure to download our mobile app.
    </div>
    <div className={styles.badgeWrapper}>
      <a href="https://play.google.com/store/apps/details?id=org.littlefreelibrary.littlefreelibrary"><img src={googlePlay} width="150" height="60" alt="Get it on Google Play" border="0" /></a>
      <a href="https://apps.apple.com/us/app/little-free-library/id1555760218"><img src={appStore} width="150" height="60" alt="Get it in the AppStore" border="0" /></a>
    </div>
  </div>
)

export default MapYourLibraryRedirectPage;
