import AnnouncementFormPage from '@components/Announcement/Form';
import Request from '@containers/Request';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import { updateAnnouncement } from '@utils/api/announcement';
import { format, parseISO } from "date-fns";

const makeDefaultValues = ({ Start_Time__c, End_Time__c, app_flags, ...announcement }) => {
  return {
    ...announcement,
    Start_Time__c:
      Start_Time__c && format(parseISO(Start_Time__c), "yyyy-MM-dd"),
    End_Time__c: End_Time__c && format(parseISO(End_Time__c), "yyyy-MM-dd"),
    app_flag_ids: app_flags.length && app_flags.map(item => { return { value: item.id, label: item.Name } })
  };
};

const AnnouncementUpdatePage = withParams(({ params: { id } }) => {
  const url = `/announcements/${id}.json`;
  return (
    <Request url={url}>
      {(announcement, makeRequest, updateData) => (
        <AnnouncementFormPage
          form={{
            url,
            onSubmit: updateAnnouncement,
            defaultValues: makeDefaultValues(announcement),
            toast: "Announcement updated"
          }}
          page={{
            title: announcement.AnnouncementName__c,
            back: true
          }}
        />
      )}
    </Request>
  )
})

export default withPageTitle(AnnouncementUpdatePage, "Update Announcement");
