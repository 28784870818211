import {PublicForm} from '@components/Layouts/Public';
import Input from '@components/Form/Fields/Input';
import {emailSchema} from '@utils/validation/auth';
import {forgotPassword} from '@utils/api/auth';

const ForgotPassword = () => (
  <PublicForm link={{to: "/login", text: "Back"}} to="/login" toast="You should receive a password reset email soon" buttonText="Recover Password" onSubmit={forgotPassword} validationSchema={emailSchema}>
    <Input type="email" name="email" placeholder="Email" secondary round />
  </PublicForm>
)

export default ForgotPassword;
