export function classNames(){
  return [...arguments].filter(s => typeof s === 'string').join(' ');
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export const uniq = (a) => a.filter(onlyUnique);

export const makeDateString = (date) => {
  if(!date) return date;
  const [y,m,d] = date.split('-');
  return `${m[0] === '0' ? m[1] : m}/${d[0] === '0' ? d[1] : d}/${y.substring(2)}`;
}

function buildFormData(formData, data, parentKey) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;
    formData.append(parentKey, value);
  }
}

export function getFormData(data) {
  const formData = new FormData();
  buildFormData(formData, data);
  return formData;
}

export const capitalize = (s) => s ? s.split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ') : ""


export function clean(obj){
   let stringfiedObj = JSON.stringify(obj, (key, value) => {
     return ['', null].includes(value) || (typeof value === 'object' &&(value.length === 0 || Object.keys(value).length === 0)) ? undefined : value;
   });
   let resObj = JSON.parse(stringfiedObj);
   let isEmptyPropsPresent = ['{}', '[]', '""', 'null'].some((key) => stringfiedObj.includes(key))
   if(isEmptyPropsPresent) {
     return clean(resObj);
   }
   return resObj;
}

export const getFormattedDate = (date) => {
  if(!date) return date;
  var d = new Date(date)
  var year = d.getFullYear();

  var month = (1 + d.getMonth()).toString();

  var day = d.getDate().toString();

  return month + '/' + day + '/' + year;
}

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

export const makeDateStr = (date) => {
  if(!date) return date;
  var d = new Date(date)
  return `${monthNames[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`
}

export const phoneFormat = (value) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;
  if (cvLength < 4) return currentValue;
  if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
  return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export const makeFileReqObj = async (file,name = "asset") => {
  const obj = {};
  if(!file){
    obj[name] = null;
  } else if(typeof file !== "string"){
    obj[name] = await getBase64(file[0])
  }
  return obj
}

const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
export const cashFormat = (v) => formatter.format(v.toLocaleString('fullwide', {useGrouping:false}))


export function dateOnly(dt){
  const d = new Date(dt);
  d.setHours(0,0,0,0);
  return d.getTime();
}

export function getFileNameFromUrl(url){
  if(!url) return
  const splitUrl = url.split("/");
  return splitUrl[splitUrl.length - 1]
}

export const makeLibraryName = (library) => library.List_As_Name__c || library.Library_Name__c || library.Name

export const makeRoleString = (user) => {
  if(!user) return "Guest"
  if(user.is_admin) return "Admin"
  if(user.is_steward) return "Steward"
  return "Guest"
}

export function debounce(func, timeout = 300){
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

export function mergeSchemas(...schemas) {
  const [first, ...rest] = schemas;

  return rest.reduce(
    (mergedSchemas, schema) => mergedSchemas.concat(schema),
    first
  );;
}

export const makeAddress = (l) => l.City__c && l.State_Province_Region__c && l.Postal_Zip_Code__c && (
  `${l.City__c}, ${l.State_Province_Region__c} ${l.Postal_Zip_Code__c}`
)

export const stringifyQuery = (obj) => {
  if (!obj) return '';
  var qs = '';
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (Array.isArray(obj[key])) {
        for (var i = 0; i < obj[key].length; i++) {
          qs += `&${key}=${obj[key][i]}`;
        }
      } else if (obj[key] === null) {
        qs += `&${key}=`;
      } else if (obj[key] !== undefined) {
        qs += `&${key}=${obj[key]}`;
      }
    }
  }
  return qs;
};

