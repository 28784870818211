import {useCallback,useEffect,useState} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import {Loader} from '@components/Loader';
import {PublicHeader} from '@components/Layouts/Public';
import withQueryStringValidation from '@containers/withQueryStringValidation';
import {useToastAddAction} from '@containers/ToastContext';
import {confirmPageValidation} from '@utils/validation/auth';
import styles from './index.module.css';

const ConfirmationPage = ({query,navigate}) => {
  const addToast = useToastAddAction(),
        [error,setError] = useState(),
        makeRequest = useCallback(async (response) => {
          try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/users/confirmation.json?confirmation_token=${query?.confirmation_token}`)
            if(res?.data?.role === "admin" || res?.data?.role === "steward"){
              navigate("/login");
              addToast("Your account has been confirmed, you can now log in");
            } else {
              navigate("/get-app");
              addToast("Your account has been confirmed, you can now log in through our mobile app");
            }
          } catch (e) {
            if(e?.response?.data?.errors?.length > 0){
              setError(typeof e.response.data.errors[0] === "string" ? e.response.data.errors[0] : "An unexpected error has occurred.")
            } else {
              setError("An unexpected error has occurred.")
            }
          }
        },[navigate,addToast,query])

  useEffect(() => {
    makeRequest()
  },[])
  return error ? (<>
    <PublicHeader
      text={error}
    />
  <div className={styles.linkWrapper}>
    <Link to="/" className={styles.link}>Home</Link>
  </div>

  </>) : (<>
    <PublicHeader
      text="Your account is being confirmed"
    />
    <Loader white />
  </>)
}

export default withQueryStringValidation(ConfirmationPage, confirmPageValidation, "/login");
