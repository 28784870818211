import {useState} from 'react';
import {Link} from 'react-router-dom';
import Page from '@components/Layouts/Page';
import Col from '@components/Sort/Col';
import ErrorDisplay from '@components/ErrorDisplay';
import {RequestLoader} from '@components/Loader';
import TableComponent from '@components/Table';
import {Buttons} from '@components/Pagination';
import {SelectComponent} from '@components/Form/Fields/Select';
import EmptyResourcePlaceholder from '@components/EmptyResourcePlaceholder';
import {Table,Header,Row} from '@components/Table';
import withPageTitle from '@containers/withPageTitle';
import {makeLibraryName,getFormattedDate} from '@utils';
import usePaginationRequest from '@containers/usePaginationRequest';
import useEffectAfterMount from '@containers/useEffectAfterMount';
import styles from './index.module.scss';
import RequestButton from '../../../containers/RequestButton';
import {deleteLibraryIssue} from '@utils/api/issue';
import ModalMenu from '../../ModalMenu';
import {ResolveButton} from '../List';
import {useAuthState} from '@containers/Auth';

const handleDeleteButtonClick = (id, request) => () => new Promise(
    async (resolve,reject) => {
      try {
        resolve(await deleteLibraryIssue(id))
        let data = request.state.data;
        data.library_issues = data.library_issues.filter((lib_issue) => lib_issue.id !== id);
        resolve(await request.updateData(data));
      } catch (e) {
        reject(e)
      }
    }
)
export const IssueDeleteButton = ({id, request, toast = "Library issue has been deleted", ...props}) => (
    <RequestButton form {...props} modalText={"Are you sure you want to delete this library issue?"} toast={toast} makeRequest={handleDeleteButtonClick(id, request)}>Delete Issue</RequestButton>
)


const LibraryIssueRow = ({issue, request}) => {
  const [issueState,setIssueState] = useState(issue);
  const user = useAuthState();
    return (<>
    <div><Link to={`libraries/${issueState.library.id}/edit`} className={styles.link}>{makeLibraryName(issueState.library)}</Link>
    </div>
    <div>{issueState.ReportedIssue__c}</div>
    <div><Link to={`users/${issueState.contact_info.id}`}
               className={styles.link}>{`${issueState.contact_info?.first_name} ${issueState.contact_info?.last_name}`}</Link>
    </div>
    <div><a href={`mailto:${issueState.contact_info?.email}`} className={styles.link}>{issueState.contact_info?.email}</a></div>
    <div>{issueState.Resolved__c && issueState.ResolvedByAdmin__c ? "Resolved(Admin)" : issueState.Resolved__c ?  "Resolved" : "Unresolved"}</div>
    <div>{issueState.created_at && getFormattedDate(issueState.created_at)}</div>
    <div><ModalMenu title={issueState.ReportedIssue__c}>
      {setModal => {
        const handleSuccess = () => {
          setIssueState({...issueState, Resolved__c: true, Read__c: true, ResolvedByAdmin__c: user.is_admin});
          setModal(false);
        }
        return (<>
          {!issueState.Resolved__c &&
              <ResolveButton form listView id={issueState.id} user={user} onSuccess={handleSuccess}>Resolve Issue</ResolveButton>
          }
          <IssueDeleteButton as="div" id={issueState.id} request={request} />
        </>)
      }}
    </ModalMenu></div>
  </>)
}

const LibraryIssueList = ({issues, text = "No library issues found"}) => (
    <TableComponent
        cols={["Library","Issue","Contact","Contact Email","Status","Date Created", ""]}
        rows={issues}
        renderRow={(issue) => <LibraryIssueRow issue={issue} />}
        noLink
        placeholder={{to: "new", text,hideButton: true}}
    />
)
const SortableLibraryTransferList = ({sort,setSort,request,updateTransfer}) => {
  if(request.state.status === "error"){
    return <ErrorDisplay error={request.state.error} />
  } else if (request.state.status === "success" && request.state.data.library_issues?.length === 0){
    return <EmptyResourcePlaceholder text="No library issues found" hideButton />
  }
  return (<>
    <Table>
      <Header>
        <div><Col sort={sort} text="Library" sortKey="library_name" setSort={setSort} /></div>
        <div><Col sort={sort} text="Issue" sortKey="library_issues.ReportedIssue__c" setSort={setSort} /></div>
        <div><Col sort={sort} text="Contact" sortKey="contact_name" setSort={setSort} /></div>
        <div><Col sort={sort} text="Contact Email" sortKey="contact_email" setSort={setSort} /></div>
        <div><Col sort={sort} text="Status" sortKey="library_issues.ResolvedByAdmin__c,library_issues.Resolved__c" setSort={setSort} /></div>
        <div><Col sort={sort} text="Date Created" sortKey="library_issues.created_at" setSort={setSort} /></div>
        <div>{""}</div>
      </Header>
      {request.state.status === "success" && request.state.data.library_issues?.map(r => (
        <Row key={r.id}>
          <LibraryIssueRow issue={r} request={request} />
        </Row>
      ))}
    </Table>
    {request.state.status === "loading" ? (
      <RequestLoader />
    ) : (
      <Buttons previousPage={request.previousPage} setPage={request.setPage} nextPage={request.nextPage} pageCount={request.state.data.page_count} page={request.state.data.page} />
    )}
  </>)
}
const statusFilterOptions = [{value: "all", name: "All Statuses"},{value: "resolved", name: "Resolved"},{value: "unresolved", name: "Unresolved"},{value: "admin_resolved", name:"Resolved(Admin)"}]

export const LibraryIssueListPageComponent = ({request,...props}) => {
  const [statusFilter,setStatusFilter] = useState(statusFilterOptions[0].value),
        [sort,setSort] = useState("library_issues.created_at-desc");

  useEffectAfterMount(() => {
    const query = {};
    if(statusFilter === "resolved"){
      query.resolved = true
    } else if(statusFilter === "unresolved"){
      query.unresolved = true
    } else if(statusFilter === "admin_resolved"){
      query.resolved_by_admin = true
    }
    if(sort){
      query.order = sort
    }
    request.setQuery(query)
  },[sort,statusFilter]);
  return(
    <Page
      {...props}
      left={(
        <SelectComponent value={statusFilter} options={statusFilterOptions} onChange={(e) => {setStatusFilter(e.target.value)}} />
      )}
    >
      <SortableLibraryTransferList request={request} sort={sort} setSort={setSort} />
    </Page>
  )
}

const LibraryIssueListPageContainer = ({url,...props}) => {
  const request = usePaginationRequest(url,25);
  return <LibraryIssueListPageComponent {...props} request={request} />
}

const LibraryIssueListPage = () => (
  <LibraryIssueListPageContainer
    title="Library Issues"
    url="/library_issues.json"
  />
)

export default withPageTitle(LibraryIssueListPage, "Library Issues");
