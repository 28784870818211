import {makeRequest} from '@utils/api';
import LocalStorageService from '@utils/storage';

const localStorageService = LocalStorageService.getService();


export const getUser = () => makeRequest("GET", "/user/me.json")

const claimCharterRequest = (userId,email_code) => makeRequest("POST", `/users/${userId}/claim_charter.json`,{
  email_code
});

export const emailCodeRequest = (code) => makeRequest("GET", `/email_code.json?code=${code}`);

export const login = (data) => new Promise(async (resolve,reject) => {
  try {
    const token = await makeRequest("POST", "/oauth/token.json",{
      grant_type: "password",
      ...data
    })
    localStorageService.setToken(token)
    resolve(await getUser())
  } catch (e) {
    reject(e)
  }
})

export const mapYourLibraryLogin = ({email_code,...data}) => new Promise(async (resolve,reject) => {
  try {
    const user = await login(data);

    await claimCharterRequest(user.id,email_code);

    resolve(user)
  } catch (e) {
    reject(e)
  }
})

export const refresh = (refresh_token) => makeRequest("POST", "/oauth/token.json", {
  refresh_token,
  grant_type: "refresh_token"
})

export const registrationRequest = (user) => makeRequest("POST", "/users.json",{
  user
})

export const mapYourLibraryRegistrationRequest = ({email_code,...data}) => new Promise(async (resolve,reject) => {
  try {
    await registrationRequest(data)
    const user = await mapYourLibraryLogin({
      email: data.email,
      password: data.password,
      email_code
    })

    resolve(user)
  } catch (e) {
    reject(e)
  }
})

export const acceptInvitation = (user) => makeRequest("PUT", "/users/invitation.json",{
  user
})

export const updatePassword = (user) => makeRequest("PUT", "/users/password.json",{
  user
})

export const forgotPassword = (user) => makeRequest("POST", "/users/password.json",{
  user
})

export const updateCurrentUser = (user) => makeRequest("PATCH","/user/me.json",{
  user
})
