import Table from "@components/Table";
import Photo from "@components/Photos/Item";
import Button from "@components/Button";
import { Link } from "react-router-dom";
import ModalMenu from "@components/ModalMenu";
import { IndicatorCharterNumberUpload } from "@components/Indicator/Upload";
import { uploadIndicatorChartNumber, clearCharters } from "@utils/api/indicator";
import RequestButton from "@containers/RequestButton";
import styles from "./index.module.css";

const IndicatorListPage = ({ indicators, text = "No indicators found" }) => (
  <>
    <Table
      cols={[
        "",
        "Name",
        "Assignable By",
        "Active",
        "Searchable",
        "Use Icon For Pin",
      ]}
      rows={indicators}
      renderRow={(indicator) => {
        return (
          <>
            <div className={styles.imageWrapper}>
              <Photo src={indicator.image_url} className={styles.Item_image} />
            </div>
            <div className={styles.padding}>{indicator.Name}</div>
            <div className={styles.padding}>{indicator.AssignableBy__c}</div>
            <div className={styles.padding}>
              {indicator.Active__c ? "Active" : "Not Active"}
            </div>
            <div className={styles.padding}>
              {indicator.Searchable__c ? "Yes" : "No"}
            </div>
            <div className={styles.padding}>
              {indicator.UseIconForPin__c ? "Yes" : "No"}
            </div>
            <div>
              <ModalMenu title={indicator.Name}>
                {setModal => {
                  return (<>
                    <Button as={Link} to={`${indicator.id}`}>
                      Update
                    </Button>
                    <RequestButton
                      button="button"
                      toast="Indicator successfully cleared"
                      makeRequest={() => clearCharters(indicator)}
                      onSuccess={() => setModal(false)}
                      to={"/"}
                      style={{ width: '100%' }}
                    >
                      Clear Charters
                    </RequestButton>
                    <IndicatorCharterNumberUpload
                      indicator={indicator}
                      makeRequest={uploadIndicatorChartNumber}
                      setModal={setModal}
                    />
                  </>)
                }}
              </ModalMenu>
            </div>
          </>
        );
      }}
      noLink
      placeholder={{ to: "new", text, hideButton: true }}
    />
  </>
);

export default IndicatorListPage;
