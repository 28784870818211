import { useState, useRef, useEffect } from 'react';
import { exportUsers } from '@utils/api/user';
import Button from '@components/Button';
import Loader from '@components/Loader';
import { format } from 'date-fns';

export default function ExportUsersButton({statusFilter, searchFilter, filterDates}) {
  const [fileLink, setFileLink] = useState(),
    [loading, setLoading] = useState(),
    linkRef = useRef(),
    handleClick = async () => {
      try {
        setLoading(true);
        setFileLink('');
        const query = {};
        const formatDate = (date) => format(date, 'yyyy-MM-dd');
        if(statusFilter) {
          query.active = statusFilter === "active";
        }
        if(searchFilter){
          query.search = searchFilter;
        }
        if (filterDates) {
          if (filterDates['dateFrom']) {
            query.created_at_from = formatDate(filterDates['dateFrom']);
          }
          if (filterDates['dateTill']) {
            query.created_at_to = formatDate(filterDates['dateTill']);
          }
        }
        const csvFile = await exportUsers(query);
        setFileLink('data:text/csv;charset=UTF-8,' + encodeURIComponent(csvFile));
      } catch (e) {

      } finally {
        setLoading(false);
      }

    };
  useEffect(() => {
    if (fileLink && linkRef?.current) {
      linkRef.current.click();
    }
  }, [fileLink]);
  return (
    <>
      <Button onClick={handleClick} small primary type="button" className="ml-2">
        Export Users
      </Button>
      <a href={fileLink} ref={linkRef} download="users.csv" className="hidden"></a>
      {loading && <Loader />}
    </>
  );
};
