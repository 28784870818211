import {useState,useRef,useCallback,forwardRef} from 'react';
import {classNames} from '@utils';
import useEventListener from '@containers/useEventListener';
import styles from './index.module.css';

const StickyHeader = forwardRef(({className,isFixed,fixedClassName,groundClassName,children,height,...props},ref) => (<>
  <div ref={ref} className={classNames(styles.ground,groundClassName)} style={{height}}>
    <div {...props} className={classNames(styles.container,isFixed && styles.fixed, isFixed && fixedClassName, className)} style={{height}}>
      {children}
    </div>
  </div>
</>));

export default function StickyHeaderContainer(props){
  const groundRef = useRef();
  const [isFixed,setIsFixed] = useState()
  const handleClick = () => {
    groundRef.current.scrollIntoView({behavior:"smooth"})
  }
  const handleScroll = useCallback((e) => {
    if(groundRef.current){
      const rect = groundRef.current?.getBoundingClientRect();
      if(rect?.top <= 0){
        setIsFixed(true)
      } else {
        setIsFixed(false)
      }
    }

  },[groundRef,isFixed,setIsFixed])
  useEventListener("scroll", handleScroll)
  return <StickyHeader ref={groundRef} isFixed={isFixed} {...props} onClick={handleClick} />
};
