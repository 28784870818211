import useQueryStringValidation from '@containers/useQueryStringValidation'

export default function withQueryStringValidation(WrappedComponent,schema,url){
  return (props) => {
    const f = useQueryStringValidation(schema,url)
    return (
      <WrappedComponent {...props} query={f.query} navigate={f.navigate} />
    )
  }
}
