import Table from '@components/Table';
import Photo from '@components/Photos/Item';
import styles from './index.module.css';

const CharterNumberListPage = ({ challenges, text = "No challenges found" }) => (
  <Table
    cols={["", "Name", "Challenge Type", "Amount", "Active"]}
    rows={challenges}
    renderRow={(challenge) => (<>
      <div className={styles.imageWrapper}>
        <Photo src={challenge.image_url} className={styles.image} />
      </div>
      <div>{challenge.name}</div>
      <div>{challenge.challenge_type === 'check_in' ? 'Check in' : 'Books left'}</div>
      <div>{challenge.amount}</div>
      <div>{challenge.active ? "Active" : "Inactive"}</div>
    </>)}
    placeholder={{ to: "new", text, hideButton: true }}
  />
)

export default CharterNumberListPage;
