import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Page from "@components/Layouts/Page";
import IndicatorList from "@components/Indicator/List";
import { Pagination } from "@components/Pagination";
import withPageTitle from "@containers/withPageTitle";
import usePaginationRequest from "@containers/usePaginationRequest";
import {SelectComponent} from '@components/Form/Fields/Select';
import Button from "@components/Button";
import styles from "./index.module.css";

const statusFilterOptions = [{value: "all", name: "All"},{value: "active", name: "Active"},{value: "inactive", name: "Inactive"},]

export const IndicatorListPageComponent = ({ request, isAdmin, ...props }) => { 
  const [statusFilter,setStatusFilter] = useState(statusFilterOptions[0].value);
  useEffect(()=>{
    const query = {};
    if(statusFilter !== 'all') query.active = statusFilter === "active" ? true : false 
    request.setQuery(query)
  },[statusFilter])
  return (
    <Page
    left={(
      <SelectComponent value={statusFilter} options={statusFilterOptions} onChange={(e) => {setStatusFilter(e.target.value)}} />
    )}
      right={
        <Button as={Link} to="new" type="button" medium primary>
          New Indicator
        </Button>
      }
      {...props}
    >
      <Pagination
        previousPage={request.previousPage}
        setPage={request.setPage}
        nextPage={request.nextPage}
        pageCount={request.state.data.page_count}
        page={request.state.data.page}
        status={request.state.status}
        error={request.state.error}
      >
        <IndicatorList
          indicators={request.state.data.app_flags}
          text="No indicators found"
        />
      </Pagination>
    </Page>
  );
};

const IndicatorListPageContainer = ({ url, ...props }) => {
  const request = usePaginationRequest(url, 25);
  return <IndicatorListPageComponent {...props} request={request} />;
};

const IndicatorListPage = () => (
  <IndicatorListPageContainer title="Indicators" url="/app_flags.json" />
);

export default withPageTitle(IndicatorListPage, "Indicators");
