import { Modal } from '@components/Modal';
import Button from '@components/Button';
import RequestButton from '@containers/RequestButton';
import LogoutButton from '@containers/LogoutButton';
import { useAuthDispatch, useAuthState, setUserAction } from '@containers/Auth';
import { updateUser } from '@utils/api/user';
import styles from './index.module.css';

export default function TOSModal() {
  const user = useAuthState();
  const authDispatch = useAuthDispatch();
  const handleAgreeRequestSuccess = (res) => authDispatch(setUserAction(res))
  return (user && !user.is_admin && !user.tos_accepted) ? (
    <Modal className={styles.modal}>
      <div className={styles.text}>
        To continue using Little Free Library, please agree to the Mobile App Privacy Policy
      </div>
      <div className={styles.buttons}>
        <RequestButton as="button" confirmationModal={false} makeRequest={() => updateUser({ tos_accepted: true }, `/users/${user.id}`)} onSuccess={handleAgreeRequestSuccess}>I Agree</RequestButton>
        <LogoutButton />
        <Button as="a" href="https://littlefreelibrary.org/privacy-policy" target="_blank">View Terms</Button>
      </div>
    </Modal>
  ) : null
}
