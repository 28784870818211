import Page from '@components/Layouts/Page';
import {withUser} from '@containers/Auth';
import withPageTitle from '@containers/withPageTitle';
import styles from './index.module.scss';

const StewardResourcesPage = withUser(({user}) => (
  <Page title="Steward Resources">
    <iframe 
      src="https://littlefreelibrary.org/stewards/steward-resources/" 
      title="Steward Resources Iframe" 
      width="100%" 
      height="100%" 
      style={{ border: 'none', display: 'flex',
      flexGrow: 1 }}
    />
  </Page>
))

export default withPageTitle(StewardResourcesPage, "Steward Resources");
