import SponsorFormPage from '@components/Sponsor/Form';
import Button from '@components/Button';
import Request from '@containers/Request';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {updateSponsor} from '@utils/api/sponsor';

const makeSponsorDefaultValues = ({image_url,...sponsor}) => ({
  ...sponsor,
  image: image_url || ""
})

const SponsorUpdatePage = withParams(({params:{id}}) => {
  const url = `/app_sponsors/${id}.json`;
  return(
    <Request url={url}>
      {(sponsor,makeRequest,updateData) => (
          <SponsorFormPage
            form={{
              url,
              onSubmit: updateSponsor,
              defaultValues: makeSponsorDefaultValues(sponsor),
              toast: "Sponsor updated"
            }}
            page={{
              title: sponsor.SponsorName__c,
              back: true
            }}
          />
      )}
    </Request>
  )
})

export default withPageTitle(SponsorUpdatePage, "Update Sponsor");
