import Post from '@components/Post';
import EmptyResourcePlaceholder from '@components/EmptyResourcePlaceholder';
import RequestButton from '@containers/RequestButton';
import {makeDateStr} from '@utils';
import {updateComment} from '@utils/api/comment';

const handleApprovalButtonClick = (Approval__c,id) => () => new Promise(
  async (resolve,reject) => {
    try {
      resolve(await updateComment({Approval__c},id))
    } catch (e) {
      reject(e)
    }
  }
)

const ApprovalButton = ({approval,id,toast = "Comment updated",children,...props}) => (
  <RequestButton primary xs {...props} toast={toast} makeRequest={handleApprovalButtonClick(approval,id)}>{children}</RequestButton>
)

const ApproveButton = (props) => (
  <ApprovalButton {...props} approval="Approved" modalText="Are you sure you want to approve this comment?" toast="Comment has been approved">Approve</ApprovalButton>
)

const DeclineButton = (props) => (
  <ApprovalButton {...props} secondary approval="Declined" modalText="Are you sure you want to decline this comment?" toast="Comment has been declined">Decline</ApprovalButton>
)

const ApprovalButtons = ({approval, id, onSuccess}) => {
  switch (approval) {
    case "Pending":
      return(<>
        <ApproveButton id={id} onSuccess={() => {
          onSuccess(id,"Approved")
        }} />
        <DeclineButton id={id} onSuccess={() => {
          onSuccess(id,"Declined")
        }} />
      </>)
    case "Approved":
      return(
        <DeclineButton id={id} onSuccess={() => {
          onSuccess(id,"Declined")
        }} />
      )
    case "Declined":
      return(
        <ApproveButton id={id} onSuccess={() => {
          onSuccess(id,"Approved")
        }} />
      )
    default:
      return null
  }
}

const CommentList = ({comments,onSuccess,placeholder = "No one has left a comment yet"}) => (
  comments?.length > 0 ? (
    comments.map(comment => (
      <Post
        key={comment.id}
        header={comment.User_Name__c}
        subheader={makeDateStr(comment.created_at)}
        buttons={<ApprovalButtons approval={comment.Approval__c} id={comment.id} onSuccess={onSuccess} />}
        text={comment.Comment__c}
      />
    ))
  ) : (
    <EmptyResourcePlaceholder text={placeholder} hideButton />
  )
)

export default CommentList;
