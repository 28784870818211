import Page from '@components/Layouts/Page';
import UserFormPage from '@components/User/Form';
import Button from '@components/Button';
import Request from '@containers/Request';
import Input from '@components/Form/Fields/Input';
import PhoneNumberInput from '@components/Form/Fields/PhoneNumberInput';
import AutocapitalizeInput from '@components/Form/Fields/AutocapitalizeInput';
import {PermissionsButton} from '@components/User/List';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {updateUser} from '@utils/api/user';

const UserUpdatePage = withParams(({params:{id}}) => {
  const url = `/users/${id}.json`;
  return(
    <Request url={url}>
      {(response,makeRequest,updateData) => (
        <Page
          title={`${response.first_name} ${response.last_name}`}
          back="../.."
          right={!response.is_admin && <PermissionsButton type="button" medium primary as={Button} user={response} onSuccess={r => {updateData(r.response)}} />}>
          <UserFormPage
            form={{
              url,
              onSubmit: updateUser,
              to: false,
              toast: "User updated"
            }}
            user={response}
          />
        </Page>

      )}
    </Request>
  )
})

export default withPageTitle(UserUpdatePage, "Update User");
