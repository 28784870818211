import {useState} from 'react';
import Page from '@components/Layouts/Page';
import LibraryList from '@components/Library/List';
import SearchFilter from '@components/SearchFilter';
import {Pagination} from '@components/Pagination';
import usePaginationRequest from '@containers/usePaginationRequest';
import withPageTitle from '@containers/withPageTitle';
import {withUser} from '@containers/Auth';
import useEffectAfterMount from '@containers/useEffectAfterMount';
import {classNames} from '@utils';
import styles from './index.module.css';

export const useLibraryFilters = (request) => {
  const [address,setAddress] = useState(),
        [charter,setCharter] = useState(),
        [name,setName] = useState();

  useEffectAfterMount(() => {
    const query = {};
    if(name){
      query.name = name
    }
    if(address){
      query.address = address;
    }
    if(charter){
      query.charter = charter;
    }
    request.setQuery(query)
  },[name,address,charter])

  return{
    setAddress,
    setCharter,
    setName
  }
}

export const LibraryFilters = ({setName,setAddress,setCharter,className}) => (
  <div className={classNames(styles.filters,className)}>
    <SearchFilter onChange={setName} placeholder="Search By Library Name..." />
    <SearchFilter className={styles.margin} onChange={setAddress} placeholder="Search By Address..." />
    <SearchFilter className={styles.margin} onChange={setCharter} placeholder="Search By Charter Number..." />
  </div>
)
export const LibraryListPageComponent = ({request,isAdmin,...props}) => {
  const filters = useLibraryFilters(request);
  return(
    <Page
      left={(<LibraryFilters {...filters} />)}
      {...props}
    >
      <Pagination previousPage={request.previousPage} setPage={request.setPage} nextPage={request.nextPage} pageCount={request.state.data.page_count} page={request.state.data.page} status={request.state.status} error={request.state.error}>
        <LibraryList libraries={request.state.data.libraries} isAdmin={isAdmin} text="There are no libraries matching this criteria" />
      </Pagination>
    </Page>
  )
}

const LibraryListPageContainer = ({url,...props}) => {
  const request = usePaginationRequest(url,25,props.isAdmin && {is_admin: true});
  return <LibraryListPageComponent {...props} request={request} />
}

const LibraryListPage = withUser(({user}) => (
  <LibraryListPageContainer
    title="Libraries"
    url="/libraries.json"
    isAdmin={user.is_admin}
  />
))

export default withPageTitle(LibraryListPage, "Libraries");
