import styles from './index.module.css';
import {classNames} from '@utils';

const Hamburger = ({isOpen,className,...props}) => (
  <button {...props} class={classNames(styles.container,isOpen && styles.active,className)} type="button">
    <span class={styles.box}>
      <span class={styles.inner}></span>
    </span>
  </button>
)

export default Hamburger;
