import {Fragment} from 'react';
import {useFieldArray} from 'react-hook-form';
import Button from '@components/Button';
import {Input} from '@components/Form/Fields/Input';
import {RequestLoader} from '@components/Loader';
import {useAppInfoState} from '@containers/AppInfo';
import {withUser} from '@containers/Auth';
import {classNames} from '@utils';
import styles from './index.module.css';

export const FlagField = ({className,isSelected,label,...props}) => (
  <Button type="button" action={!isSelected} secondary={!isSelected} medium className={classNames(styles.flagField,className,isSelected && styles.selected)} {...props}>
    <div className={styles.label}>{label}</div>
  </Button>
)

const makeFlags = (flags,isAdmin) => isAdmin ? flags : flags.filter(flag => flag.AssignableBy__c !== "Admin")

const FlagFields = withUser(({user,name,libraryId,...props}) => {
  const info = useAppInfoState(),
        { fields, append, remove } = useFieldArray({
          name
        });
  return(
    <div className={styles.container}>
      {fields.map((flag,i) => (
        <Fragment key={flag.id}>
          <Input defaultValue={flag.app_flag.id} name={`${name}[${i}].app_flag_id`} className="hidden" />
          <Input defaultValue={flag.id} name={`${name}[${i}].library_flag_id`} className="hidden" />
        </Fragment>
      ))}
      {info?.app_flags?.length > 0 ? (
        makeFlags(info.app_flags.filter(flag => flag.Active__c),user.is_admin).map((flag,i) => {
          const fieldIdx = fields.findIndex(f => f.app_flag.id === flag.id),
                isSelected = fieldIdx !== -1,
                handleClick = (newFlag) => {
                  if(isSelected){
                    remove(fieldIdx)
                  } else {
                    append({app_flag: flag})
                  }
                }

          return(
            <FlagField {...props} isSelected={isSelected} onClick={handleClick} label={flag.Name} key={flag.id} />
          )
        })
      ) : (
        <RequestLoader />
      )}
    </div>
  )
})

export default FlagFields;
