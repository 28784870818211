import FormPage from '@components/Form/Page';
import Input from '@components/Form/Fields/Input';
import Button from '@components/Button';
import StateSelect from '@containers/StateSelect';
import AutocapitalizeInput from '@components/Form/Fields/AutocapitalizeInput';
import ConfirmationResendButton, { CancelEmailConfirmationButton } from '@containers/ConfirmationResendButton';
import { userDetailsSchema } from '@utils/validation/auth';
import styles from './index.module.css';

const makeUserDefaultValues = ({ contact, first_name, last_name, email, unconfirmed_email }) => ({
  first_name,
  last_name,
  email,
  unconfirmed_email,
  contact_attributes: contact
})
export const UserFields = ({ isSteward, contactId, unconfirmed_email }) => (<>
  <div className={styles.subHeading}>This is your private user account information. To change the email address you use to log in, edit the email below.</div>
  <AutocapitalizeInput name="first_name" placeholder="Enter First Name" label="First Name" />
  <AutocapitalizeInput name="last_name" placeholder="Enter Last Name" label="Last Name" />
  <Input type="email" name="email" placeholder="Enter Email" label="Email" />
  {unconfirmed_email && <div className={styles.unconfirmedEmail}>
    <Input type="email" name="unconfirmed_email" placeholder="Enter Email" label="Unconfirmed Email" disabled />
    <ConfirmationResendButton email={unconfirmed_email} title="Resend Confirmation" />
    <CancelEmailConfirmationButton email={unconfirmed_email} />
  </div>}

  {isSteward && (<>
    <div className={styles.label}>Steward Profile</div>
    <div className={styles.subHeading}>This is your personal contact information. It will not display on the map unless you choose to share it in your library settings.</div>
    <AutocapitalizeInput name="contact_attributes[FirstName]" placeholder="Enter First Name" label="First Name" />
    <AutocapitalizeInput name="contact_attributes[LastName]" placeholder="Enter Last Name" label="Last Name" />
    <Input type="email" name="contact_attributes[Email]" placeholder="Enter Email" label="Email" />

    <div className={styles.label}>Mailing Address</div>
    <div className={styles.subHeading}>This is your personal mailing address, not your library’s address. It may be used by Little Free Library staff for steward communications. It is not displayed on the map or shared with outside parties.</div>
    {contactId && <Input className="hidden" name="contact_attributes[id]" />}
    <AutocapitalizeInput name="contact_attributes[MailingStreet]" label="Mailing Address" placeholder="Enter Mailing Address" />
    <AutocapitalizeInput name="contact_attributes[MailingCountry]" label="Country" placeholder="Enter Country" />
    <AutocapitalizeInput name="contact_attributes[MailingCity]" label="City" placeholder="Enter City" />
    <StateSelect name="contact_attributes[MailingState]" label="State/Province" placeholder="Select State/Province" />
    <Input name="contact_attributes[MailingPostalCode]" label="Postal Code" placeholder="Enter Postal Code" />
  </>)}
</>)
const UserFormPage = ({ form, page, user }) => (
  <FormPage
    form={{ validationSchema: userDetailsSchema, defaultValues: makeUserDefaultValues(user), ...form }}
    page={page}
    title="User Profile"
  >
    <UserFields isSteward={user.is_steward} contactId={user.contact?.id} unconfirmed_email={user.unconfirmed_email} />
    <Button type="submit">Save</Button>

  </FormPage>
);

export default UserFormPage;
