import { useState } from 'react';
import Modal from '@components/Modal';
import Button from '@components/Button';
import { useToastAddAction } from '@containers/ToastContext';
import { useLoaderDispatch } from "@containers/Loader";
import { useErrorModalDispatch } from "@containers/ErrorModal";

const ButtonComponent = ({ children, ...props }) => (
  <Button medium {...props} type="button">{children}</Button>
)

export default function RequestButton({ id, makeRequest, toast, confirmationModal = true, onSuccess = () => { }, modalText = "Are you sure?", children, button: WrapperComponent = ButtonComponent, ...props }) {
  const loaderDispatch = useLoaderDispatch(),
    errorDispatch = useErrorModalDispatch(),
    addToast = useToastAddAction(),
    [modal, setModal] = useState(),
    handleConfirmClick = async (e) => {
      e.preventDefault();
      loaderDispatch({ type: "START_LOADING" });
      try {
        const response = await makeRequest();
        loaderDispatch({ type: "STOP_LOADING" });
        setModal();
        if (toast) {
          addToast(toast)
        }
        onSuccess(response)
      } catch (e) {
        loaderDispatch({ type: "STOP_LOADING" });
        errorDispatch(e?.formError ? e?.formError : "An unexpected error has occurred")
        setModal()
      }
    },
    handleButtonClick = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (confirmationModal) {
        setModal(true);
      } else {
        handleConfirmClick(e)
      }
    }

  return (<>
    <WrapperComponent {...props} onClick={handleButtonClick}>{children}</WrapperComponent>
    {modal && <Modal text={modalText} onClick={handleConfirmClick} cancel={(e) => { setModal(); e.preventDefault(); }} />}
  </>);
};
