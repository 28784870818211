import {makeRequest} from '@utils/api';
import {getBase64} from '@utils';


const makeLibraryImage = async ({order,image}) => {
  return new Promise(async (resolve, reject) => {
    const obj = {order};
    if(typeof image === "string" || image === null){
      obj.image = image;
    } else if(image instanceof File) {
      obj.image = await getBase64(image)
    }
    resolve(obj)
  });
};
export const createLibraryImage = async (libraryId,library_image) => makeRequest("POST",`/libraries/${libraryId}/library_images.json`, {
 library_image: await makeLibraryImage(library_image)
})

export const updateLibraryImage = async (libraryId,libraryImgId,library_image) => makeRequest("PATCH",`/libraries/${libraryId}/library_images/${libraryImgId}.json`, {
 library_image: await makeLibraryImage(library_image)
})
