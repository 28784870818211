import Content from '@components/Layouts/Content';
import Form from '@components/Form';

const FormPage = ({form,children,...props}) => (
  <Form {...form}>
    <Content {...props}>
      {children}
    </Content>
  </Form>
)

export default FormPage;
