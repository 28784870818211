import {debounce} from '@utils';

const TextFilter = ({onChange,...props}) => {
  const handleChange = debounce((e) => {
    onChange(e.target.value)
  },1000)
  return(
    <input {...props} onChange={handleChange} />
  )
}

export default TextFilter;
