import { useState } from 'react';
import Table from '@components/Table';
import ModalMenu from '@components/ModalMenu';
import PermissionsModal from '@components/User/PermissionsModalForm';
import ConfirmationResendButton from '@containers/ConfirmationResendButton';
import RequestButton from '@containers/RequestButton';
import { makeRoleString } from '@utils';
import { updateUser } from '@utils/api/user';
import { format } from 'date-fns';

const handleBlockButtonClick = (id, is_active) => () => new Promise(
  async (resolve, reject) => {
    try {
      resolve(await updateUser({ is_active }, `/users/${id}.json`))
    } catch (e) {
      reject(e)
    }
  }
)

export const PermissionsButton = ({ as: WrapperComponent = "div", user, onSuccess, ...props }) => {
  const [isOpen, setIsOpen] = useState(),
    close = () => { setIsOpen() },
    handleSuccess = (response) => {
      onSuccess(response);
      close();
    }
  return (<>
    <WrapperComponent {...props} onClick={() => { setIsOpen(true) }}>Update Permissions</WrapperComponent>
    {isOpen && <PermissionsModal user={user} close={close} onSuccess={handleSuccess} />}
  </>)
}

const User = ({ user }) => {
  const [userState, setUserState] = useState(user),
    name = `${userState.first_name} ${userState.last_name}`;
  const createDateFromAPIRes = (date) => new Date(date.replace(/-/g, '\/').replace(/T.+/, ''));
  const formatDate = (date) => {
    const from_api = createDateFromAPIRes(date);
    return format(from_api, 'MM-dd-yyyy')
  };

  return (<>
    <div>{name}</div>
    <div>{makeRoleString(userState)}</div>
    <div>{userState.email}</div>
    <div>{userState.is_active ? "Yes" : "No"}</div>
    <div>{userState.email_confirmed ? "Yes" : "No"}</div>
    <div>{userState.tos_accepted ? "Yes" : "No"}</div>
    <div>{formatDate(userState.created_at)}</div>
    <div>
      <ModalMenu title={name}>
        {setModal => {
          const handleSuccess = (user) => {
            setUserState(user);
            setModal(false);
          }
          return userState.is_active ? (<>
            <RequestButton button="div" toast="User has been blocked" makeRequest={handleBlockButtonClick(user.id, false)} onSuccess={handleSuccess}>Block User</RequestButton>
            {!userState.email_confirmed && <ConfirmationResendButton email={user.id} button="div" />}
            <PermissionsButton user={userState} onSuccess={({ response }) => { handleSuccess(response) }} />
          </>) : (
            <RequestButton button="div" toast="User has been unblocked" makeRequest={handleBlockButtonClick(user.id, true)} onSuccess={handleSuccess}>Unblock User</RequestButton>
          )
        }}
      </ModalMenu>
    </div>
  </>)
}
const UsersListPage = ({ users, text = "There are no users" }) => (
  <Table
    cols={["Name", "Role", "Email", "Active", "Confirmed", "TOS Accepted", "Signed Up"]}
    rows={users}
    renderRow={(user) => <User key={user.id} user={user} />}
    noLink
  // placeholder={{to: "new", text,hideButton: true}}
  />
)

export default UsersListPage;
