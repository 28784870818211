import ErrorDisplay from '@components/ErrorDisplay';
import {RequestLoader} from '@components/Loader';

export default function RequestStatus({children,status,error}) {
  if(status === "loading"){
    return <RequestLoader />
  }
  if(status === "error"){
    return <ErrorDisplay error={error} />
  }
  return children;
};
