import {string, object,mixed} from 'yup';

export const userSchema = object().shape({
  company_id: mixed().required('Company is required'),
  //title: string().required('Title is required'),
  first_name: string().required('First name is required'),
  last_name: string().required('Last name is required'),
  email: string().email('Invalid email').required('Email is required')
  //mobile_phone: string().required('Mobile phone is required'),
  //office_phone: string().required('Office phone is required')
});

export const userPermissionsSchema = object().shape({
  
});
