import { Link } from 'react-router-dom';
import Button from '@components/Button';
import EmptyResourcePlaceholder from '@components/EmptyResourcePlaceholder';
import styles from './index.module.scss';
import {classNames} from '@utils';

export const Header = ({children}) => (
  <div className={styles.header}>
    {children}
  </div>
)

export const Row = ({to,children}) => {
  const WrapperComponent = to ? Link : "div"
  return(
    <WrapperComponent to={to} className={classNames(styles.row, to && "selectable")}>
      {children}
    </WrapperComponent>
  )
}

export const Table = ({children, add}) => (
  <div className={styles.wrapper}>
    <div className={styles.container}>
      {children}
    </div>
    {add && <Button as={Link} to="new" action className={styles.addBtn} />}
  </div>
)

const List = ({cols,rows,renderRow,to,add, noLink, placeholder = {to: "new", text: "There are none"}}) => rows?.length > 0 ? (
  <Table add={add}>
    <Header>
        {cols.map((c,i) => <div key={i}>{c}</div>)}
    </Header>
    {rows.map(r => (
      <Row to={noLink ? undefined : to ? typeof to === 'function' ? to(r) : to : `${r.id}`} key={r.id}>
        {renderRow(r)}
      </Row>
    ))}
  </Table>
) : (<EmptyResourcePlaceholder {...placeholder} />)

export default List;
