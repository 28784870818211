import {Link} from 'react-router-dom';
import Page from '@components/Layouts/Page';
import LibraryList from '@components/Library/List';
import {withUser} from '@containers/Auth';
import withPageTitle from '@containers/withPageTitle';
import styles from './index.module.scss';

const StewardLibraryListPage = withUser(({user}) => (
  <Page title="My Libraries">
    <div className={styles.title}>Welcome, Steward!</div>
    <div className={styles.subtitle}>Visit <Link to="../account">Account</Link> to edit personal contact information</div>
    <LibraryList libraries={user.my_libraries} />
  </Page>
))

export default withPageTitle(StewardLibraryListPage, "My Libraries");
