import {useState,useRef,useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import InfiniteLoader from 'react-virtualized/dist/commonjs/InfiniteLoader';
import Table from '@components/Virtualized/Table';
import {RequestLoader} from '@components/Loader';
import ErrorDisplay from '@components/ErrorDisplay';
import useAccessorFunction from '@containers/useAccessorFunction';
import usePaginationRequest from '@containers/usePaginationRequest';
import styles from './index.module.css';
import {classNames} from '@utils';

const useInfiniteLoaderProps = (
  rows,
  status,
  hasNextPage,
  nextPage
) => {
  const rowCount = hasNextPage ? rows.length + 1 : rows.length,
        isRowLoaded = ({index}) => !hasNextPage || index < rows.length

  return {
    infiniteLoaderProps: {
      rowCount,
      loadMoreRows: (hasNextPage && status !== "loading") ? nextPage : () => {},
      isRowLoaded
    },
    listProps: {
      rowCount,
      isRowLoaded
    }
  }
}

const InfiniteLoaderComponent = ({
  status,
  rows,
  page,
  pageCount,
  nextPage,
  ...props
}) => {
  const [totalRows,setTotalRows] = useState(rows),
        isMounted = useRef(false),
        {infiniteLoaderProps,listProps} = useInfiniteLoaderProps(totalRows,status,page < pageCount,nextPage);
  useEffect(() => {
    if(isMounted.current){
      setTotalRows(page === 1 ? (
        rows
      ) : (
        (prevRows) => ([...prevRows,...rows])
      ))
    } else {
      isMounted.current = true
    }
  },[rows,page])
  return(
    <InfiniteLoader {...infiniteLoaderProps}>
      {(list) => (
        <Table
          {...props}
          {...listProps}
          rows={totalRows}
          ref={list.registerChild}
          onRowsRendered={list.onRowsRendered}
        />
      )}
    </InfiniteLoader>
  )
}

export const InfiniteLoaderContainer = ({
  state,
  state: {
    data,
    status,
    error
  },
  listAccessor = ({items}) => items,
  setPage,
  query,
  ...props
}) => {
  const listAccessorFunc = useAccessorFunction(listAccessor);
  if(!listAccessorFunc(data) && status === "loading"){
    return <RequestLoader />
  }
  if(status === "error"){
    return <ErrorDisplay error={error} />
  }
  return (
    <InfiniteLoaderComponent
      {...props}
      page={data.page}
      pageCount={data.page_count}
      rows={listAccessorFunc(data)}
      status={status}
      nextPage={() => {setPage(data.page+1,query)}}
    />
  )
}
export default function InfiniteLoaderExport({
  pageSize = 25,
  url,
  ...props
}){
  const request = usePaginationRequest(url,pageSize);
  return(
    <InfiniteLoaderContainer
      {...props}
      {...request}
    />
  )
}
