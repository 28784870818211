import {string, object, ref} from 'yup';
import {mergeSchemas} from '@utils';

export const emailSchema = object().shape({
  email: string().email('Invalid email').required('Email is required')
})
const passwordSchema = object().shape({
  password: string().required('Password is required')
})
const confirmPassWord = object().shape({
  password_confirmation: string().required('Passwords must match').oneOf([ref('password'), null], 'Passwords must match')
})
const userSchema = object().shape({
  first_name: string().required('First name is required'),
  last_name: string().required('Last name is required')
})
const charterNumberSchema  = object().shape({
  email_code: string().required('Email code is required')
})

export const emailCodeSchema  = object().shape({
  code: string().required('Email code is required')
})

export const loginSchema = mergeSchemas(
  emailSchema,
  passwordSchema
)

export const userDetailsSchema = mergeSchemas(
  userSchema,
  emailSchema
)

export const confirmPasswordSchema = mergeSchemas(
  passwordSchema,
  confirmPassWord
)

export const registrationSchema = mergeSchemas(
  userDetailsSchema,
  confirmPasswordSchema
)


export const mapYourLibraryLoginSchema = mergeSchemas(
  loginSchema,
  charterNumberSchema
)

export const mapYourLibraryRegistrationSchema = mergeSchemas(
  emailSchema,
  passwordSchema,
  charterNumberSchema
)

export const invitePageValidation = object().shape({
  invitation_token: string().required('Invitation token is required')
})

export const confirmPageValidation = object().shape({
  confirmation_token: string().required('Confirmation token is required')
})

export const updatePasswordPageValidation = object().shape({
  reset_password_token: string().required('Password token is required')
})
