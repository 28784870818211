import {makeRequest, deleteRequest} from '@utils/api';

export const createIssue = (library_issue, url) => makeRequest("POST",url,{
  library_issue
})

export const deleteLibraryIssue = (issueId) => deleteRequest(`/library_issues/${issueId}.json`)

export const updateIssue = (library_issue, id) => makeRequest("PATCH",`/library_issues/${id}.json`,{
  library_issue
})
