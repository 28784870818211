import RequestButton from '@containers/RequestButton';
import {deleteRequest} from '@utils/api';

const handleDeleteButtonClick = (url) => () => new Promise(
  async (resolve,reject) => {
    try {
      resolve(await deleteRequest(url))
    } catch (e) {
      reject(e)
    }
  }
)

const DeleteButton = ({url,children,toast = "Item has been deleted",...props}) => (
  <RequestButton {...props} toast={toast} makeRequest={handleDeleteButtonClick(url)}>{children || "Delete"}</RequestButton>
)

export default DeleteButton;
