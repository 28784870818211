import { makeRequest } from '@utils/api';

export const createAnnouncement = (announcement) => {
  const tempData = { ...announcement }
  if (announcement.app_flag_ids && announcement.app_flag_ids.length) {
    tempData.app_flag_ids = announcement.app_flag_ids.map(item => item.value)
  }
  return makeRequest("POST", "/announcements.json", {
    announcement: tempData
  })
}

export const updateAnnouncement = (announcement, url) => {
  const tempData = { ...announcement }
  if (announcement.app_flag_ids && announcement.app_flag_ids.length) {
    tempData.app_flag_ids = announcement.app_flag_ids.map(item => item.value)
  }
  return makeRequest("PATCH", url, {
    announcement: tempData
  })
}
