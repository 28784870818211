import Table from '@components/Table';
import {getFormattedDate} from '@utils';
import RequestButton from '@containers/RequestButton';
import {sendStewardInvitation} from '@utils/api/charter';
import styles from './index.module.css';

const handleInviteButtonClick = (id) => () => new Promise(
  async (resolve,reject) => {
    try {
      resolve(await sendStewardInvitation(id))
    } catch (e) {
      reject(e)
    }
  }
)

const CharterInviteButton = ({id,reinvite,toast = "Steward invite has been sent",...props}) => (
  <RequestButton form {...props} toast={toast} makeRequest={handleInviteButtonClick(id)}>{reinvite ? "Re-Invite" : "Invite"}</RequestButton>
)

const CharterNumberListPage = ({charterNumbers, text = "No charter numbers found"}) => (
  <Table
    cols={["Charter Number","Steward Name","Steward Email","Last Invite Sent",""]}
    rows={charterNumbers}
    renderRow={(charterNumber) => (<>
      <div className={styles.padding}>{charterNumber.Official_Charter_Number__c}</div>
      <div>{`${charterNumber.first_name} ${charterNumber.last_name}`}</div>
      <div>{charterNumber.email_address}</div>
      <div>{charterNumber.last_invite_sent && getFormattedDate(charterNumber.last_invite_sent)}</div>
      <div>{charterNumber.can_invite && <CharterInviteButton id={charterNumber.id} reinvite={charterNumber.last_invite_sent} />}</div>
  </>)}
    noLink
    placeholder={{to: "new", text,hideButton: true}}
  />
)

export default CharterNumberListPage;
