import {forwardRef} from 'react';
import {useFormContext,Controller} from 'react-hook-form';
import withField from '@components/Form/Fields/withField';

const InputComponent = forwardRef((props,ref) => <input {...props} ref={ref} />)
export const Input = ({as: Component = InputComponent,...props}) => {
  const { register } = useFormContext();
  return <Component {...props} {...register(props.name)} />
};

export const ControlledInputComponent = ({as: PassedInputComponent = InputComponent,name,...props}) => (
  <Controller
    name={name}
    render={({ field }) => (
      <PassedInputComponent
        {...field}
        {...props}
      />
    )}
  />
)
export const ControlledInput = withField(ControlledInputComponent)

const InputField = withField(Input)

export default InputField;
