import { useState, useEffect, useCallback, useRef } from 'react';
import ReactSlider from 'react-slider';
import useOnClickOutside from '@containers/useOnClickOutside';
import useEventListener from '@containers/useEventListener';
import { usePanel, InfoSection, PanelContainer, PanelHeading, InfoModal } from '../FilterPanel';
import { classNames } from '@utils';
import styles from './index.module.scss';
import nearMeIcon from '@assets/images/near_me.svg';

const makeInputPlaceholder = (type) => {
  switch (type) {
    case "near":
      return "Enter a city, state/province, or postal code"
    case "charter":
      return "Enter a library charter number"
    case "name":
      return "Enter a name"
    default:
      return ""
  }
}

const sliderLabelMap = {
  0: "Location",
  1: "Charter",
  2: "Near Me",
  3: "Name"
}

const sliderFilterMap = {
  0: "near",
  1: "charter",
  2: "nearme",
  3: "name"
}

const searchTypeLabelMap = {
  [sliderFilterMap[0]]: sliderLabelMap[0],
  [sliderFilterMap[1]]: sliderLabelMap[1],
  [sliderFilterMap[2]]: sliderLabelMap[2],
  [sliderFilterMap[3]]: sliderLabelMap[3]
}
const sliderValueMap = {
  [sliderFilterMap[0]]: 0,
  [sliderFilterMap[1]]: 1,
  [sliderFilterMap[2]]: 2,
  [sliderFilterMap[3]]: 3,
}

const SearchTypePanel = ({openModal,close,value = "near",onChange}) => {
  const handleSliderChange = (v) => onChange(sliderFilterMap[v])
  const sliderValue = sliderValueMap[value]
  return(
    <PanelContainer className={styles.container}>
      <PanelHeading title="Search By" className={styles.header} onClickInfoButton={openModal} />
      <ReactSlider
        min={0}
        max={3}
        onChange={handleSliderChange}
        value={sliderValue}
        marks
        className={styles.slider}
        thumbClassName={styles.thumb}
        trackClassName={styles.track}
        markClassName={styles.mark}
        renderMark={({className,...props}) => <div className={classNames(className,props.key === sliderValue && styles.white)} {...props}>{sliderLabelMap[props.key]}</div>}
      />
    </PanelContainer>
  )
}

export default function TextFilter({
  onSubmit,
  isMobile,
  searchType,
  searchString,
  handleSearchTypeChange,
  handleSearchStringChange,
  clearDisplay,
  displayLabel,
  isSmall,
  isLoading
}){
  const [modal,setModal] = useState(false);
  const { isOpen, open, close, ref } = usePanel(modal);
  const closeModal = () => setModal(false)
  const openModal = () => setModal(true)
  const inputRef = useRef();
  const handleSubmit = () => {
    onSubmit();
    close();
  }
  useEffect(() => {
    if(searchType === "nearme"){
      close();
    } else if(inputRef.current && (searchType === "near" || searchType === "charter")){
      inputRef.current.focus()
    }
  },[searchType])

  useEventListener(isSmall ? null : "keyup", (e) => {
    if(13 === e.which || 13 === e.keyCode){
      handleSubmit()
    }
  });
  return(
    <div className={classNames(styles.searchWrapper,displayLabel ? styles.mapLabel : isSmall && styles.nearMeLabel)} onClick={clearDisplay}>
      <div className={styles.selectContainer} ref={ref}>
        <div onClick={(isSmall || isOpen) ? close : open} className={styles.selectWrapper} >
          <div >{displayLabel || searchTypeLabelMap[searchType]}</div>
        </div>
        {isOpen && (
          <SearchTypePanel onChange={handleSearchTypeChange} value={searchType} openModal={openModal} close={close} />
        )}
        {modal && (
          <InfoModal close={closeModal}>
            <InfoSection title="Location">
              Choose <b>Location</b> to search by any city, state/province postal code or country.
            </InfoSection>
            <InfoSection title="Charter">
              Choose <b>Charter</b> to search for a specific library by its unique charter number.
            </InfoSection>
            <InfoSection title="Near Me">
              Choose <b>Near Me</b> to search based on your current location (you must allow your browser to use your location for this search to work).
            </InfoSection>
          </InfoModal>
        )}
      </div>

      <div className={styles.inputWrapper}>
        {!isSmall && (
          <input name="search" autoFocus placeholder={makeInputPlaceholder(searchType)} value={searchString}  onChange={handleSearchStringChange} className={styles.input} ref={inputRef} />
        )}
        <button type="button" onClick={!isSmall ? handleSubmit : undefined} className={classNames(styles.searchBtn, !isSmall && !isMobile && styles.active)}>
          <div className={styles.searchIcon}>&#9906;</div>
          {!isSmall && !isMobile && (
            <span className={styles.searchText}>Search</span>
          )}
        </button>
      </div>

    </div>
  )
}
