import IndicatorFormPage from '@components/Indicator/Form';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {createIndicator} from '@utils/api/indicator';

const IndicatorCreatePage = withParams(({params:{id}}) => (
  <IndicatorFormPage
    form={{
      url: "/app_flags.json",
      onSubmit: createIndicator
    }}
    page={{
      title: "Create Indicator",
      back: true,
      submit: "Create New Indicator"
    }}
  />
))

export default withPageTitle(IndicatorCreatePage, "Create Indicator");
