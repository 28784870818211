import {useRef,useEffect,useCallback} from 'react';

export default function useEffectAfterMount(handler,dependencies){
  const isMounted = useRef(),
        actionHandler = useCallback(handler,dependencies);
  useEffect(() => {
    if(isMounted.current){
      actionHandler()
    } else {
      isMounted.current = true
    }
  },[actionHandler])
}
