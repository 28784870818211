import {forwardRef} from 'react';
import {classNames} from '@utils';
import {useFormContext,Controller} from 'react-hook-form';
import withField from '@components/Form/Fields/withField';
import styles from './index.module.scss';

const Checkbox = forwardRef(({onChange,...props},ref) => <input {...props} checked={props.value} onChange={e => {onChange(e.target.checked)}} type="checkbox" ref={ref}  />)

export const ControlledCheckbox = ({as: Component = "input",...props}) => {
  const { register } = useFormContext();
  return <input {...props} type="checkbox" {...register(props.name)} />
};
export const Switch = ({className, as: WrappedComponent = Checkbox,...props}) => (
  <div className={classNames(styles.container,className)}>
    <WrappedComponent {...props} />
    <span className={styles.slider} />
  </div>
);

export const ControlledSwitch = withField((props) => <Controller {...props} render={({onChange,...f}) => <Switch {...f} checked={f.value} onChange={(e) => {onChange(e.target.checked ? true : false)}} as="input" type="checkbox" />} />,undefined,undefined,true)

const RegisteredSwitch = withField((props) => <Switch {...props} checked={props.value} as={ControlledCheckbox} />,undefined,undefined,true)

export default RegisteredSwitch;
