import AnnouncementFormPage from '@components/Announcement/Form';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {createAnnouncement} from '@utils/api/announcement';

const AnnouncementCreatePage = withParams(({params:{id}}) => (
  <AnnouncementFormPage
    form={{
      url: "/announcements.json",
      onSubmit: createAnnouncement
    }}
    page={{
      title: "Create Announcement",
      back: true,
      submit: "Create New Announcement"
    }}
  />
))

export default withPageTitle(AnnouncementCreatePage, "Create Announcement");
