import {forwardRef} from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {CropperInput,useAvatarCropper,useDropzoneHandlers} from '@components/Form/Fields/AvatarCrop';
import EmptyResourcePlaceholder from '@components/EmptyResourcePlaceholder';
import Photo from '@components/Photos/Item';
import Button from '@components/Button';
import Request from '@containers/Request';
import DeleteButton from '@containers/DeleteButton';
import withParams from '@containers/withParams';
import {useLoaderDispatch,startLoading,stopLoading} from "@containers/Loader";
import withPageTitle from '@containers/withPageTitle';
import withIsMobile from '@containers/withIsMobile';
import {useToastAddAction} from '@containers/ToastContext';
import {updateLibraryImage,createLibraryImage} from '@utils/api/library_image';
import {classNames} from '@utils';
import styles from './index.module.css';
import {ReactComponent as TrashIcon} from '@assets/images/delete_black_24dp.svg';

const move = (arr, from, to) => {
  const clone = [...arr];
  Array.prototype.splice.call(clone, to, 0,
    Array.prototype.splice.call(clone, from, 1)[0]
  );
  return clone;
}

const AddLibraryImageDropzone = ({order = 1,onSuccess,activeClassName,className,children,libraryId}) => {
  const loaderDispatch = useLoaderDispatch(),
        addToast = useToastAddAction(),
        handleError = () => {
          addToast("Unable to add your photo");
        },
        {file,setFile,image,setImage,isCropperOpen,close} = useAvatarCropper(handleError),
        { getRootProps, getInputProps, isDragActive } = useDropzoneHandlers(setFile),
        handleCrop = async (croppedImage) => {
          try {
            loaderDispatch(startLoading());
            const img = await createLibraryImage(libraryId,{order, image: croppedImage});
            onSuccess(img);
            close();
            addToast("Your photo has been added");
            setImage();
            setFile()
          } catch (e) {
            handleError()
          } finally {
            loaderDispatch(stopLoading());
          }

        };
  return(<>
    <div className={classNames(className, isDragActive && activeClassName)} {...getRootProps()}>
      {children}
    </div>
    <CropperInput
      name="image"
      image={image}
      handleCrop={handleCrop}
      close={close}
      isCropperOpen={isCropperOpen}
      inputProps={getInputProps()} />
  </>)
}

const LibraryPhoto = forwardRef(({idx,image,libraryId,deleteImage,...props},ref) => {
  const onDeleteSuccess = () => {
    deleteImage(idx)
  }
  return(
    <div ref={ref} {...props} className={styles.image}>
      <div className={styles.banner}>
        <DeleteButton url={`/libraries/${libraryId}/library_images/${image.id}`} button="div" className={`${styles.circle} ${styles.deleteWrapper}`} onSuccess={onDeleteSuccess} toast="Photo has been deleted" modalText="Are you sure you want to delete this photo?">
          <TrashIcon fill="#fff" />
        </DeleteButton>
        <div className={styles.circle}>{idx+1}</div>
      </div>
      <Photo src={image.image_url} />
    </div>
  )
})

const ImageList = withIsMobile(({images,updateData,libraryId,isMobile}) => {
  const addToast = useToastAddAction(),
        handleDrag = async ({ source, destination }) => {
          if (destination && source.index !== destination.index) {
            const updatedImageArray = move(images,source.index, destination.index)
            try {
              updateData(updatedImageArray)
              await updateLibraryImage(libraryId,images[source.index].id,{order: destination.index + 1})
              addToast("Photo order updated")
            } catch (e) {
              updateData([...images])
              addToast("Unable to update photo order")
            }
          }
        },
        deleteImage = (i) => updateData([...images.slice(0, i), ...images.slice(i + 1)]),
        addImage = (img) => updateData([...images,img])
  return(
    <div className={styles.listWrapper}>
      <DragDropContext onDragEnd={handleDrag}>
        <Droppable droppableId="droppable" direction={isMobile ? "vertical" : "horizontal"}>
          {(provided) => (
            <div className={styles.list} {...provided.droppableProps} ref={provided.innerRef}>
              {images.map((image, i) => (
                <Draggable
                  key={image.id}
                  draggableId={image.id+""}
                  index={i}
                >
                  {(provided, snapshot) => (
                    <LibraryPhoto idx={i} image={image} libraryId={libraryId} deleteImage={deleteImage} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <AddLibraryImageDropzone order={images.length + 1} onSuccess={addImage} libraryId={libraryId} className={classNames(isMobile ? styles.mobileDropzone : styles.dropzone,images.length !== 4 && styles.marginLeft)} activeClassName={styles.active}>
        {isMobile ? (
          <Button medium>Add Image</Button>
        ) : (
          <EmptyResourcePlaceholder buttonText="Click Here To Add Photo" form={false} medium />
        )}
      </AddLibraryImageDropzone>
    </div>
  )
},1024)

const LibraryImagesPage = withParams(({params:{libraryId}}) => (
  <Request url={`/libraries/${libraryId}/library_images.json`}>
    {(images,makeRequest,updateData) => images?.length > 0 ? (<>
      <ImageList images={images} updateData={updateData} libraryId={libraryId} />
    </>) : (
      <AddLibraryImageDropzone onSuccess={(img) => {updateData([img])}} libraryId={libraryId} className={styles.container} activeClassName={styles.containerDragging}>
        <EmptyResourcePlaceholder buttonText="Click Here To Add Photo" form={false} medium />
      </AddLibraryImageDropzone>
    )}
  </Request>
));

export default withPageTitle(LibraryImagesPage, "Library Images");
