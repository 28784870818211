import {useState} from 'react';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import styles from './index.module.css';


const DateFilter = ({dateFilter, onChange}) => (

  <div className={styles.headerItems}>
    <div className={styles.dateWidth}>
      <DatePicker
         selected={dateFilter}
         dateFormat="MM/dd/yyyy"
         onChange={onChange}
       />
     </div>
   </div>
)

export default DateFilter;