import {classNames} from '@utils';
import styles from './index.module.scss';
import addIcon from '@assets/images/add-24px.svg';

const Button = ({className, secondary, unclickable, form, small, xs, medium, red, add, action, disabled, isProcessing, children, onClick, light, center, as: WrapperComponent = "button",...props}) => (
  <WrapperComponent
    {...props}
    onClick={(disabled || isProcessing) ? undefined : onClick}
    className={classNames(styles.container,
      !unclickable && "selectable",
      form && styles.form,
      secondary && styles.secondary,
      disabled && styles.disabled,
      red && styles.red,
      small && styles.small,
      medium && styles.medium,
      center && styles.center,
      action && styles.action,
      add && styles.add,
      light && styles.light,
      xs && styles.xs,
      unclickable && styles.unclickable,
      className
    )}
  >
    {isProcessing ? (
      <div>Loading...</div>
    ) : (<>
      <div className={styles.text}>{children}</div>
      {action && <img src={addIcon} className={styles.addIcon} />}
    </>)}
  </WrapperComponent>
)

export default Button;
