import logo from '@assets/images/LFL_RGB_HorizonalTaglineLogo_Gray@3x.png';
import styles from './index.module.css';

const Shell = () => (
  <div className={styles.container}>
    <img alt="Little Free Library logo" src={logo} className={styles.logo} />
  </div>
)

export default Shell;
