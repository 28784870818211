import GuestBook from '@components/Comment/List';
import StickyHeader from '@components/StickyHeader';
import styles from './index.module.scss';

const CommentSection = ({title,...props}) => (
  <div className={styles.container}>
    <StickyHeader className={styles.header} groundClassName={styles.margin} height="50px">
      <div className={styles.title}>{title}</div>
    </StickyHeader>
    <GuestBook {...props} />
  </div>
)


export default CommentSection;
