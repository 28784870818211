import { useState, useRef } from 'react';
import ReactSlider from 'react-slider';
import { RequestLoader } from '@components/Loader';
import Button from '@components/Button';
import { Modal } from '@components/Modal';
import useOnClickOutside from '@containers/useOnClickOutside';
import { useAppInfoState } from '@containers/AppInfo';
import { classNames } from '@utils';
import styles from './index.module.scss';
import tuneIcon from '@assets/images/tune.svg';

const IndicatorButton = ({ className, isSelected, label, ...props }) => (
  <Button type="button" action={!isSelected} secondary={!isSelected} medium className={styles.flagField} {...props}>
    <div className={styles.label}>{label}</div>
  </Button>
)

export const Indicators = ({
  addIndicator,
  removeIndicator,
  selectedIndicators
}) => {
  const info = useAppInfoState();
  return info?.app_flags?.length > 0 ? (
    info.app_flags.filter(flag => flag.Active__c).map(fl => {
      const isSelected = selectedIndicators.find(ind => Number(ind) === fl.id)
      return (
        <IndicatorButton key={fl.id} isSelected={isSelected} onClick={isSelected ? () => removeIndicator(fl.id) : () => addIndicator(fl.id)} label={fl.Name} />
      )
    })
  ) : (
    <RequestLoader />
  )
}

const mapDistanceToSlider = (distance) => distance > 4 ? (distance / 5) + 1 : (distance - 1) / 2

const mapSliderToDistance = (slider) => slider > 2 ? (slider - 1) * 5 : (slider * 2) + 1

export const PanelContainer = ({ children, className, left, ...props }) => (
  <div {...props} className={classNames(styles.container, left && styles.left, className)}>
    {children}
  </div>
)

export const PanelHeading = ({ onClickInfoButton, title, className, ...props }) => (
  <div className={classNames(styles.header, className)}>
    <div className={styles.title}>
      {title}
    </div>
    <div className={styles.infoButton} onClick={onClickInfoButton}>i</div>
  </div>
)

function IndicatorPanel({
  defaultIndicators = [],
  defaultDistance = 50,
  close,
  onApply,
  setModal,
  isSmall
}) {
  const [indicators, setIndicators] = useState(defaultIndicators)
  const [distance, setDistance] = useState(mapDistanceToSlider(defaultDistance))
  const removeIndicator = (ind) => {
    setIndicators(prevInd => prevInd.filter(fl => fl !== ind))
  }
  const addIndicator = (ind) => {
    setIndicators(prevInd => [...prevInd, ind])
  }
  const handleDistanceSliderChange = (v) => setDistance(v)
  const handleApplyClick = () => {
    close();
    onApply({
      app_flag_ids: indicators,
      distance: mapSliderToDistance(distance)
    });

  }

  return (
    <PanelContainer left={isSmall} className={styles.indicatorPanel}>
      <div className={styles.indicatorPanelContent}>
        <PanelHeading title="Set Distance Range 1 - 50 Miles" onClickInfoButton={() => setModal("distance")} />
        <ReactSlider
          min={0}
          max={11}
          onChange={handleDistanceSliderChange}
          value={distance}
          className={styles.slider}
          thumbClassName={styles.thumb}
          trackClassName={styles.track}
          renderMark={props => <div {...props} />}
          renderThumb={(props, state) => <div {...props}>{mapSliderToDistance(state.valueNow)}</div>}
        />
        <PanelHeading title="Choose Indicators" onClickInfoButton={() => setModal("indicators")} />
      </div>
      <div className={styles.flags}>
        <Indicators selectedIndicators={indicators} addIndicator={addIndicator} removeIndicator={removeIndicator} />
      </div>
      <div className={styles.actions}>
        <div onClick={close} className={styles.cancel}>Cancel</div>
        <div onClick={handleApplyClick} className={styles.apply}>Apply</div>
      </div>
    </PanelContainer>
  )
}

export const InfoSection = ({ title, children }) => (
  <div className={styles.modalSection}>
    <div className={styles.modalTitle}>{title}</div>
    <div className={styles.modalInfo}>
      {children}
    </div>
  </div>
)

export const InfoModal = ({ close, children, className }) => (
  <Modal close={close} closeOnOverlayClick className={classNames(styles.modal, className)}>
    {children}
  </Modal>
)

export const usePanel = (blockClickOutside) => {
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)
  useOnClickOutside(ref, blockClickOutside ? undefined : close)
  return {
    ref,
    isOpen,
    close,
    open
  }
}

export const PanelButton = ({ className, src, ...props }) => (
  <div {...props} className={classNames(styles.panelButton, className)}>
    <img src={src} />
  </div>
)

export default function IndicatorPanelButton({
  defaultIndicators,
  defaultDistance,
  addIndicator,
  removeIndicator,
  onApply,
  isSmall
}) {
  const [modal, setModal] = useState(false);
  const closeModal = () => setModal(false);
  const panel = usePanel(modal);
  return (
    <div className={styles.indicatorButtonWrapper} ref={panel.ref}>
      <PanelButton src={tuneIcon} title="Search Filters" onClick={panel.isOpen ? panel.close : panel.open} />

      {panel.isOpen && (
        <IndicatorPanel isSmall={isSmall} onApply={onApply} setModal={setModal} defaultDistance={defaultDistance} defaultIndicators={defaultIndicators} addIndicator={addIndicator} removeIndicator={removeIndicator} close={panel.close} />
      )}
      {modal === "distance" && (
        <InfoModal close={closeModal}>
          <InfoSection title="Distance Range">
            Use the distance filters to control how many miles out from your search location the map will show results.
          </InfoSection>
        </InfoModal>
      )}
      {modal === "indicators" && (
        <InfoModal close={closeModal}>
          <InfoSection title="Need Books">
            This indicator shows Little Free Libraries that are in need of books, as reported by the library's steward.
          </InfoSection>
          <InfoSection title="New Books Added">
            This indicator shows Little Free Libraries that have been stocked with new books in the past 72 hours.
          </InfoSection>
          <InfoSection title="Sharing Box">
            This indicator shows Little Free Libraries that offer additional items like food, household items, or school supplies.
          </InfoSection>
          <InfoSection title="Temporarily Closed">
            This indicator shows Little Free Libraries that are temporarily unavailable to patrons, as reported by the library's steward.
          </InfoSection>
          <InfoSection title="Mobile Library">
            This indicator shows Little Free Libraries that do not have a permanent location.
          </InfoSection>
          <InfoSection title="Read In Color">
            This indicator shows Little Free Libraries whose stewards are participating in LFL's Read in Color program and have pledged to stock their libraries with diverse books.
          </InfoSection>
          <InfoSection title="Impact Library">
            This indicator shows Little Free Libraries that were granted through LFL's Impact Library Program.
          </InfoSection>
        </InfoModal>
      )}
    </div>

  )

}
