import {useCallback,useEffect} from 'react';
import {Loader} from '@components/Loader';
import {PublicHeader} from '@components/Layouts/Public';
import withQueryStringValidation from '@containers/withQueryStringValidation';
import {emailCodeSchema} from '@utils/validation/auth';
import {emailCodeRequest} from '@utils/api/auth';

const EmailCodePage = ({
  query:{code},
  navigate
}) => {
  const makeRequest = useCallback(async () => {
          try {
            const {code_type} = await emailCodeRequest(code);
            if(code_type === "new_charter"){
              navigate(`/mapyourlibrary/${code}`);
            } else if(code_type === "transfer"){
              navigate(`/transferyourlibrary?code=${code}`);
            } else {
              navigate('/')
            }
          } catch (e) {

          }
        },[])

  useEffect(() => {
    makeRequest()
  },[])
  return(<>
    <PublicHeader
      text="Redirecting"
    />
    <Loader white />
  </>)
}

export default withQueryStringValidation(EmailCodePage, emailCodeSchema, "/login");
