import {makeRequest,deleteRequest} from '@utils/api';

export const createLibraryTransferEmail = (libraryId,libraryTransferId) => makeRequest("POST",`/libraries/${libraryId}/library_transfers/${libraryTransferId}/resend.json`,{})

export const updateLibraryTransfer = (library_transfer, url) => makeRequest("PATCH",url,{
  library_transfer
})

export const cancelLibraryTransfer = (libraryId,libraryTransferId) => deleteRequest(`/libraries/${libraryId}/library_transfers/${libraryTransferId}.json`)

export const createLibraryTransfer = (library_transfer, url) => makeRequest("POST",url,{
  library_transfer
})
