import {classNames} from '@utils';
import Label from '@components/Form/Label';
import Error from '@components/Form/Fields/Error';
import styles from './index.module.scss';

const withField = (WrappedComponent, WrapperComponent = "label",wrapClass,row) => ({label,sublabel, className,onClick,secondary,round, asterik,...props}) => (
  <WrapperComponent className={classNames(styles.container,className,wrapClass, props.as === "textarea" && styles.textarea, row && styles.row,secondary && styles.secondary,round && styles.round)} onClick={onClick}>
    {label && (
      <Label secondary={secondary}>
        <div>{label}{(props.required || asterik) && <span className={styles.required}>*</span>}</div>
        {sublabel && <div className={styles.sublabel}><i>({sublabel})</i></div>}
    </Label>
    )}
    <div className={styles.inputWrapper}>
      <WrappedComponent {...props} />
      <Error name={props.name} className={styles.error} />

    </div>
  </WrapperComponent>
);

export default withField;
