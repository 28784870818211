import { useState } from 'react';
import Page from '@components/Layouts/Page';
import UserFormPage from '@components/User/Form';
import { withUser, useAuthDispatch, setUserAction } from '@containers/Auth';
import { updateCurrentUser } from '@utils/api/auth';
import Modal from '@components/Modal';

const AccountPage = withUser(({ user }) => {
  const authDispatch = useAuthDispatch();
  const [modal, setModal] = useState(false);
  return (
    <Page
      title="My Account"
    >
      <UserFormPage
        form={{
          url: 'user/me.json',
          onSubmit: updateCurrentUser,
          to: false,
          toast: "Your account has been updated",
          onSuccess: ({ response }) => {
            authDispatch(setUserAction(response))
            setModal(response.just_changed_email);
          }
        }}
        user={user}
      />
      {modal && <Modal text={"We have sent a confirmation email to your updated email address."} onClick={() => setModal(false)} />}
    </Page>
  )
})

export default AccountPage;
