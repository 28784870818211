import SortIcon from '@components/Sort/Icon';
import styles from './index.module.css';

const SortCol = ({text,sort,sortKey,setSort}) => {
  const isSort = sort?.includes(sortKey),
        isDesc = isSort ? sort.includes("-desc") : false,
        handleClick = () => {
          if(isSort && !isDesc){
            const keys = sortKey.split(",");
            const mapped_keys = keys.map(x => x.trim() + "-desc");
            setSort(mapped_keys.join(","));
          } else {
            setSort(sortKey)
          }
        }
  return(
    <div className={styles.container} onClick={handleClick}>
      <div>{text}</div>
      <SortIcon isAsc={isSort && !isDesc} isDesc={isDesc} className={styles.icon} />
    </div>
  )
}

export default SortCol;
