import Header from './Header';
//import NotificationsModal from '@components/NotificationsModal';
import styles from './index.module.css';

export const Content = ({children}) => (
  <main className={styles.container}>
    {children}
  </main>
)
const Layout = ({children}) => (<>
  <Header />
  <Content>
    {children}
  </Content>
  {/*<NotificationsModal />*/}
</>);

export default Layout;
