import DonationMessagesFormPage from '@components/DonationMessages/Form';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import { createDonationMessages } from '@utils/api/donationMessages';

const DonationMessageCreatePage = withParams(({ params: { id } }) => (
  <DonationMessagesFormPage
    form={{
      url: "/donation_messages.json",
      onSubmit: createDonationMessages
    }}
    page={{
      title: "Create Donation Message",
      back: true,
      submit: "Create New Donation Message"
    }}
  />
))

export default withPageTitle(DonationMessageCreatePage, "Create Donation Message");
