import { useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useLoaderDispatch, startLoading, stopLoading } from "@containers/Loader";
import { useToastAddAction } from '@containers/ToastContext';
import { useAuthDispatch, logoutAction } from '@containers/Auth';
import { useErrorModalDispatch } from "@containers/ErrorModal";
import { FormError, TokenError } from '@utils/api/error';
import { useEffect, useMemo } from 'react';

export default function Form({ validationSchema, onSubmit, toast, to = "..", children, className, defaultValues, url, onSuccess = () => { }, ...props }) {
  const loaderDispatch = useLoaderDispatch(),
    navigate = useNavigate(),
    errorModalDispatch = useErrorModalDispatch(),
    addToast = useToastAddAction(),
    authDispatch = useAuthDispatch(),
    form = useForm({
      resolver: validationSchema && yupResolver(validationSchema),
      defaultValues: useMemo(() => {
        return defaultValues;
      }, [defaultValues])
    }),
    handleSubmit = async formValues => {
      loaderDispatch(startLoading());
      try {
        const response = await onSubmit(formValues, url, defaultValues);
        onSuccess({ response, formValues, defaultValues });
        if (to) {
          navigate(to)
        }
        if (toast) {
          addToast(toast)
        }
      } catch (e) {
        if (e instanceof FormError) {
          if (e.fieldErrors?.length > 0) {
            e.fieldErrors.forEach(({ name, type, message }) =>
              form.setError(name, { type, message })
            )
          }
          if (e.formError) {
            errorModalDispatch(e.formError === "Email has already been taken" ? "Email is already associated with an account. Either log in to your existing account or choose a different email." : e.formError)
          }
        } else if (e instanceof TokenError) {
          if (url === "/login") {
            errorModalDispatch("The email or password you entered is incorrect")
          }
          authDispatch(logoutAction())
        } else {
          errorModalDispatch("An unexpected error has occurred")
        }

      } finally {

        loaderDispatch(stopLoading());
      }
    };
  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues]);
  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className={className} {...props}>
        {children}
      </form>
    </FormProvider>
  );
};
