import styles from './index.module.scss';
import {classNames} from '@utils';

export const Loader = ({className, dark, small}) => <div className={classNames(styles.loader, dark && styles.dark, small && styles.small ,className)} />

export const RequestLoader = ({className}) => (
  <div className={classNames(styles.wrapper,className)}>
    <Loader dark />
  </div>
)
const LoaderOverlay = () => (
  <div className={styles.container}>
    <Loader />
  </div>
)

export default LoaderOverlay;
