import { string, object, mixed } from "yup";

export const donationMessagesSchema = object().shape({
  body: string()
    .required("Body is required")
    .max(250, "Body must be at most 250 characters"),
  button_text: string()
    .required("Button text is required"),
  link: string().url("Link Url must be a valid URL").nullable(),
});
