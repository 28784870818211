import {useState} from 'react';
import Page from '@components/Layouts/Page';
import SearchFilter from '@components/SearchFilter';
import CharterNumberList from '@components/Charter/List';
import {Pagination} from '@components/Pagination';
import withPageTitle from '@containers/withPageTitle';
import usePaginationRequest from '@containers/usePaginationRequest';
import useEffectAfterMount from '@containers/useEffectAfterMount';
import styles from './index.module.css';

export const CharterNumberListPageComponent = ({request,isAdmin,...props}) => {
  const [textFilter,setTextFilter] = useState();
  useEffectAfterMount(() => {
    const query = {};
    if(textFilter){
      query.search = textFilter;
    }
    request.setQuery(query)
  },[textFilter])
  return(
    <Page
      left={(
        <SearchFilter className={styles.input} onChange={setTextFilter} placeholder="Search by Email or Charter Number..." />
      )}
      {...props}
    >
      <Pagination previousPage={request.previousPage} setPage={request.setPage} nextPage={request.nextPage} pageCount={request.state.data.page_count} page={request.state.data.page} status={request.state.status} error={request.state.error}>
        <CharterNumberList charterNumbers={request.state.data.charter_cs} text="No charter numbers found" />
      </Pagination>
    </Page>
  )
}

const CharterNumberListPageContainer = ({url,...props}) => {
  const request = usePaginationRequest(url,25);
  return <CharterNumberListPageComponent {...props} request={request} />
}

const CharterNumberListPage = () => (
  <CharterNumberListPageContainer
    title="Charter Numbers"
    url="/charter_cs.json"
  />
)

export default withPageTitle(CharterNumberListPage, "Charter Numbers");
