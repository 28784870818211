import { useState } from "react";
import { Controller } from "react-hook-form";
import Page from "@components/Layouts/Page";
import Content from "@components/Layouts/Content";
import Form from "@components/Form";
import Switch from "@components/Form/Fields/Switch";
import Select from "@components/Form/Fields/Select";
import File from '@components/Form/Fields/File';
import InputComponent, { Input } from "@components/Form/Fields/Input";
import FileField from "@components/Form/Fields/AvatarCrop";
import Button from "@components/Button";
import { indicatorSchema } from "@utils/validation/indicator";
import styles from "./index.module.css";

const assignableByFilterOptions = [
  { value: "Steward", name: "Steward" },
  { value: "Admin", name: "Admin" },
];

const LogoField = ({ name, ...props }) => (
  <Controller
    name={name}
    render={({ field }) => (
      <>
        <FileField
          name="file"
          {...props}
          aspect={1}
          value={field.value}
          onCrop={(img) => {
            field.onChange(img);
          }}
        />
        <Input {...field} {...props} className="hidden" />
      </>
    )}
  />
);
const IndicatorFormPage = ({ form, page, indicator }) => {
  const [assignableByFilter, setAssignableByFilter] = useState(
    assignableByFilterOptions[0].value
  );
  return (
    <Form {...form}>
      <Page
        {...page}
        right={
          <Button medium type="submit">
            Save
          </Button>
        }
      >
        <Content title="Indicator Information">
          <div className={styles.content}>
            <div className={styles.center}>
              <LogoField
                name="image"
                label="Image"
                className={styles.file}
                dropzoneClassName={styles.fileDropzone}
              />
            </div>
            <div>
              <InputComponent
                name="Name"
                placeholder="Enter Indicator Name"
                label="Name"
              />
              <Select
                name="AssignableBy__c"
                options={assignableByFilterOptions}
                label="Assignable By"
              />
              <Switch
                label="Active"
                name="Active__c"
                className={styles.switch}
              />
              <Switch
                label="Searchable"
                name="Searchable__c"
                className={styles.switch}
              />
              <Switch
                label="Use Icon For Pin"
                name="UseIconForPin__c"
                className={styles.switch}
              />
            </div>
          </div>
        </Content>
      </Page>
    </Form>
  );
};

export default IndicatorFormPage;
