import { useState } from "react";
import RequestButton from "@containers/RequestButton";
import styles from "./index.module.scss";

export const IndicatorCharterNumberUpload = ({ indicator, makeRequest, setModal }) => {
  const [data, setdata] = useState(null);
  const getFileData = (file) => {
    var formData = new FormData();
    formData.append("charter_csv", file);
    setdata(formData);
  };

  return (
    <>
      <div className={styles.container}>
        <input
          name="csv"
          type="file"
          onChange={(e) => getFileData(e.target.files[0])}
        />
        <RequestButton
          // button="button"
          toast="Import Complete"
          makeRequest={() => makeRequest(indicator, data)}
          disabled={!data}
          onSuccess={() => setModal(false)}
          to={"/"}
        >
          import charter numbers
        </RequestButton>
      </div>
    </>
  );
};
