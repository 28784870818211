import {Link} from 'react-router-dom';
import Page from '@components/Layouts/Page';
import Button from '@components/Button';
import AnnouncementList from '@components/Announcement/List';
import {Pagination} from '@components/Pagination';
import withPageTitle from '@containers/withPageTitle';
import usePaginationRequest from '@containers/usePaginationRequest';

export const AnnouncementListPageComponent = ({request,...props}) => (
  <Page
    right={(
      <Button as={Link} to="new" type="button" medium primary>New announcement</Button>
    )}
    {...props}
  >
    <Pagination previousPage={request.previousPage} setPage={request.setPage} nextPage={request.nextPage} pageCount={request.state.data.page_count} page={request.state.data.page} status={request.state.status} error={request.state.error}>
      <AnnouncementList announcements={request.state.data.announcements} text="No announcements found" />
    </Pagination>
  </Page>
)

const AnnouncementListPageContainer = ({url,...props}) => {
  const request = usePaginationRequest(url,25);
  return <AnnouncementListPageComponent {...props} request={request} />
}

const AnnouncementListPage = () => (
  <AnnouncementListPageContainer
    title="Announcements"
    url="/announcements.json"
  />
)

export default withPageTitle(AnnouncementListPage, "Announcements");
