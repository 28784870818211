import { useState } from 'react';
import { Modal, ModalText } from '@components/Modal';
import styles from './index.module.css';
import vertIcon from '@assets/images/more_vert_black_24dp.svg';

export const ExpandMenu = ({ children, className, title }) => {
  const [modal, setModal] = useState();
  return (<>
    <button type="button" onClick={() => { setModal(true) }} className={styles.button}>
      <img src={vertIcon} className={styles.icon} />
    </button>
    {modal && (
      <Modal close={() => { setModal(false) }}>
        {title && <ModalText>{title}</ModalText>}
        <div className={styles.content}>
          {typeof children === "function" ? children(setModal) : children}
        </div>
      </Modal>
    )}
  </>)
}

export default ExpandMenu;
