import IssueList from '@components/Issue/List';
import StickyHeader from '@components/StickyHeader';
import styles from './index.module.scss';

const IssueSection = ({title,...props}) => (
  <div className={styles.container}>
    <StickyHeader className={styles.header} groundClassName={styles.margin} height="50px">
      <div className={styles.title}>{title}</div>
    </StickyHeader>
    <IssueList {...props} />
  </div>
)


export default IssueSection;
