import {useEffect} from 'react';
import styles from './index.module.css';
import googlePlay from '@assets/images/google-play-badge-300x89.png';
import appStore from '@assets/images/download-on-the-app-store.png';

const MapYourLibraryRedirectPage = ({logout}) => {
  useEffect(() => {
    if (['iPhone', 'iPad', 'iPod'].indexOf(window.navigator.platform) !== -1) {
      //window.location = "com.littlefreelibrary://transferyourlibrary"
    }
    if(/Android/.test(window.navigator.userAgent)){
      window.location = "littlefreelibrary://mapyourlibrary"
    }
  },[])
  return(
    <div className={styles.container}>
      <div className={styles.header}>
        Transfer Your Little Library
      </div>
      <div className={styles.text}>
        You have clicked a link to transfer your Little Library, please download one of our mobile apps to continue.
      </div>
      <div className={styles.badgeWrapper}>
        <a href="http://play.google.com"><img src={googlePlay} width="150" height="60" alt="Get it on Google Play" border="0" /></a>
        <a href="https://itunes.apple.com"><img src={appStore} width="150" height="60" alt="Get it on Google Play" border="0" /></a>
      </div>
    </div>
  )
}

export default MapYourLibraryRedirectPage;
