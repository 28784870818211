import IndicatorFormPage from "@components/Indicator/Form";
import Button from "@components/Button";
import Request from "@containers/Request";
import withParams from "@containers/withParams";
import withPageTitle from "@containers/withPageTitle";
import {
  updateIndicator,
  updateIndicatorImportChartNumber,
} from "@utils/api/indicator";

const makeIndicatorDefaultValues = ({ image_url, ...indicator }) => ({
  ...indicator,
  image: image_url || "",
});

const IndicatorUpdatePage = withParams(({ params: { id } }) => {
  const url = `/app_flags/${id}.json`;
  return (
    <Request url={url}>
      {(indicator, makeRequest, updateData) => (
        <IndicatorFormPage
          form={{
            url,
            onSubmit: updateIndicator,
            defaultValues: makeIndicatorDefaultValues(indicator),
            toast: "Indicator updated",
          }}
          page={{
            title: indicator.Name,
            back: true,
          }}
        />
      )}
    </Request>
  );
});

export default withPageTitle(IndicatorUpdatePage, "Update Indicator");
