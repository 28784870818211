import styles from './index.module.css';

const Post = ({header,subheader,buttons,right,email,text,note,children}) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <div>
        <div className={styles.title}>{header}</div>
          {email && (
            <div className={styles.email}>{email}</div>
          )}
        <div className={styles.subtitle}>{subheader}</div>
      </div>
      {buttons && (
        <div className={styles.buttons}>
          {buttons}
        </div>
      )}
      {right}
    </div>

    {text && (
      <div className={styles.text}>
        {text}
      </div>
    )}
    {note && (
      <div className={styles.note}>
        {note}
      </div>
    )}
    {children}
  </div>
)

export default Post;
