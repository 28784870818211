import styles from './index.module.css';
import LinkText from '@components/LinkText';
import LogoutButton from '@containers/LogoutButton';
import googlePlay from '@assets/images/google-play-badge-300x89.png';
import appStore from '@assets/images/download-on-the-app-store.png';

const DownloadPage = ({logout}) => (
  <div className={styles.container}>
    <div className={styles.title}>Download the mobile app</div>
    <div className={styles.badgeWrapper}>
      <a href="http://play.google.com"><img src={googlePlay} width="150" height="60" alt="Get it on Google Play" border="0" /></a>
      <a href="itms-apps://"><img src={appStore} width="150" height="60" alt="Get it on Google Play" border="0" /></a>
    </div>
    {logout && <LinkText><LogoutButton /></LinkText>}
  </div>
)

export default DownloadPage;
