import { useWatch } from 'react-hook-form';
import FormPage from '@components/Form/Page';
import Button from '@components/Button';
import SplitRow from '@components/SplitRow';
import Page from '@components/Layouts/Page';
import Input from '@components/Form/Fields/Input';
import Radio from '@components/Form/Fields/Radio';
import Select from '@components/Form/Fields/Select';
import PhoneNumberInput from '@components/Form/Fields/PhoneNumberInput';
import AutocapitalizeInput from '@components/Form/Fields/AutocapitalizeInput';
import StateSelect from '@containers/StateSelect';
import withParams from '@containers/withParams';
import { libraryRequestSchema } from '@utils/validation/library_request';
import { createLibraryRequest } from '@utils/api/library_request';
import styles from './index.module.css';

const yesNoOptions = [{ value: true, name: "Yes" }, { value: false, name: "No" }]

const SecondaryStewardFields = () => {
  const hasSecondary = useWatch({ name: "Has_Secondary__c" });
  return (hasSecondary && hasSecondary === "true") ? (<>
    <div className={`${styles.sectionHeader} ${styles.section}`}>Secondary Steward</div>
    <SplitRow>
      <AutocapitalizeInput name="Secondary_Steward_First_Name__c" label="Secondary Steward's First Name" secondary required />
      <AutocapitalizeInput name="Secondary_Steward_Last_Name__c" label="Secondary Steward's Last Name" secondary required />
    </SplitRow>
    <SplitRow>
      <Input type="email" name="Secondary_Steward_s_Email__c" label="Secondary Steward's Email" secondary required />
      <PhoneNumberInput name="Secondary_Steward_s_Phone__c" label="Secondary Steward's Phone" secondary />
    </SplitRow>
    <Select name="secondary_newsletter" options={yesNoOptions} placeholder="Please select..." label="Would the primary steward like to receive Little Free Library’s e-newsletter?" secondary />
  </>) : null
}
const exactLocationOnMapOptions = [{ value: false, name: "Street Address" }, { value: false, name: "GPS Coordinates" }]

const MapFields = () => {
  const displayOnMap = useWatch({ name: "Display_On_Map__c" });
  return (displayOnMap && displayOnMap === "true") ? (<>
    <Select name="Exact_Location_On_Map__c" options={exactLocationOnMapOptions} placeholder="Please select..." label="Would you prefer to use a street address or GPS coordinates to determine your library's location on our map?" secondary required />
    <Input name="List_As_Name__c" label="Enter the library's name as you would like it to appear on the map:" secondary />
    <Select name="Email_On_Map__c" options={yesNoOptions} placeholder="Please select..." label="Would you like to display the primary steward's email address on the map?" secondary />
    <Input as="textarea" rows="8" name="Library_Story__c" label="Library Story or Description" secondary />
    <Input as="textarea" rows="4" name="Admin_Only_Notes__c" label="Notes/Special Instructions" secondary />
  </>) : null
}

const radioOptions = [{ value: true, label: "Add a new little library to the map" }, { value: false, label: "Update or edit information about a little library that's already on the map" }]
const LibraryFormPage = withParams(({ params: { charter } }) => (
  <Page title="Add or Update Your Little Library on the World Map">
    <div className={styles.header}>
      Add Your Library to the World Map
    </div>
    <div className={styles.text}>
      Welcome! Fill out the form below to add your library to the world map! <a className={styles.link} href="https://littlefreelibrary.org/faqs/" target="_blank" rel="noopener noreferrer">Click here for answers to common questions</a>. <b>View an <a className={styles.link} href="https://littlefreelibrary.org/wp-content/uploads/2018/08/world-map-example.png" target="_blank" rel="noopener noreferrer">example of a Little Free Library</a> on the world map.</b>

    </div>
    <div className={styles.header}>
      Update an Existing Library

    </div>
    <div className={styles.text}>
      Fill out the form below to update your library if it is already on the world map. Please note:
      <ul>
        <li>
          <b>You must enter steward contact information and the library’s location every time you fill out this form.</b> If that information has changed, enter the most up-to-date information in those fields.

        </li>
        <li>
          <b>It is very important to enter the correct charter number for your library.</b> Otherwise, you could overwrite someone else’s information.
        </li>
        <li>
          <b>If you want to change your library’s story, type in a new story.</b> Otherwise, leave the story blank and your current story will stay on the map.
        </li>
        <li>
          <b>If you want to change your library’s photos, upload one or two new photos.</b> These photos will replace any photos that currently appear with your library’s listing on the world map. Do not upload any new photos if you wish to keep the photos of your library that currently display on the world map.
        </li>
        <li>
          <b>You will receive an email immediately after you submit the form below</b> to let you know that we have received your request. We will email you again once your updates have been made.
        </li>
        <li>
          <b>View an <a className={styles.link} href="https://littlefreelibrary.org/wp-content/uploads/2018/08/world-map-example.png" target="_blank" rel="noopener noreferrer">example of a Little Free Library</a> on the world map.</b>
        </li>
      </ul>
      <b>IMPORTANT :</b> If you built your own library, you must first <a className={styles.link} href="https://littlefreelibrary.org/join/" target="_blank" rel="noopener noreferrer">register by purchasing an official charter sign</a> for your library before you can add it to the world map.
    </div>
    <FormPage
      form={{
        url: "/library_requests.json",
        onSubmit: createLibraryRequest,
        defaultValues: charter ? { is_creating: true, Official_Charter_Number: charter } : { is_creating: true },
        validationSchema: libraryRequestSchema,
        className: styles.container,
        to: "success"
      }}
    >
      <div className={styles.section}>
        <div className={styles.headerTwo}>
          Add or Update a Little Library on the World Map
        </div>
        <div className={styles.text}>
          Your book exchange must have an official Little Free Library charter sign engraved with a unique charter number before it can be added to the map. <a className={styles.link} href="https://littlefreelibrary.org/registration-process" target="_blank" rel="noopener noreferrer">Learn more about charter signs</a>.
          <br />
          You will receive a confirmation email after you submit this form. Your submission will be reviewed (typically within three business days) and you will receive another email to let you know when your library has been added or updated on the map.
        </div>
      </div>
      <Radio options={radioOptions} name="is_creating" label="What would you like to do?" secondary required />
      <div className={styles.section}>
        <div className={styles.sectionHeader}>Primary Steward Information</div>
        <div className={styles.text}>The primary steward is the person Little Free Library will communicate with regarding this book exchange.</div>
      </div>
      <AutocapitalizeInput name="Primary_Steward_First_Name__c" label="Primary Steward's First Name" secondary required />
      <SplitRow>
        <AutocapitalizeInput name="Primary_Steward_Last_Name__c" label="Primary Steward's Last Name" secondary required />
        <Input type="email" name="Primary_Steward_s_Email__c" label="Primary Steward's Email" secondary required />
        <PhoneNumberInput name="Primary_Steward_s_Phone__c" label="Primary Steward's Phone" secondary />
      </SplitRow>
      <Select name="newsletter" options={yesNoOptions} placeholder="Please select..." label="Would the primary steward like to receive Little Free Library’s e-newsletter?" secondary required />
      <Select name="Has_Secondary__c" options={yesNoOptions} placeholder="Please select..." label="Is there a secondary steward for this library?" secondary required />
      <SecondaryStewardFields />
      <div className={styles.section}>
        <div className={styles.sectionHeader}>Library Information</div>
        <div className={styles.text}>Please answer the questions below to provide us with location and background information about your little library. We require city, state/province, country and postal code for our records. You may opt out of publicly displaying this information on our world map. Other information you provide will help us better understand the Little Free Library network as it grows.</div>
      </div>
      <Input name="Official_Charter_Number" label="Library's Charter Number" secondary required disabled={charter ? true : false} />
      <SplitRow>
        <Select name="Library_Country__c" options={yesNoOptions} placeholder="Please select..." label="Library's Country" secondary required />
        <AutocapitalizeInput name="Library_City__c" label="Library's City" secondary required />
      </SplitRow>
      <StateSelect name="Library_State_Province_Region__c" label="US State" placeholder="Please select..." secondary required />
      <AutocapitalizeInput name="province" label="Other Province (optional)" secondary />
      <Input name="Library_Postal_Zip_Code__c" label="Postal/Zip Code" secondary required />
      <div className={`${styles.sectionHeader} ${styles.section}`}>Little Free Library Mapping Information</div>
      <Select name="Display_On_Map__c" options={yesNoOptions} placeholder="Please select..." label="Would you like your Little Free Library to display on our world map?" secondary required />
      <MapFields />
      <Select name="Trademark_Agreement__c" options={yesNoOptions} placeholder="Please select..." label={<>I agree to Little Free Library's &nbsp;<a className={styles.link} href="https://littlefreelibrary.org/wp-content/uploads/2019/09/Little-Free-Library-Trademark-License-Agreement.pdf" target="_blank" rel="noopener noreferrer">Trademark Agreement</a></>} secondary required />
      <div className={`${styles.text} ${styles.section}`}>
        By submitting this form, you agree to our <a className={styles.link} href="https://littlefreelibrary.org/wp-content/uploads/2012/10/Little-Free-Library-Terms-and-Conditions-of-Use-Agreement.pdf" target="_blank" rel="noopener noreferrer">Terms & Conditions</a> and our <a className={styles.link} href="https://littlefreelibrary.org/privacy-policy/" target="_blank" rel="noopener noreferrer">privacy policy</a>. You also understand that the story, photos, and location information submitted above will be shared publicly on our map and may be shared by LFL staff at our discretion. <b>Please click the SUBMIT button below to submit this form.</b> You will receive an immediate confirmation email at the primary steward's email address above letting you know that we have received your submission.
      </div>
      <Button className={styles.button}>Submit</Button>
    </FormPage>
  </Page>
))

export default LibraryFormPage;
