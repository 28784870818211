import { useParams } from 'react-router-dom';

export default function withParams(WrappedComponent){
  return (props) => {
    const params = useParams();
    return (
      <WrappedComponent {...props} params={params} />
    )
  }
}
