import {Link,useMatch} from 'react-router-dom';
import Form from '@components/Form';
import Button from '@components/Button';
import {Header} from '@components/Layouts/App/Header';
import {Content} from '@components/Layouts/App';
import styles from './index.module.scss';
import logo from '@assets/images/LFL_RGB_HorizonalTaglineLogo_White@3x.png';

export const PublicForm = ({buttonText = "Submit",children,margin,homePage,link,title,text,after,...props}) => (
  <Form {...props}>
    <PublicHeader title={title} text={text} margin={margin} homePage={homePage} />
    {children}
    <Button className={styles.button} light type="submit">{buttonText}</Button>
    {after}
    {link && (Array.isArray(link) ? (
      link.map(l => <Link key={l.to} to={l.to} className={styles.link}>{l.text}</Link>)
    ) : (
      <Link to={link.to} className={styles.link}>{link.text}</Link>
    ))}
  </Form>
)

export const PublicHeader = ({title,text,children,margin,homePage = "/"}) => (
  <header className={styles.header}>
    <Link to={homePage}><img src={logo} alt="Little Free Library Small Logo" className={styles.logo} /></Link>
    <div className={margin ? styles.margin : undefined}>
      <div className={styles.title}>{title}</div>
      {text && <div className={styles.subtitle}>{text}</div>}
    </div>

    {children}
  </header>
)

const PublicLayout = ({children}) => {
  const isCharterMapMatch = useMatch("/mapyourlibrary/:charter/form/*"),
        isMapMatch = useMatch("/mapyourlibrary/form/*"),
        isTransferMatch = useMatch("/transferyourlibrary/*"),
        isOurMapMatch = useMatch("/ourmap");
  return isOurMapMatch ? (
    children
  ) : (
    (isMapMatch || isTransferMatch || isCharterMapMatch) ? (<>
      <Header />
      <Content>
        {children}
      </Content>
    </>) : (
      <div className={styles.container}>
        <div className={styles.content}>
          {children}
        </div>
      </div>
    )
  )
}

export default PublicLayout;
