import styles from './index.module.css';
import {classNames} from '@utils'

const Content = ({left,right,title,children,className,noPadding}) => (
  <div className={classNames(styles.container,noPadding && styles.noPadding)}>
    <div className={styles.title}>{title}</div>
    <div className={classNames(styles.content,className)}>
      {(left || right) && (
        <div className={styles.flex}>
          <div>
            {left}
          </div>
          <div>
            {right}
          </div>
        </div>
      )}
      {children}
    </div>
  </div>
)

export default Content;
