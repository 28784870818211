import Switch from '@components/Form/Fields/Switch';
import Form from '@components/Form';
import {Modal,ModalText,ModalButtons} from '@components/Modal';
import {updateUser} from '@utils/api/user';
import {userPermissionsSchema} from '@utils/validation/user';
import styles from './index.module.css';

const makeDefaultPermissionsValues = ({can_check_in,can_comment,can_report}) => ({
  can_check_in,
  can_comment,
  can_report
})
const PermissionsModalForm = ({close,user,...props}) => (
  <Modal close={close}>
    <ModalText>User Permissions</ModalText>
    <Form
      {...props}
      defaultValues={makeDefaultPermissionsValues(user)}
      validationSchema={userPermissionsSchema}
      url={`/users/${user.id}.json`}
      onSubmit={updateUser}
      toast="User permissions updated"
      to={false}
    >
      <Switch name="can_comment" label="Comments" className={styles.switch} />
      <Switch name="can_check_in" label="Check-Ins" className={styles.switch} />
      <Switch name="can_report" label="Reports" className={styles.switch} />
      <ModalButtons onCancelClick={close} continueProps={{type: "submit",medium: true}} cancelProps={{type: "button",medium: true,secondary: true}} continueText="Save" />
    </Form>
  </Modal>
)

export default PermissionsModalForm;
