import Table from '@components/Table';
import styles from './index.module.css'

const ListPage = ({ donationMessages, text = "No donation messages found" }) => (
  <Table
    cols={["Title", "Link", "Active", "First Count", "Recurring Code", "Trigger Type"]}
    rows={donationMessages}
    renderRow={(item) => (<>
      <div className={styles.padding}>{item.title}</div>
      <div className={styles.padding}><a target={'_blank'} href={item.link}>{item.link}</a></div>
      <div className={styles.padding}>{item.active ? "Active" : "Inactive"}</div>
      <div className={styles.padding}>{item.first_count}</div>
      <div className={styles.padding}>{item.recurring_count}</div>
      <div className={styles.padding}>{item.trigger_type}</div>
    </>)}
    placeholder={{ to: "new", text, hideButton: true }}
  />
)

export default ListPage;
