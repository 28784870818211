import {useState,useCallback} from 'react';
import useEventListener from '@containers/useEventListener';

export default function withIsMobile(WrappedComponent,width){
  return (props) => {
    const [isMobile,setIsMobile] = useState(window.innerWidth < width)
    const handleSizeChange = useCallback(() => {
      setIsMobile(window.innerWidth < width)
    }, [setIsMobile])
    useEventListener("resize", handleSizeChange);
    return (
      <WrappedComponent {...props} isMobile={isMobile} />
    )
  }
}
