import {useEffect,useReducer} from 'react';
import {getRequest} from '@utils/api';

const REQUEST = "REQUEST";
const REQUEST__SUCCESS = "REQUEST__SUCCESS";
const REQUEST__FAILURE = "REQUEST__FAILURE";
const UPDATE_DATA = "UPDATE_DATA";

const initialState = {status: "loading", data: {page: 1}, error: null,query: null};
function requestReducer(state = initialState, {type,payload}) {
  switch (type) {
    case REQUEST:
      return {
        ...state,
        query: payload,
        error: null,
        status: "loading"
      };
    case REQUEST__SUCCESS:
      return {
        ...state,
        status: "success",
        data: payload,
        error: null
      }
    case REQUEST__FAILURE:
      return {
        ...initialState,
        status: "error",
        error: payload || "An unexpected error has occurred"
      }
    case UPDATE_DATA:
      return {
        ...state,
        data: payload
      }
    default:
      throw new Error(`Unhandled action type: ${type}`)
  }
}

const stringifyQuery = (obj) => {
  if(!obj) return ""
  var qs = ""
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      qs += `&${key}=${obj[key]}`
    }
  }
  return qs
}


export default function usePaginationRequest(url,pageSize = 50,initialQuery = {}){
  const [state, dispatch] = useReducer(requestReducer, initialState),
        makeRequest = async (page = 1,query = {}) => {
          dispatch({type: REQUEST, payload: query})
          try {
            dispatch({type: REQUEST__SUCCESS, payload: await getRequest(`${url}?page_size=${pageSize}&page=${page}${stringifyQuery({...initialQuery,...query})}`)})
          } catch (e) {
            dispatch({type: REQUEST__FAILURE, payload: "An unexpected error has occurred"})
          }
        },
        setPage = (page) => {
          makeRequest(page,state.query);
        },
        nextPage = () => {
          setPage(state.data.page+1)
        },
        previousPage = () => {
          setPage(state.data.page-1)
        },
        setQuery = (query = {}) => {
          makeRequest(1,query)
        },
        updateData = (payload) => dispatch({type: UPDATE_DATA, payload});
  useEffect(() => {
    makeRequest(1, initialQuery);
  },[])
  return {state,makeRequest,setQuery,previousPage,nextPage,setPage,updateData};
}
