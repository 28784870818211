import {useState} from 'react';
import {classNames} from '@utils';
import styles from './index.module.css';
import dropdownIcon from '@assets/images/dropdown.svg';

const Section = ({title,collapsible,className,children,wrapperClassName}) => {
  const [isOpen,setIsOpen] = useState(!collapsible);
  const handleClick = () => setIsOpen(prevIsOpen => !prevIsOpen);
  return(
    <div className={classNames(styles.container,wrapperClassName,isOpen && styles.open, collapsible && styles.collapsible)}>
      <div className={styles.header} onClick={handleClick}>
        <div className={styles.title}>{title}</div>
        {collapsible && <img src={dropdownIcon} className={styles.toggle} />}
      </div>
      <div className={classNames(styles.content, className)}>
        <div className={styles.padding}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Section;
