import {makeRequest} from '@utils/api';

export const createSponsor = (app_sponsor) => makeRequest("POST","/app_sponsors.json",{
  app_sponsor
})
const base64regex = /data:image\/([a-zA-Z]*);base64,([^\"]*)/g

const makeAppSponsor = ({id,ImageUrl__c,url,image,...app_sponsor}) => {
  const obj = {...app_sponsor};
  if(base64regex.test(image)){
    obj.image = image
  }
  return obj
}
export const updateSponsor = (app_sponsor, url) => makeRequest("PATCH",url,{
  app_sponsor: makeAppSponsor(app_sponsor)
})
