import {string, object} from 'yup';

export const libraryRequestSchema = object().shape({
  Primary_Steward_First_Name__c: string().required("Primary Steward's First Name is required"),
  Primary_Steward_Last_Name__c: string().required("Primary Steward's Last Name is required"),
  Primary_Steward_s_Email__c: string().email('Invalid email').required("Primary Steward's Email is required"),
  newsletter: string().required('This field is required'),
  Has_Secondary__c: string().required('This field is required'),
  Secondary_Steward_First_Name__c: string().test('Has secondary steward but no first name entered',"Secondary Steward's First Name is required",function(){
    return (this.parent.Has_Secondary__c && this.parent.Has_Secondary__c === "true") ? this.parent.Secondary_Steward_First_Name__c : false;
  }),
  Secondary_Steward_Last_Name__c: string().test('Has secondary steward but no last name entered',"Secondary Steward's Last Name is required",function(){
    return (this.parent.Has_Secondary__c && this.parent.Has_Secondary__c === "true") ? this.parent.Secondary_Steward_Last_Name__c : false;
  }),
  Secondary_Steward_s_Email__c: string().email('Invalid email').test('Has secondary steward but no email entered',"Secondary Steward's Email is required",function(){
    return (this.parent.Has_Secondary__c && this.parent.Has_Secondary__c === "true") ? this.parent.Secondary_Steward_Email__c : false;
  }),
  Official_Charter_Number: string().required("Library's Charter Number is required"),
  Library_Country__c: string().required("Library's Country is required"),
  Library_City__c: string().required("Library's City is required"),
  Library_State_Province_Region__c: string().required("Library's US State is required"),
  Library_Postal_Zip_Code__c: string().required("Library's Postal/Zip Code is required"),
  Display_On_Map__c: string().required("This field is required"),
  Exact_Location_On_Map__c: string().test('Has library displaying on map but no preferred location type selected',"This field is required",function(){
    return (this.parent.Has_Secondary__c && this.parent.Has_Secondary__c === "true") ? this.parent.Secondary_Steward_First_Name__c : false;
  }),
  Trademark_Agreement__c: string().test('Agreed to statement',"Secondary Steward's Last Name is required",function(){
    return (this.parent.Trademark_Agreement__c && this.parent.Trademark_Agreement__c === "true")
  })
});
