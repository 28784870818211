import { forwardRef } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import withField from '@components/Form/Fields/withField';
import styles from './index.module.css';
import { classNames } from '@utils';
import Select from 'react-select';

const getOptions = (options) => {
  return options.map((item) => {
    return { value: item.value, label: item.name }
  })
}

export const MultiSelectComponent = forwardRef(({ options, placeholder, className, ...props }, ref) => (
  <Select isMulti options={getOptions(options)} {...props}
    className={classNames(props.disabled && styles.disabled, className)}
    styles={{
      container: (base) => ({
        ...base,
        width: "100%",
        minWidth: "100%"
      }),
      multiValueLabel: (base) => ({
        ...base,
        fontSize: 12
      }),
      placeholder: (base) => ({
        ...base,
        fontSize: 14
      }),
      menuList: (base) => ({
        ...base,
        fontSize: 14
      }),
    }}
    ref={ref}>
  </Select>
));

export const SelectComponent = forwardRef(({ options, placeholder, className, ...props }, ref) => (
  <select {...props} className={classNames(props.disabled && styles.disabled, className)} ref={ref}>
    {placeholder && <option value="" disabled={props.unclearable} selected>{placeholder}</option>}
    {(options && options.length > 0) ? (
      options.map((option, i) =>
        <option key={option.value || i} value={option.value} disabled={option.disabled}>{option.name}</option>
      )
    ) : null}
  </select>
))

export const ControlledSelectComponent = ({ as: PassedSelectComponent = SelectComponent, name, ...props }) => (
  <Controller
    name={name}
    render={({ field }) => (
      <PassedSelectComponent
        {...field}
        {...props}
      />
    )}
  />
)
export const ControlledMultiSelectComponent = ({ as: PassedSelectComponent = MultiSelectComponent, name, ...props }) => (
  <Controller
    name={name}
    render={({ field }) => (
      <PassedSelectComponent
        {...field}
        {...props}
      />
    )}
  />
)
export const ControlledSelect = withField(ControlledSelectComponent)
export const ControlledMultiSelect = withField(ControlledMultiSelectComponent)

const SelectComp = withField(props => {
  const { register } = useFormContext();
  return <SelectComponent {...props} {...register(props.name)} />
})

export default SelectComp;
