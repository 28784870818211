import Table from '@components/Table';
import Photo from '@components/Photos/Item';
import {phoneFormat,makeLibraryName,makeAddress} from '@utils';
import styles from './index.module.css';

const LibrarysListPage = ({libraries,isAdmin,text = "There are no libraries"}) => (
  <Table
    cols={isAdmin ? ["","Library List-As Name","Charter Number","Address","Steward","Phone"] : ["","Library List-As Name", "Charter Number", "Address"]}
    rows={libraries}
    to={({id}) => `${id}/edit`}
    renderRow={(l) => (<>
      <div className={styles.imageWrapper}>
        <Photo src={l.primary_image} className={styles.image} />
      </div>
      <div>
        {makeLibraryName(l)}
      </div>
      <div>{l.Official_Charter_Number__c}</div>
      <div>
        <div>{l.Street__c}</div>
        <div>{makeAddress(l)}</div>
      </div>
      {isAdmin && (<>
        <div>{l.Primary_Steward_s_Name__c}</div>
        <div>{phoneFormat(l.Primary_Steward_s_Phone__c)}</div>
      </>)}
    </>)}
    placeholder={{to: "new", text,hideButton: true}}
  />
)

export default LibrarysListPage;
