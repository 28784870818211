import { useFormState,useWatch,useFormContext } from "react-hook-form";
import { usePrompt } from "@utils/prompt";
import FormPage from '@components/Form/Page';
import FlagFields from '@components/Flag/Fields';
import Button from '@components/Button';
import Label from '@components/Form/Label';
import SplitRow from '@components/SplitRow';
import Input from '@components/Form/Fields/Input';
import Switch from '@components/Form/Fields/Switch';
import AutocapitalizeInput from '@components/Form/Fields/AutocapitalizeInput';
import StateSelect from '@containers/StateSelect';
import CountrySelect from '@containers/CountrySelect';
import {useLoaderDispatch,startLoading,stopLoading} from "@containers/Loader";
import {useErrorModalDispatch} from "@containers/ErrorModal";
import {useToastAddAction} from '@containers/ToastContext';
import {withUser} from '@containers/Auth';
import AppInfoSelect from '@containers/AppInfoSelect';
import {librarySchema} from '@utils/validation/library';
import {libraryRequest} from '@utils/api/library';
import styles from './index.module.css';
import React, { useState } from 'react';

export const UnsavedChangesModal = () => {
  const {isDirty,isSubmitting} = useFormState();
  usePrompt("There are unsaved changes. Are you sure you want to leave this page?", isDirty && !isSubmitting);
  return null;
}

const AnnouncementButtons = ({url}) => {
  const loaderDispatch = useLoaderDispatch(),
        errorModalDispatch = useErrorModalDispatch(),
        addToast = useToastAddAction(),
        { setValue } = useFormContext(),
        {dirtyFields} = useFormState(),
        announcement = useWatch({name: 'Announcement__c'}),
        handleSave = async () => {
          try {
            loaderDispatch(startLoading());
            const response = await libraryRequest({Announcement__c: announcement},url,"PATCH");
            setValue("Announcement__c", response.Announcement__c,{ shouldDirty: false })
            addToast("Announcement added")
          } catch (e) {
            errorModalDispatch("Unable to save announcement")

          } finally {
            loaderDispatch(stopLoading());
          }
        },
        handleDelete = async () => {
          try {
            loaderDispatch(startLoading());
            const response = await libraryRequest({Announcement__c: null},url,"PATCH");
            setValue("Announcement__c", "",{ shouldDirty: false })
            addToast("Announcement deleted")
          } catch (e) {
            errorModalDispatch("Unable to delete announcement")
          } finally {
            loaderDispatch(stopLoading());
          }
        };
  return(
    <div className={styles.buttons}>
      <Button small type="button" disabled={!dirtyFields.Announcement__c} onClick={handleSave}>Save</Button>
      <Button small type="button" red onClick={handleDelete}>Delete</Button>
    </div>
  )
}
const HideMyLibraryButtons = ({url}) => {
  const loaderDispatch = useLoaderDispatch(),
        errorModalDispatch = useErrorModalDispatch(),
        addToast = useToastAddAction(),
        { setValue } = useFormContext(),
        {dirtyFields} = useFormState(),
        DownUntil__c = useWatch({name: 'DownUntil__c'}),
        handleSave = async () => {
          try {
            loaderDispatch(startLoading());
            const response = await libraryRequest({DownUntil__c},url,"PATCH");
            setValue("DownUntil__c", response.DownUntil__c,{ shouldDirty: false })
            addToast("Your library is now hidden from the map until the date provided")
          } catch (e) {
            errorModalDispatch("Unable to hide library")

          } finally {
            loaderDispatch(stopLoading());
          }
        },
        handleDelete = async () => {
          try {
            loaderDispatch(startLoading());
            const response = await libraryRequest({DownUntil__c: null},url,"PATCH");
            setValue("DownUntil__c", "",{ shouldDirty: false })
            addToast("Your library will now be displayed on the map")
          } catch (e) {
            errorModalDispatch("Unable to display library")
          } finally {
            loaderDispatch(stopLoading());
          }
        };
  return(
    <div className={styles.hideUntilButtons}>
      <Button small type="button" disabled={!dirtyFields.DownUntil__c} onClick={handleSave}>Save</Button>
      <Button small type="button" red onClick={handleDelete}>Delete</Button>
    </div>
  )
}
export const FormPageTitle = ({title,buttonText = "Save"}) => (
  <div className={styles.title}>
    {title}
    <Button className={styles.button} primary medium type="submit">{buttonText}</Button>
  </div>
)

const sortMapMeOptions = (a,b) => {
  if(a.disabled && !b.disabled){
    return 1
  }
  if(!a.disabled && b.disabled){
    return -1
  }
  return 0
}

const mapMeAccessor = (v) => v
const disabledAccessor = (v) => false
const adminMapMeAccessor = (v) => v?.[0]
const stewardMapMeOptions = (info) => info ? info.Map_Me__c_admin_options.map(a => ({name: a[0],id: a[0],disabled: info.Map_Me__c_options.findIndex(s => s === a[0]) === -1})).sort(sortMapMeOptions) : [{name: "Loading..."}]
const LibraryFormPage = withUser(({user, form, libraryId, library}) => {
  const [foreignCountry, setForeignCountry] = useState(library.Country__c !== 'Canada' && library.Country__c !== 'United States');

  const handleCountryChange = (selectedCountry) => {
    if (selectedCountry !== 'Canada' && selectedCountry !== 'United States') {
      setForeignCountry(true);
    } else {
      setForeignCountry(false);
    }
  };

  return (
  <FormPage
    form={{validationSchema: librarySchema, ...form}}
    title={(<FormPageTitle title="Library Information" />)}
  >
    <div className={styles.container}>
      <div >
      <AppInfoSelect name="Map_Me__c" label="Map Status" resource={user.is_admin ? "Map_Me__c_admin_options" : stewardMapMeOptions} valueAccessor={user.is_admin ? adminMapMeAccessor : undefined} labelAccessor={user.is_admin ? adminMapMeAccessor : undefined} disabledAccessor={user.is_admin ? disabledAccessor : undefined} />
      <Input name="Official_Charter_Number__c" className={styles.disabled} label="Charter Number" placeholder="Charter Number" disabled />

      <AutocapitalizeInput name="List_As_Name__c" label="Library List-As Name" placeholder="Enter Library List As Name" required />
      <AutocapitalizeInput name="Street__c" label="Library Street Address" sublabel={library.Map_Me__c == "Mapped" ? "Not required if GPS coordinates provided below" : "" } placeholder="Enter Library Street Address" 
      asterik ={["Mapped"].includes(library.Map_Me__c)} />
      <Input as="textarea" rows="2" name="HowToFind__c" label="Location Hint" sublabel="140 Character Limit" placeholder="Enter Location Hint" maxLength="140" />

      <CountrySelect name="Country__c" label="Country" placeholder="Select Country..." asterik 
        onChangeCapture={event => handleCountryChange(event.target.value)}
      />
      <AutocapitalizeInput name="City__c" label="City" placeholder="Enter City" asterik />
      { foreignCountry ? (
        <Input name="State_Province_Region__c" label="State/Province" placeholder="Select State/Province..." />
      ) :
      (
        <StateSelect name="State_Province_Region__c" label="State/Province" placeholder="Select State/Province..." asterik />
      )}

      <Input name="Postal_Zip_Code__c" label="Postal Code" placeholder="Enter Postal Code" asterik />
      <Input name="Latitude_Form__c" label="Latitude" type="number" step="any" />
      <Input name="Longitude_Form__c" label="Longitude" type="number" step="any" />
      <Input type="date" name="DownUntil__c" label="Hide My Library Until" sublabel="We will hide your library until the date entered" className={styles.announcement} />
      <HideMyLibraryButtons url={form.url} />
      <Input as="textarea" rows="2" name="Announcement__c" label="Announcement" placeholder="Enter news about your library. &#10;Announcement expires after 72 hours." maxLength="300" sublabel="300 Character Limit" className={styles.announcement} />
      <AnnouncementButtons url={form.url} />
      <Input as="textarea" rows="8" name="Library_Story__c" label="Story" placeholder="Enter Story" maxLength="1000"sublabel="1,000 Character Limit" />

      </div>
      <div>


          <Label className={styles.switchLabel}>Show My Name</Label>
          <Switch className={styles.switch} label={library.Primary_Steward_s_Name__c} name="Name_on_Map__c" />
          <Label className={styles.switchLabel}>Show My Email</Label>
          <Switch className={`${styles.switch} ${styles.email}`} label={library.Primary_Steward_s_Email__c} name="Email_on_Map__c" />

        <Input name="Facebook__c" label="Facebook" placeholder="http://www.facebook.com/username" />
        <Input name="Instagram__c" label="Instagram" placeholder="https://www.instagram.com/username" />
        <Input name="Twitter__c" label="Twitter" placeholder="https://www.twitter.com/username" />
        <Input name="Pinterest__c" label="Pinterest" placeholder="https://www.pinterest.com/username" />
        <FlagFields name="active_flags" libraryId={libraryId} />

      </div>
    </div>
    <div className={styles.buttonWrapper}>
      <Button className={styles.bottomButton} primary type="submit" medium>Save</Button>
    </div>
    <UnsavedChangesModal />
  </FormPage>
)});

export default LibraryFormPage;
