import {Controller} from 'react-hook-form';
import withField from '@components/Form/Fields/withField';
import {capitalize} from '@utils';

const Input = ({onChange,...props}) => (
  <input {...props} onChange={(e) => {onChange(capitalize(e.target.value))}} />
)
export const AutocapitalizeInput = (props) => (
  <Controller
    name={props.name}
    render={({ field }) => (
      <Input
        {...field}
        {...props}
      />
    )}
  />
)

const AutocapitalizeInputField = withField(AutocapitalizeInput)

export default AutocapitalizeInputField;
