import Post from '@components/Post';
import EmptyResourcePlaceholder from '@components/EmptyResourcePlaceholder';
import RequestButton from '@containers/RequestButton';
import {makeDateStr} from '@utils';
import {updateIssue, deleteLibraryIssue} from '@utils/api/issue';
import styles from './index.module.css';
import {useAuthState} from '@containers/Auth';

const handleDeleteButtonClick = (id) => () => new Promise(
  async (resolve,reject) => {
    try {
      resolve(await deleteLibraryIssue(id))
    } catch (e) {
      reject(e)
    }
  }
)

const handleResolveButtonClick = (id, user) => () => new Promise(
  async (resolve,reject) => {
    try {
      resolve(await updateIssue({Resolved__c: true, Read__c: true, ResolvedByAdmin__c: user.is_admin},id))
    } catch (e) {
      reject(e)
    }
  }
)

export const DeleteButton = ({id, onIssueDeleteSuccess, listView, ...props}) => (
  <RequestButton primary={!listView} xs={!listView} {...props} modalText="Are you sure you want to delete this issue?" toast="Issue has been deleted" makeRequest={handleDeleteButtonClick(id)} onSuccess={() => {onIssueDeleteSuccess(id)}}>
    Delete
  </RequestButton>
)

export const ResolveButton = ({id, onSuccess, onIssueUpdateSuccess, listView, user, ...props}) => (
  <RequestButton primary={!listView} xs={!listView} {...props} modalText="Are you sure you want to mark this issue as resolved?" toast="Issue has been marked as resolved" makeRequest={handleResolveButtonClick(id,user)} onSuccess={() => { listView ? onSuccess() : onIssueUpdateSuccess(id,true)}}>
    Resolve
  </RequestButton>
)

const IssueList = ({issues, onIssueUpdateSuccess, onIssueDeleteSuccess, placeholder = "No one has left an issue yet"}) => {
  const user = useAuthState();
  console.log(issues.length);
  return (
      issues?.length > 0 ? (
          issues.map(issue => (
              <Post
                  key={issue.id}
                  header={issue.reported_by}
                  subheader={makeDateStr(issue.created_at)}
                  buttons={issue.Resolved__c ? (
                      <div className={styles.resolvedWrapper}>
                        <div className={styles.resolvedCheck}>&#10003;</div>
                        <div className={styles.resolvedText}>Resolved</div>
                      </div>
                  ) : (<>
                        <ResolveButton id={issue.id} user={user} onIssueUpdateSuccess={onIssueUpdateSuccess}/>
                        {user.is_admin && <DeleteButton id={issue.id} onIssueDeleteSuccess={onIssueDeleteSuccess}/>}
                      </>
                  )}
                  text={issue.ReportedIssue__c}
                  email={issue.ShareContactInfo__c && issue.contact_info?.email}
                  note={issue.Notes__c}
              />
          ))
      ) : (
          <EmptyResourcePlaceholder text={placeholder} hideButton/>
      )
  )
}

export default IssueList;
