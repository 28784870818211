import {createContext,useContext} from 'react';
import {useRequest} from '@containers/Request';

const RequestStateContext = createContext();
const RequestDispatchContext = createContext();

export function useRequestState() {
  const context = useContext(RequestStateContext);
  if (context === undefined) {
    throw new Error('useRequestState must be used within a RequestProvider');
  }
  return context;
}

export function useRequestDispatch() {
  const context = useContext(RequestDispatchContext);
  if (context === undefined) {
    throw new Error('useRequestDispatch must be used within a RequestProvider');
  }
  return context;
};

export function withRequestState(WrappedComponent){
  return (props) => {
    const request = useRequestState()
    return (
      <WrappedComponent {...props} request={request} />
    )
  }
}

export function withRequestStateUpdate(WrappedComponent){
  return (props) => {
    const {updateData,makeRequest} = useRequestDispatch()
    return (
      <WrappedComponent {...props} updateData={updateData} makeRequest={makeRequest} />
    )
  }
}


export default function RequestProvider({children,url}) {
  const {state,updateData,makeRequest} = useRequest(url);
  return (
    <RequestStateContext.Provider value={state}>
      <RequestDispatchContext.Provider value={{updateData,makeRequest}}>
        {children}
      </RequestDispatchContext.Provider>
    </RequestStateContext.Provider>
  );
};
