import {Link} from 'react-router-dom';
import Page from '@components/Layouts/Page';
import Button from '@components/Button';
import SponsorList from '@components/Sponsor/List';
import {Pagination} from '@components/Pagination';
import withPageTitle from '@containers/withPageTitle';
import usePaginationRequest from '@containers/usePaginationRequest';

export const SponsorListPageComponent = ({request,...props}) => (
  <Page
    right={(
      <Button as={Link} to="new" type="button" medium primary>New Sponsor</Button>
    )}
    {...props}
  >
    <Pagination previousPage={request.previousPage} setPage={request.setPage} nextPage={request.nextPage} pageCount={request.state.data.page_count} page={request.state.data.page} status={request.state.status} error={request.state.error}>
      <SponsorList sponsors={request.state.data.app_sponsors} text="No sponsors found" />
    </Pagination>
  </Page>
)

const SponsorListPageContainer = ({url,...props}) => {
  const request = usePaginationRequest(url,25);
  return <SponsorListPageComponent {...props} request={request} />
}

const SponsorListPage = () => (
  <SponsorListPageContainer
    title="Sponsors"
    url="/app_sponsors.json"
  />
)

export default withPageTitle(SponsorListPage, "Sponsors");
