import {Link} from 'react-router-dom';
import Button from '@components/Button';
import styles from './index.module.css';
import logo from '@assets/images/LFL_RGB_HorizonalTaglineLogo_Gray@3x.png';

const EmptyResourcePlaceholder = ({text,as,to, form = true,buttonText,subtext,hideButton,...props}) => (
  <div className={styles.container}>
    <img src={logo} className={styles.logo} />
    <div className={styles.text}>{text}</div>
    {subtext && <div className={styles.subtext}>{subtext}</div>}
    {!hideButton && <Button {...props} form={form} as={to ? Link : undefined} to={to}>{buttonText ? buttonText : "Add One"}</Button>}
  </div>
)

export default EmptyResourcePlaceholder;
