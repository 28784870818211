import axios from 'axios';
import handleError from '@utils/api/error';
import LocalStorageService from '@utils/storage';

export const makeRequest = (method, url, data) => axios({
  url: `${process.env.REACT_APP_API_URL}${url}`,
  method,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  data
}).then(
  (response) => response?.data,
  handleError
)

export const deleteRequest = (url) => makeRequest("DELETE", url)
export const getRequest = (url, action = "GET", body) => makeRequest(action, url, body)

export const makeMultiPartRequest = (method, url, data) => axios({
  url: `${process.env.REACT_APP_API_URL}${url}`,
  method,
  headers: {
    'Content-Type': 'multipart/form-data',
    'Accept': 'application/json'
  },
  data
}).then(
  (response) => response?.data,
  handleError
)



const localStorageService = LocalStorageService.getService();

axios.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  if (error.response?.status === 401 && !originalRequest._retry) {
    if (!originalRequest.retry) {
      originalRequest._retry = true;
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/oauth/token.json`, {
        refresh_token: localStorageService.getRefreshToken(),
        grant_type: "refresh_token"
      })
      if (res.status >= 200 && res.status < 300) {
        localStorageService.setToken(res.data);
        originalRequest.headers.Authorization = `Bearer ${res.data.access_token}`
        return axios(originalRequest);
      }
    }
  }
  return Promise.reject(error);
});
