import { makeRequest } from '@utils/api';

export const createChallenge = (challenge) => makeRequest("POST", "/challenges.json", {
  challenge
})
const base64regex = /data:image\/([a-zA-Z]*);base64,([^\"]*)/g

const makeAppChallenge = ({ id, ImageUrl__c, url, image, ...challenge }) => {
  const obj = { ...challenge };
  if (base64regex.test(image)) {
    obj.image = image
  }
  return obj
}
export const updateChallenge = (challenge, url) => makeRequest("PATCH", url, {
  challenge: makeAppChallenge(challenge)
})
