import DonationMessagesFormPage from '@components/DonationMessages/Form';
import Request from '@containers/Request';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import { updateDonationMessages } from '@utils/api/donationMessages';

const DonationMessagesUpdatePage = withParams(({ params: { id } }) => {
  const url = `/donation_messages/${id}.json`;
  return (
    <Request url={url}>
      {(donation_message, makeRequest, updateData) => (
        <DonationMessagesFormPage
          form={{
            url,
            onSubmit: updateDonationMessages,
            defaultValues: donation_message,
            toast: "Donation messages updated"
          }}
          page={{
            title: donation_message.title,
            back: true
          }}
        />
      )}
    </Request>
  )
})

export default withPageTitle(DonationMessagesUpdatePage, "Update Donation Messages");
