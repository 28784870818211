import {createContext,useContext} from 'react';
import {useRequest} from '@containers/Request';

const AppInfoStateContext = createContext();
function AppInfoProvider({children}) {
  const {state} = useRequest("/app_info");
  return (
    <AppInfoStateContext.Provider value={state.data}>
      {children}
    </AppInfoStateContext.Provider>
  );
};

function useAppInfoState() {
  const context = useContext(AppInfoStateContext);
  if (context === undefined) {
    throw new Error('useAppInfoState must be used within a AppInfoProvider');
  }
  return context;
}


export {AppInfoProvider, useAppInfoState};
