import {forwardRef} from 'react';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import Table,{Column} from 'react-virtualized/dist/commonjs/Table';
import {RequestLoader} from '@components/Loader';
import {classNames} from '@utils';
import styles from './index.module.css';
import 'react-virtualized/styles.css';

const TableContainer = forwardRef(({
  rows = [],
  rowCount = rows.length,
  onClick,
  isRowLoaded,
  rowClassName,
  rowRenderer = ({className, index, key, rowData, style,columns}) => {
    const rowLoaded = isRowLoaded({index})
    return rowLoaded ? (
      <div onClick={() => {onClick(rowData)}} className={classNames(className,rowClassName)} key={key} style={style}>
        {columns}
      </div>
    ) : (
      <div key={key} style={style} className={styles.center} ><RequestLoader className={styles.loader} /></div>
    )
  },
  headerRowRenderer = ({className,style,columns}) => (
    <div className={classNames(className,styles.header)} style={style}>
      {columns}
    </div>
  ),
  className,
  emptyText,
  rowHeight = 70,
  headerHeight = 50,
  cols,
  ...props
},ref) => (
  <AutoSizer>
    {({ width,height }) => (
      <Table
        className={className}
        headerHeight={headerHeight}
        noRowsRenderer={() => <div>{emptyText}</div>}
        rowHeight={rowHeight}
        rowGetter={({index}) => rows[index]}
        rowCount={rowCount}
        rowRenderer={rowRenderer}
        headerRowRenderer={headerRowRenderer}
        gridClassName={styles.table}
        ref={ref}
        {...props}
        width={width}
        height={height}>
        {cols.map((col) => (
          <Column
            {...col}
            className="td"
            headerClassName="th"
            dataKey={col.key}
            width={width}
          />
        ))}
      </Table>
    )}
  </AutoSizer>
))

export default TableContainer;
