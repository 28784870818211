import {useState} from 'react';
import Label from '@components/Form/Label';
import Content from '@components/Layouts/Content';
import CommentSection from '@components/Comment/Section';
import EmptyResourcePlaceholder from '@components/EmptyResourcePlaceholder';
import {SelectComponent} from '@components/Form/Fields/Select';
import RequestStatus from '@components/RequestStatus';
import SwitchRequest from '@containers/SwitchRequest';
import {withRequestState,withRequestStateUpdate} from '@containers/RequestContext';
import {updateLibrary} from '@utils/api/library';
import styles from './index.module.scss';

const handleCommentSwitch = (id) => (CommentsActive__c) => new Promise(
  async (resolve,reject) => {
    try {
      resolve(await updateLibrary({CommentsActive__c},`/libraries/${id}.json`))
    } catch (e) {
      reject(e)
    }
  }
)

const GuestBookPageComponent = ({comments,filter,...props}) => {
  if(filter === "all"){
    const pending = comments.filter(({Approval__c}) => Approval__c === "Pending"),
          accepted = comments.filter(({Approval__c}) => Approval__c === "Approved"),
          declined = comments.filter(({Approval__c}) => Approval__c === "Declined")

    return (pending.length === 0 && accepted.length === 0 && declined.length === 0) ? (
      <EmptyResourcePlaceholder text="No one has left a comment yet" hideButton />
    ) : (<>
      {pending.length > 0 && <CommentSection {...props} title="Pending" comments={pending} />}
      {accepted.length > 0 && <CommentSection {...props} title="Approved" comments={accepted} />}
      {declined.length > 0 && <CommentSection {...props} title="Declined" comments={declined} />}
    </>)
  }
  return <CommentSection {...props} title={filter} comments={comments.filter(({Approval__c}) => Approval__c === filter)} placeholder={`There are no ${filter.toLowerCase()} comments`} />
}

const filterOptions = [{value: "all", name: "All"},{value: "Pending", name: "Pending"},{value: "Approved", name: "Approved"},{value: "Declined", name: "Declined"}]



const GuestBookPage = withRequestState(withRequestStateUpdate(({request,makeRequest,updateData}) => {
  const [filter,setFilter] = useState(filterOptions[0].value),
        handleApprovalUpdateSuccess = (id,Approval__c) => {
          updateData({
            ...request.data,
            library_comments: request.data.library_comments.map(comment => (
              comment.id === id ? (
                Object.assign({},comment,{Approval__c})
              ) : (
                comment
              )
            ))
          })
        }
  return(
    <RequestStatus status={request.status} error={request.error}>
    <Content
      title={(
        <div className={styles.flex}>

          <div className={styles.title}>
            <div>Guest Book</div>
            <SelectComponent value={filter} options={filterOptions} onChange={(e) => {setFilter(e.target.value)}} />
          </div>
          <label className={styles.switch}>
            <Label className={styles.switchLabel}>Allow Guest Book Entries</Label>
            <SwitchRequest onSuccess={() => {makeRequest()}} value={request.data?.CommentsActive__c} makeRequest={handleCommentSwitch(request.data?.id)} successToast="Library guest book updated" errorToast="Unable to update library guest book" label="Allow Guest Book Comments" />
          </label>
        </div>
      )}
      noPadding
    >
    <div className={styles.subtitle}>Guest book entries can be approved or denied but cannot be edited.</div>

        <GuestBookPageComponent filter={filter} comments={request.data?.library_comments} onSuccess={handleApprovalUpdateSuccess} />
    </Content>
  </RequestStatus>

  )
}))


export default GuestBookPage;
