
import styles from "./index.module.css";

const Counts = ({ check_in_users, comment_users, new_library_announcement_users, route_users }) => {

	return (
		<div className={styles.row}>
			<div className={styles.column}>
				<div className={styles.card}>
					<h2>{check_in_users}</h2>
					<p>Check In Users</p>
				</div>
			</div>

			<div className={styles.column}>
				<div className={styles.card}>
					<h2>{comment_users}</h2>
					<p>Comment Users</p>
				</div>
			</div>

			<div className={styles.column}>
				<div className={styles.card}>
					<h2>{new_library_announcement_users}</h2>
					<p>New Library Announcement Users</p>
				</div>
			</div>

			<div className={styles.column}>
				<div className={styles.card}>
					<h2>{route_users}</h2>
					<p>Route Users</p>
				</div>
			</div>

		</div>
	)
}

export default Counts;
