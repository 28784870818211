import { makeRequest, makeMultiPartRequest } from "@utils/api";
import axios from "axios";

export const createIndicator = (app_flag) =>
  makeRequest("POST", "/app_flags.json", {
    app_flag: makeAppIndictor(app_flag),
  });
const base64regex = /data:image\/([a-zA-Z]*);base64,([^\"]*)/g;

const makeAppIndictor = ({ id, ImageUrl__c, url, image, ...app_flag }) => {
  const obj = { ...app_flag };
  if (base64regex.test(image)) {
    obj.image = image;
  }
  return obj;
};
export const updateIndicator = (app_flag, url) =>
  makeRequest("PATCH", url, {
    app_flag: makeAppIndictor(app_flag),
  });

export const uploadIndicatorChartNumber = (app_flag, charter_csv) =>
  makeMultiPartRequest("POST", `/app_flags/${app_flag.id}/import.json`, charter_csv);

export const clearCharters = (app_flag) => makeRequest("POST", `/app_flags/${app_flag.id}/clear.json`);
