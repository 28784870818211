import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './index.module.css';
import LinkText from '@components/LinkText';
import LogoutButton from '@containers/LogoutButton';
import icon from '@assets/images/LFL_RGB_HorizonalTaglineLogo_White@3x.png';
import googlePlay from '@assets/images/google-play-badge-300x89.png';
import appStore from '@assets/images/download-on-the-app-store.png';

const GetAppPage = ({ logout }) => {
  useEffect(() => {
    if (['iPhone', 'iPad', 'iPod'].indexOf(window.navigator.platform) !== -1) {		// window.location = "com.littlefreelibrary.LFL://get-app"
    }
    if (/Android/.test(window.navigator.userAgent)) {
      const url =
        "intent://littlefreelibrary.org/#Intent;scheme=https;package=org.littlefreelibrary.littlefreelibrary;end";
      window.location.replace(url);
    }
  }, [])
  return (
    <div className={styles.container}>
      <Link to="/"><img src={icon} alt="Little Free Library Small Logo" className={styles.logo} /></Link>
      <div className={styles.text}>
        {logout ? (
          "You do not have a steward account with this email address. You may log in to your standard user account through the mobile app."
        ) : (
          "Your account has been confirmed, log in with the Little Free Library mobile app to continue"
        )}
      </div>
      <div className={styles.badgeWrapper}>
        <a href="https://play.google.com/store/apps/details?id=org.littlefreelibrary.littlefreelibrary"><img src={googlePlay} width="150" height="60" alt="Get it on Google Play" border="0" /></a>
        <a href="https://apps.apple.com/us/app/little-free-library/id1555760218"><img src={appStore} width="150" height="60" alt="Get it in the AppStore" border="0" /></a>
      </div>
      {logout && <LinkText className={styles.margin}><LogoutButton /></LinkText>}
    </div>
  )
}

export default GetAppPage;
