import {useState} from 'react';
import {Link} from 'react-router-dom';
import Table from '@components/Table';
import ModalMenu from '@components/ModalMenu';
import Form from '@components/Form';
import Input from '@components/Form/Fields/Input';
import {Modal,ModalText,ModalButtons} from '@components/Modal';
import RequestButton from '@containers/RequestButton';
import {makeLibraryName,getFormattedDate} from '@utils';
import {createLibraryTransferEmail,updateLibraryTransfer,cancelLibraryTransfer} from '@utils/api/library_transfer';
import {libraryTransferSchema} from '@utils/validation/library_transfer';
import styles from './index.module.scss';

const handleTransferEmailButtonClick = (libraryId,libraryTransferId) => () => new Promise(
  async (resolve,reject) => {
    try {
      resolve(await createLibraryTransferEmail(libraryId,libraryTransferId))
    } catch (e) {
      reject(e)
    }
  }
)
const handleTransferCancelButtonClick = (libraryId,libraryTransferId) => () => new Promise(
  async (resolve,reject) => {
    try {
      resolve(await cancelLibraryTransfer(libraryId,libraryTransferId))
    } catch (e) {
      reject(e)
    }
  }
)

const makeDefaultPermissionsValues = ({can_check_in,can_comment,can_report}) => ({
  can_check_in,
  can_comment,
  can_report
})
const ModalForm = ({close,title,children,...props}) => (
  <Modal close={close}>
    <ModalText>{title}</ModalText>
    <Form {...props}>
      {children}
      <ModalButtons onCancelClick={close} continueProps={{type: "submit",medium: true}} cancelProps={{type: "button",medium: true,secondary: true}} continueText="Save" />
    </Form>
  </Modal>
)
const makeDefaultTransferValues = ({steward_email,steward_last_name,steward_first_name}) => ({
  steward_first_name,
  steward_last_name,
  steward_email
})
const EditButton = ({onSuccess,buttonText,...props}) => {
  const [isOpen,setIsOpen] = useState(),
        close = () => {setIsOpen()},
        handleSuccess = (response) => {
          onSuccess(response);
          close();
        }
  return(<>
    <div onClick={() => {setIsOpen(true)}}>{buttonText}</div>
    {isOpen && (
      <ModalForm
        {...props}
        close={close}
        onSuccess={handleSuccess}
      />
    )}
  </>)
}

export const LibraryTransferRow = ({transfer,makeRequest,onUpdate,onDelete}) => (<>
  <div><Link to={`libraries/${transfer.library.id}/edit`} className={styles.link}>{makeLibraryName(transfer.library)}</Link></div>
  <div><Link to={`users/${transfer.current_steward.id}`} className={styles.link}>{`${transfer.current_steward?.first_name} ${transfer.current_steward?.last_name}`}</Link></div>
  <div>{`${transfer.steward_first_name} ${transfer.steward_last_name}`}</div>
  <div><a href={`mailto:${transfer.steward_email}`} className={styles.link}>{transfer.steward_email}</a></div>
  <div>{transfer.complete ? "Complete" : transfer.expired ? "Expired" : "Incomplete"}</div>
  <div>{transfer.created_at && getFormattedDate(transfer.created_at)}</div>
  <div>
    <ModalMenu title="Library Transfer">
      {setModal => {
        const handleSuccess = ({response}) => {
                onUpdate(response);
                setModal(false);
              },
              handleCancel = () => {
                onDelete(transfer.id);
                setModal(false);
              };
        return(<>
          <EditButton
            buttonText="Edit Steward Information"
            title="Steward Information"
            defaultValues={makeDefaultTransferValues(transfer)}
            validationSchema={libraryTransferSchema}
            url={`/libraries/${transfer.library.id}/library_transfers/${transfer.id}.json`}
            onSubmit={updateLibraryTransfer}
            toast="Library transfer updated"
            to={false}
            onSuccess={handleSuccess}>
            <Input secondary name="steward_first_name" label="Steward First Name" required />
            <Input secondary name="steward_last_name" label="Steward Last Name" required />
            <Input secondary name="steward_email" type="email" label="Steward Email" required />
          </EditButton>
          {!transfer.complete && !transfer.expired && <RequestButton button="div" toast="Transfer email has been sent" makeRequest={handleTransferEmailButtonClick(transfer.library.id,transfer.id)}>Resend Transfer Email</RequestButton>}
          {!transfer.complete && <RequestButton button="div" toast="Transfer has been cancelled" makeRequest={handleTransferCancelButtonClick(transfer.library.id,transfer.id)} onSuccess={handleCancel}>Cancel Transfer</RequestButton>}
        </>)
      }}
    </ModalMenu>
  </div>
</>)

const defaultCols = ["Library","Initiated By","New Steward", "New Steward Email","Status","Date Created",""]
const LibraryTransferListPage = ({libraryTransfers,makeRequest, text = "No library transfers found",cols = defaultCols}) => (
  <Table
    cols={cols}
    rows={libraryTransfers}
    renderRow={(transfer) => <LibraryTransferRow transfer={transfer} makeRequest={makeRequest} />}
    noLink
    placeholder={{to: "new", text,hideButton: true}}
  />
)

export default LibraryTransferListPage;
