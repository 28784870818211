import {useState,useEffect } from 'react';
import {createPortal} from 'react-dom';
import Button from '@components/Button';
import styles from './index.module.css';
import {classNames} from '@utils';
import closeIcon from '@assets/images/close-24px.svg';

const modalRoot = document.getElementById("modal");
export function Modal({close,className,children,closeOnOverlayClick}){
  const [isShowing,setIsShowing] = useState();
  const el = document.createElement("div");
  useEffect(() => setIsShowing(true), []);
  useEffect(() => {
    modalRoot.appendChild(el);
    return () => {
      modalRoot.removeChild(el);
    };
  }, [el]);
  return createPortal(
    <div onClick={closeOnOverlayClick ? close : undefined} className={classNames(styles.container,isShowing && styles.show)}>
      <div onClick={(e) => {e.stopPropagation()}} className={classNames(styles.content,isShowing && styles.show,className)}>
        {close && <img alt="Close" onClick={close} src={closeIcon} className={styles.closeIcon + " selectable"}  />}
        {children}
      </div>
    </div>,
    el
  )
}

export const ModalText = ({children,className}) => <div className={classNames(styles.text,className)}>{children}</div>
export const ModalButtons = ({onCancelClick,onContinueClick,cancelText = "Cancel",continueText = "Continue",continueProps = {type: "button",medium: true},cancelProps = {type: "button",secondary: true,medium: true}}) => (
  <div className={styles.buttonRow}>
    <Button {...cancelProps} onClick={onCancelClick}>{cancelText}</Button>
    <Button {...continueProps} onClick={onContinueClick}>{continueText}</Button>
  </div>
)

export default function ModalComponent({text,onClick,cancel,children,buttonText,...props}){
  return(
    <Modal {...props}>
      <ModalText>{text}</ModalText>
      {children ? (
        children
      ) : (
        cancel ? (
          <ModalButtons onCancelClick={cancel} onContinueClick={onClick} continueText={buttonText} />
        ) : (
          <Button medium type="button" onClick={onClick}>OK</Button>
        )
      )}
    </Modal>
  )
}
