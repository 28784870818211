import {useState,useEffect} from 'react';
import logo from '@assets/images/LFL_RGB_HorizonalTaglineLogo_White@3x.png';
import styles from './index.module.css';
import { classNames } from '@utils';

export const useProgressiveImg = (highQualitySrc, lowQualitySrc = logo) => {
  const [src, setSrc] = useState(lowQualitySrc);
  useEffect(() => {
    setSrc(lowQualitySrc);
    const img = new Image();
    img.src = highQualitySrc;
    img.onload = () => {
      setSrc(highQualitySrc);
    };
  }, [lowQualitySrc, highQualitySrc]);
  return src;
}

export default function Photo({src,className,...props}){
  const img = useProgressiveImg(src)
  return(
    <img
      {...props}
      className={classNames(styles.image,className)}
      src={img}
    />
  )
}
