import React from "react";
import Switch from "@components/Form/Fields/Switch";
import { useFieldArray, useWatch } from "react-hook-form";
import styles from "./index.module.css";

function AudienceField({ name, label, defaultValue=[]}) {
  const possible_values = ["Stewards", "Patrons", "Anonymous"];

  const { replace } = useFieldArray({
    name,
  });
  const values = useWatch({
    name,
    defaultValue,
  });
  
  const handleChange = (audience) => {
    replace(
      values.includes(audience)
        ? values.filter((a) => a !== audience)
        : [...values, audience]
    );
  };
  
  return (
    <div>
      <p class={styles.label}>{label}</p>
      {possible_values.map((audience) => {
        return (
          <Switch
            name={audience.toLowerCase()}
            label={audience}
            value={values && values.includes(audience)}
            onClick={() => handleChange(audience)}
          />
        );
      })}
    </div>
  );
}

export default AudienceField;
