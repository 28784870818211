import Select from '@components/Form/Fields/Select';
import useAccessorFunction from '@containers/useAccessorFunction'

const ResourceSelect = ({as: Component = Select,resource,labelAccessor = "name", valueAccessor = "id",disabledAccessor = "disabled",data,...props}) => {
  const jsonFunc = useAccessorFunction(resource),
        labelFunc = useAccessorFunction(labelAccessor),
        valueFunc = useAccessorFunction(valueAccessor),
        disabledFunc = useAccessorFunction(disabledAccessor),
        json = jsonFunc(data);
  return(
    <Component {...props} options={json ? json.map(s => ({value: valueFunc(s), name: labelFunc(s), disabled: disabledFunc(s)})) : []} />
  )
}

export default ResourceSelect;
