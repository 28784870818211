import {useEffect,useState,useRef} from 'react';
import {Switch} from '@components/Form/Fields/Switch';
import {useToastAddAction} from '@containers/ToastContext';
import {classNames} from '@utils';

export default function SwitchRequest({value,makeRequest,successToast,onSuccess = () => {},errorToast,...props}){
  const addToast = useToastAddAction(),
        isMounted = useRef(false),
        [isActive,setIsActive] = useState(value),
        handleRequest = useRef();

  useEffect(() => {
    handleRequest.current = async () => {
      try {
        await makeRequest(isActive)
        addToast(successToast)
        onSuccess()
      } catch (e) {
        addToast(errorToast)
        setIsActive(prev => !prev)
      }
    }
  },[isActive,setIsActive,addToast,handleRequest])
  useEffect(() => {
    if (isMounted.current) {
       handleRequest.current()
    } else {
      isMounted.current = true;
    }
  },[isMounted,handleRequest,isActive])
  return <Switch {...props} onChange={(e) => {setIsActive(e);}} value={isActive} />

}
