import Page from '@components/Layouts/Page';
import Content from '@components/Layouts/Content';
import Value from '@components/Value';
import Request from '@containers/Request';
import ConfirmationResendButton from '@containers/ConfirmationResendButton';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {makeRoleString} from '@utils'

const UserPage = withParams(({params:{id}}) => (
  <Request url={`/users/${id}.json`}>
    {(response) => (
      <Page
        title="User"
        back="Back To Users"
        right={!response.email_confirmed && <ConfirmationResendButton email={response.email} />}
      >
        <Content
          title="User Information"
          left={<>
            <Value value={`${response.first_name} ${response.last_name}`} label="Name" />
            <Value value={response} format={makeRoleString} label="Role" />
            <Value value={response.email} label="Email" />
          </>}
          right={<>
            <Value value={response.email_confirmed ? "Yes" : "No"} label="Confirmed" />
          </>}
        />
      </Page>
    )}
  </Request>
))

export default withPageTitle(UserPage, "User Details");
