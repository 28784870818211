import Page from "@components/Layouts/Page";
import Content from "@components/Layouts/Content";
import Form from "@components/Form";
import Switch from "@components/Form/Fields/Switch";
import InputComponent from "@components/Form/Fields/Input";
import Button from "@components/Button";
import { donationMessagesSchema } from "@utils/validation/donationMessages";
import styles from "./index.module.css";

const DonationMessagesFormPage = ({ form, page }) => (
  <Form validationSchema={donationMessagesSchema} {...form}>
    <Page
      {...page}
      right={
        <Button medium type="submit">
          Save
        </Button>
      }
    >
      <Content title="Donation Messages Information">
        <div className={styles.content}>
          <InputComponent
            name="title"
            placeholder="Enter Title"
            label="Title"
          />
          <InputComponent
            as="textarea"
            name="body"
            placeholder="Enter Body"
            label="Body"
          />
          <InputComponent
            name="link"
            placeholder="Enter Link Url"
            label="Link Url"
          />
          <InputComponent
            name="button_text"
            placeholder="Enter Button Text"
            label="Button Text"
          />
          <Switch label="Active" name="active" />

          <InputComponent
            name="first_count"
            placeholder="Enter First Count"
            label="First Count"
          />
          <InputComponent
            name="recurring_count"
            placeholder="Enter Recurring Count"
            label="Recurring Count"
          />
        </div>
      </Content>
    </Page>
  </Form>
);

export default DonationMessagesFormPage;
