import { Link, useLocation } from "react-router-dom";
import Page from "@components/Layouts/Page";
// import AnalyticList from '@components/Analytic/List';
import Button from "@components/Button";
import Request from "@containers/Request";
import withPageTitle from "@containers/withPageTitle";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import Chart from "../Charts";
import Counts from "../Counts";
import styles from "./index.module.css";

export const AnalyticListPageComponent = ({ url, ...props }) => {
  const defaultDateValues = {
    dateFrom: new Date(),
    dateTill: new Date(),
  };
  const [filterDates, setFilterDates] = useState(defaultDateValues);
  const [analyticsUrl, setAnalyticsUrl] = useState(url);
  const [queryData, setQueryData] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const { search } = useLocation();
  const isStaticTimeline = search === "?static-timelines";


  const handleOnChangeRaw = (e) => {
    if (e.type === "click" || e?.target?.value?.length > 0) {
      setRefetch(true);
    }
  };

  useEffect(() => {
    if (!!queryData) {

      const { date_start, date_end } = queryData;
      console.log(date_start, date_end);
      const createDateFromAPIRes = (d) => d ? new Date(d?.replace(/-/g, "/").replace(/T.+/, "")) : new Date();
      setFilterDates({
        dateFrom: createDateFromAPIRes(date_start),
        dateTill: createDateFromAPIRes(date_end),
      });
    }
  }, [queryData]);

  useEffect(() => {
    if (refetch) {
      const formatDate = (date) => format(date, "yyyy-MM-dd");
      const date_start = formatDate(filterDates["dateFrom"]);
      const date_end = formatDate(filterDates["dateTill"]);
      setAnalyticsUrl(`${url}?date_start=${date_start}&date_end=${date_end}`);
      setRefetch(false);
    }
  }, [refetch]);

  return (
    <Page
      // right={<Button as={Link} to="new">Create New Analytic</Button>}
      right={
        <div className={styles.tabs}>
          <Button
            as={Link}
            to="?static-timelines"
            secondary={!isStaticTimeline}
          >
            Static Timelines
          </Button>
          <Button as={Link} to="/analytics" secondary={isStaticTimeline}>
            Analytics
          </Button>
        </div>
      }
      left={
        !isStaticTimeline && (
          <>
            <div className={styles.headerItems}>
              <span className={styles.boldText}>Start Date:</span>
              <div className={styles.dateWidth}>
                <DatePicker
                  selected={filterDates["dateFrom"]}
                  dateFormat="MM/dd/yyyy"
                  onChangeRaw={handleOnChangeRaw}
                  onChange={(date) =>
                    setFilterDates({ ...filterDates, dateFrom: date })
                  }
                />
              </div>
            </div>
            <div className={styles.headerItems}>
              <span className={styles.boldText}>End Date:</span>
              <div className={styles.dateWidth}>
                <DatePicker
                  selected={filterDates["dateTill"]}
                  dateFormat="MM/dd/yyyy"
                  onChangeRaw={handleOnChangeRaw}
                  onChange={(date) =>
                    setFilterDates({ ...filterDates, dateTill: date })
                  }
                />
              </div>
            </div>
          </>
        )
      }
      {...props}
    >
      {!isStaticTimeline ? (
        <Request url={analyticsUrl}>
          {(data) => {
            if (data !== queryData) setQueryData(data);
            const {
              check_in_users,
              comment_users,
              new_library_announcement_users,
              route_users,
              daily_devices,
              donation_displays,
            } = data;

            return (
              <div>
                <Counts
                  check_in_users={check_in_users}
                  comment_users={comment_users}
                  new_library_announcement_users={new_library_announcement_users}
                  route_users={route_users}
                />
                <div className={styles.chart}>
                  <Chart
                    context={"Daily Devices"}
                    count={0}
                    types={daily_devices}
                  />
                </div>
                <div className={styles.chart}>
                  <Chart
                    context={"Message Displays"}
                    count={0}
                    types={donation_displays}
                  />
                </div>
              </div>
            );
          }}
        </Request>
      )
        :
        <Request url={'/analytics/static.json'}>
          {(data) => {
            if (data !== queryData) setQueryData(data);
            const {
              active_guestbooks_by_month,
              check_in_users_by_month,
              check_ins_by_month,
              devices_by_month,
              latency_by_day,
              libraries_by_indicator,
              library_issues_by_month,
              new_users_by_month,
              users_by_month,
              user_count
            } = data;

            return (
              <div>
                <div className={styles.chart}>
                  <Chart
                    context={"New Users By Month"}
                    count={0}
                    types={new_users_by_month}
                  />
                </div>
                <div className={styles.chart}>
                  <Chart
                    context={"Users By Month"}
                    count={0}
                    types={users_by_month}
                  />
                </div>
                <div className={styles.chart}>
                  <Chart
                    context={"Check In Users By Month"}
                    count={0}
                    types={check_in_users_by_month}
                    isDataLabelEnabled={false}
                  />
                </div>


                <div className={styles.chart}>
                  <Chart
                    context={"Active Guestbooks By Month"}
                    count={0}
                    types={active_guestbooks_by_month}
                    isDataLabelEnabled={false}
                  />
                </div>
                <div className={styles.chart}>
                  <Chart
                    context={"Check Ins By Month"}
                    count={0}
                    types={check_ins_by_month}
                  />
                </div>
                <div className={styles.chart}>
                  <Chart
                    context={"Libraries By Indicator"}
                    count={0}
                    types={libraries_by_indicator}
                    isDataLabelEnabled={false}
                  />
                </div>
                <div className={styles.chart}>
                  <Chart
                    context={"Library Issues By Month"}
                    count={0}
                    types={library_issues_by_month}
                  />
                </div>
                <div className={styles.chart}>
                  <Chart
                    context={"Latency By Day"}
                    count={0}
                    types={latency_by_day}
                  />
                </div>
              </div>
            );
          }}
        </Request>
      }
    </Page>
  );
};

const AnalyticListPage = () => (
  <AnalyticListPageComponent title="Analytics" url="/analytics/range.json" />
);

export default withPageTitle(AnalyticListPage, "Analytics");
