import {createContext,useContext,useState} from 'react';
import Modal from '@components/Modal';

const ErrorModalDispatchContext = createContext();

function ErrorModalProvider({children}) {
  const [error,setError] = useState();
  return (
      <ErrorModalDispatchContext.Provider value={setError}>
        {children}
        {error ? <Modal text={error} onClick={() => {setError()}} /> : null}
      </ErrorModalDispatchContext.Provider>
  );
};

function useErrorModalDispatch() {
  const context = useContext(ErrorModalDispatchContext);
  if (context === undefined) {
    throw new Error('useErrorModalDispatch must be used within a ErrorModalProvider');
  }
  return context;
};


export {ErrorModalProvider, useErrorModalDispatch};
