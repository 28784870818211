import {useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import {InfiniteLoaderContainer} from '@components/Virtualized/InfiniteTable';
import Form from '@components/Form';
import Button from '@components/Button';
import Input from '@components/Form/Fields/Input';
import PhoneNumberInput from '@components/Form/Fields/PhoneNumberInput';
import SearchFilter from '@components/SearchFilter';
import {LibraryFilters,useLibraryFilters} from '@components/Library/ListPage';
import {Modal,ModalText,ModalButtons} from '@components/Modal';
import usePaginationRequest from '@containers/usePaginationRequest';
import {withUser} from '@containers/Auth';
import {createLibraryTransfer} from '@utils/api/library_transfer';
import {libraryTransferSchema} from '@utils/validation/library_transfer';
import {makeLibraryName,classNames,makeAddress} from '@utils';
import styles from './index.module.scss';

const libraryCols = [
  {label: "Name", cellDataGetter: (cell) => cell?.rowData ? makeLibraryName(cell.rowData) : "", key: "name", disableSort: true},
  {label: "Address",cellRenderer: (cell) => cell?.rowData ? (<>
    <div>{cell.rowData.Street__c}</div>
    <div>{makeAddress(cell.rowData)}</div>  </>) : "", cellDataGetter: (cell) => cell?.rowData ? cell.rowData.Street__c : "", key: "users.last_name"},
  {label: "Charter", cellDataGetter: (cell) => cell?.rowData ? cell.rowData.Official_Charter_Number__c : "", key: "users.first_name"},
]
const LibraryList = withUser(({user,...props}) => {
  const request = usePaginationRequest("/libraries.json",50,user.is_admin && {is_admin: true}),
        filters = useLibraryFilters(request);
  return(<>
    <LibraryFilters className={classNames(styles.width,styles.subheader)} {...filters} />
    <div className={styles.grow}>
      <InfiniteLoaderContainer
        {...props}
        {...request}
        rowClassName={styles.library}
        listAccessor={(r) => r?.libraries}
        cols={libraryCols}
      />
    </div>
  </>)
})

export const TransferFields = ({secondary}) => (<>
  <Input secondary={secondary} name="steward_first_name" label="New Steward First Name" required />
  <Input secondary={secondary} name="steward_last_name" label="New Steward Last Name" required />
  <Input secondary={secondary} name="steward_email" type="email" label="New Steward Email" required />
  <PhoneNumberInput secondary={secondary} name="steward_phone" label="New Steward Phone Number" required />
</>)

export const TransferForm = ({children,libraryId,toast = "Library transfer initiated",to = false,...props}) => (
  <Form
    {...props}
    url={`/libraries/${libraryId}/library_transfers.json`}
    validationSchema={libraryTransferSchema}
    onSubmit={createLibraryTransfer}
    toast={toast}
    to={to}
  >
    {children}
  </Form>
)
const ModalSwitch = ({close}) => {
  const [library,setLibrary] = useState(),
        handleSuccess = () => {
          close();
        };
  return(
    <Modal close={close} className={classNames(styles.modal, library ? styles.formModal : styles.listModal)}>
      {library ? (<>
        <ModalText>Transfer Information</ModalText>
        <div className={styles.subtext}>
          Enter new steward information to initiate a transfer for
          <br />
          <Link to={`/libraries/${library.id}`} target="_blank">{makeLibraryName(library)}</Link>
          <br />
          or <button onClick={() => {setLibrary()}} type="button">choose a new library</button>
        </div>
      <TransferForm
          libraryId={library.id}
          onSuccess={handleSuccess}
          className={styles.width}
        >
          <TransferFields secondary />
          <div className={styles.buttons}>
            <Button type="submit">Transfer</Button>
          </div>
        </TransferForm>
      </>) : (<>
        <ModalText className={classNames(styles.left,styles.width)}>Choose the library you want to transfer</ModalText>
        <LibraryList setLibrary={setLibrary} onClick={(lib) => {setLibrary(lib)}} />
      </>)}
    </Modal>
  )
}
const CreateTransferButton = ({onSuccess,buttonText,...props}) => {
  const [isOpen,setIsOpen] = useState(),
        close = () => {setIsOpen()},
        handleSuccess = (response) => {
          onSuccess();
          close();
        }
  return(<>
    <Button type="button" medium primary onClick={() => {setIsOpen(true)}}>New Transfer</Button>
    {isOpen && (
      <ModalSwitch
        {...props}
        close={close}
        onSuccess={handleSuccess}
      />
    )}
  </>)
}

export default CreateTransferButton
