import styles from './index.module.css';
import {classNames} from '@utils';

const SplitRow = ({children,className,...props}) => (
  <div {...props} className={classNames(styles.container, className)}>
    {children}
  </div>
)

export default SplitRow;
