import { Link } from 'react-router-dom';
import Page from '@components/Layouts/Page';
import Button from '@components/Button';
import DonationMessagesList from '@components/DonationMessages/List';
import { Pagination } from '@components/Pagination';
import withPageTitle from '@containers/withPageTitle';
import usePaginationRequest from '@containers/usePaginationRequest';

export const DonationMessagesListPageComponent = ({ request, ...props }) => (
  <Page
    right={(
      <Button as={Link} to="new" type="button" medium primary>New donation message</Button>
    )}
    {...props}
  >
    <Pagination previousPage={request.previousPage} setPage={request.setPage} nextPage={request.nextPage} pageCount={request.state.data.page_count} page={request.state.data.page} status={request.state.status} error={request.state.error}>
      <DonationMessagesList donationMessages={request.state.data.donation_messages} text="No donation messages found" />
    </Pagination>
  </Page>
)

const DonationMessagesListPageContainer = ({ url, ...props }) => {
  const request = usePaginationRequest(url, 25);
  return <DonationMessagesListPageComponent {...props} request={request} />
}

const DonationMessagesListPage = () => (
  <DonationMessagesListPageContainer
    title="Donation Messages"
    url="/donation_messages.json"
  />
)

export default withPageTitle(DonationMessagesListPage, "Donation Messages");
