import withField from '@components/Form/Fields/withField';
import {Input} from '@components/Form/Fields/Input';
import {classNames} from '@utils';
import styles from './index.module.css';

const Radio = withField(({options,className,...props}) => options.map(({value,label}) => (
  <div key={value} className={classNames(styles.container,className)}>
    <Input type="radio" {...props} value={value} className={styles.input} />
    <div className={styles.label}>{label}</div>
  </div>
)),undefined,styles.wrapper)

export default Radio;
