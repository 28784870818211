import Table from '@components/Table';
import Photo from '@components/Photos/Item';
import styles from './index.module.css';

const CharterNumberListPage = ({sponsors, text = "No sponsors found"}) => (
  <Table
    cols={["","Name","Active"]}
    rows={sponsors}
    renderRow={(sponsor) => (<>
      <div className={styles.imageWrapper}>
        <Photo src={sponsor.image_url} className={styles.image} />
      </div>
      <div>{sponsor.SponsorName__c}</div>
      <div>{sponsor.Active__c ? "Active" : "Inactive"}</div>
  </>)}
    placeholder={{to: "new", text,hideButton: true}}
  />
)

export default CharterNumberListPage;
