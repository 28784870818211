import { useAuthDispatch, logoutAction, useAuthState } from '@containers/Auth';
import { classNames } from '@utils';
import { MixpanelConsumer } from 'react-mixpanel';


const LogoutButton = ({ className, children, as: WrapperComponent = "button", ...props }) => {

  const user = useAuthState();
  const dispatch = useAuthDispatch(),
    handleClick = (mixpanel) => {
      mixpanel.identify(user.id);
      mixpanel.track('Log Out', {
        'Source': 'Web',
        'UserType': user.is_admin ? 'admin' : 'steward'
      });
      dispatch(logoutAction())
    }
  return (
    <MixpanelConsumer>
      {mixpanel => <WrapperComponent {...props} type="button" onClick={() => handleClick(mixpanel)} className={classNames("selectable", className)} title="Log Out">{children || "Log Out"}</WrapperComponent>}

    </MixpanelConsumer>
  )
}

export default LogoutButton
